import React from "react";
import moment from "moment";
import cx from "classnames";
import _ from "lodash";
import { CheckOutlined } from "@ant-design/icons";
import { Tooltip, Button, Typography, Dropdown, Menu, Mentions, Modal, message } from "antd";

import { sendNotificationsToMentionedPeople, getMentionedItems } from "common/mentionHelpers";
import { ReplyIcon, MoreIcon } from "common/icons";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";

import Avatar from "Avatar/Avatar";
import Card from "Card/Card";

import "./LiveReviewComment.scss";

function getOffset({ parent, child }) {
  const parentPos = parent.getBoundingClientRect();
  const childPos = child.getBoundingClientRect();
  const offset = {};

  offset.top = childPos.top - parentPos.top;
  offset.right = childPos.right - parentPos.right;
  offset.bottom = childPos.bottom - parentPos.bottom;
  offset.left = childPos.left - parentPos.left;
  return offset;
}

export default class LiveReviewComment extends React.Component {
  state = {
    isEditing: false,
    inputValue: null,
    isHidden: false,
    contentInApi: null,
    isSubmitting: false,
    isReplyBoxVisible: false,
    replyValue: "",
  };

  componentDidMount() {
    this.setState({
      inputValue: this.props.content,
      contentInApi: this.props.content,
    });
    this.throttledCalculatePositions = _.throttle(this.props.calculatePositions, 500);
  }

  getUpToDateReview = async () => {
    const upToDateReview = (
      await callGraphQLSimple({
        message: "Failed to retrieve review details",
        queryName: "getReview",
        variables: {
          id: this.props.review.id,
        },
      })
    ).data.getReview;

    return upToDateReview;
  };

  onCreate = async (isReply) => {
    const {
      id,
      review,
      fieldName,
      newCommentLineItemIndex,
      apiUser,
      onClose,
      recordCommentActivityItem,
      users,
      pageId,
      pageType,
    } = this.props;
    const { inputValue, replyValue } = this.state;
    this.setState({ isSubmitting: true });

    let textToSearchForMentions = isReply ? replyValue : inputValue;

    const upToDateReview = await this.getUpToDateReview();

    if (!isReply) {
      await callGraphQLSimple({
        message: "Failed to create comment",
        queryName: "updateReview",
        variables: {
          input: {
            id: upToDateReview.id,
            reviewThread: [
              ...(upToDateReview.reviewThread || []),
              {
                id: `${Date.now()}_${Math.floor(Math.random() * 1000)}`,
                type: "COMMENT",
                createdAt: new Date().toISOString(),
                sheetId: fieldName,
                sheetLabel: newCommentLineItemIndex,
                content: inputValue,
                author: apiUser.id,
                reviewThread: [],
              },
            ],
          },
        },
      });
    } else {
      let updatedReviewThread = JSON.parse(JSON.stringify(upToDateReview.reviewThread));
      const parentComment = (updatedReviewThread || []).find((comment) => comment.id === id);
      if (!parentComment) {
        message.error("Could not find parent comment");
        return;
      }
      if (!parentComment.reviewThread) {
        parentComment.reviewThread = [];
      }
      parentComment.reviewThread.push({
        id: `${Date.now()}_${Math.floor(Math.random() * 1000)}`,
        type: "COMMENT",
        createdAt: new Date().toISOString(),
        sheetId: fieldName,
        sheetLabel: newCommentLineItemIndex,
        content: replyValue,
        author: apiUser.id,
        reviewThread: [],
      });
      await callGraphQLSimple({
        message: "Failed to record reply",
        queryName: "updateReview",
        variables: {
          input: {
            id: upToDateReview.id,
            reviewThread: updatedReviewThread,
          },
        },
      });
    }

    await recordCommentActivityItem({
      content: textToSearchForMentions,
      actionType: "CREATE",
    });

    sendNotificationsToMentionedPeople({
      textToSearch: textToSearchForMentions,
      mentionOptions: users.map((x) => x.id),
      users,
      notificationText: `${apiUser.firstName} ${apiUser.lastName} mentioned you in a review for ${getSelection(
        pageType
      )} ${pageId}`,
      link: `${window.location.pathname}${window.location.search}`,
      apiUser,
    });

    if (isReply) {
      setTimeout(() => {
        this.setState({ isReplyBoxVisible: false, replyValue: "" });
      }, 200);
    } else {
      setTimeout(onClose, 200);
    }
  };

  onEdit = async () => {
    const { recordCommentActivityItem, id, calculatePositions, users, apiUser, pageType, pageId, parent } = this.props;
    const { inputValue } = this.state;

    const upToDateReview = await this.getUpToDateReview();

    let updatedReviewThread = upToDateReview.reviewThread || [];
    let targetComment;

    if (parent) {
      const parentComment = updatedReviewThread.find((comment) => comment.id === parent.id);

      if (!parentComment) {
        message.error("Cannot find target comment to edit");
        return;
      }
      targetComment = parentComment.reviewThread.find((comment) => comment.id === id);
    } else {
      targetComment = updatedReviewThread.find((comment) => comment.id === id);
    }

    if (!targetComment) {
      message.error("Cannot find target comment to edit");
      return;
    }

    targetComment.content = inputValue;
    targetComment.edited = true;
    targetComment.createdAt = new Date().toISOString();

    this.setState({ isEditing: false }, calculatePositions);

    callGraphQLSimple({
      message: "Failed to update comment",
      queryName: "updateReview",
      variables: {
        input: {
          id: upToDateReview.id,
          reviewThread: updatedReviewThread,
        },
      },
    }).then(() => {
      recordCommentActivityItem({ content: inputValue, actionType: "EDIT" });
    });

    const mentionedPeople = getMentionedItems({
      text: inputValue,
      options: users.map((x) => x.id),
    });
    if (mentionedPeople?.length > 0) {
      const mentionedPeopleDetails = mentionedPeople.map((userId) => users.find((user) => user.id === userId));
      let title;
      let content = null;
      if (mentionedPeople.length === 1) {
        title = `Do you want to notify ${mentionedPeopleDetails[0].firstName} ${mentionedPeopleDetails[0].lastName}?`;
      } else if (mentionedPeopleDetails.length === 2) {
        title = `Do you want to notify ${mentionedPeopleDetails[0].firstName} ${mentionedPeopleDetails[0].lastName} and ${mentionedPeopleDetails[1].firstName} ${mentionedPeopleDetails[1].lastName}?`;
      } else {
        title = "Do you want to notify the mentioned people?";
        content = (
          <ul>
            {mentionedPeopleDetails.map((user) => {
              return (
                <li key={user.id}>
                  <Typography.Text>
                    {user.firstName} {user.lastName}
                  </Typography.Text>
                  <br />
                </li>
              );
            })}
          </ul>
        );
      }
      Modal.confirm({
        title,
        content,
        okText: "Yes, notify",
        cancelText: "No",
        onOk: async () => {
          sendNotificationsToMentionedPeople({
            textToSearch: inputValue,
            mentionOptions: users.map((x) => x.id),
            users,
            notificationText: `${apiUser.firstName} ${apiUser.lastName} mentioned you in a review for ${pageType} ${pageId}`,
            link: `${window.location.pathname}${window.location.search}`,
            apiUser,
          });
        },
      });
    }
  };

  onEditCancel = () => {
    this.setState({ isEditing: false, inputValue: this.state.contentInApi }, this.props.calculatePositions);
  };

  onDelete = async () => {
    const { id, content, recordCommentActivityItem, calculatePositions, parent } = this.props;

    const upToDateReview = await this.getUpToDateReview();

    let updatedReviewThread = upToDateReview.reviewThread || [];

    if (parent) {
      const parentComment = updatedReviewThread.find((comment) => comment.id === parent.id);

      if (!parentComment || !parentComment.reviewThread) {
        message.error("Cannot find target comment to delete");
        return;
      }

      parentComment.reviewThread = parentComment.reviewThread.filter((comment) => comment.id !== id);
    } else {
      updatedReviewThread = updatedReviewThread.filter((comment) => comment.id !== id);
    }

    this.setState({ isHidden: true, isEditing: false }, calculatePositions);

    await callGraphQLSimple({
      message: "Failed to delete comment",
      queryName: "updateReview",
      variables: {
        input: {
          id: upToDateReview.id,
          reviewThread: updatedReviewThread,
        },
      },
    });
    await recordCommentActivityItem({
      content,
      actionType: "DELETE",
    });
  };

  markAsResolved = async () => {
    const { recordCommentActivityItem, id, apiUser, calculatePositions } = this.props;

    const upToDateReview = await this.getUpToDateReview();

    this.setState({ isEditing: false }, calculatePositions);
    await callGraphQLSimple({
      message: "Failed to mark comment as resolved",
      queryName: "updateReview",
      variables: {
        input: {
          id: upToDateReview.id,
          reviewThread: [
            ...(upToDateReview.reviewThread || []).map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  resolved: true,
                  resolvedBy: apiUser.id,
                  resolvedAt: new Date().toISOString(),
                };
              } else {
                return item;
              }
            }),
          ],
        },
      },
    });
    await recordCommentActivityItem({
      content: upToDateReview.reviewThread.find((item) => item.id === id)?.content,
      actionType: "RESOLVE",
    });
  };

  displayTitle = () => {
    let { fieldName, lineItemIndex, form } = this.props;
    let fieldNameWithoutNumbers = fieldName.split("-1")[0];

    const FIELD_NAMES = {
      quoteTitle: "Quote Title",
      quoteDescription: "Quote Description",
      clientAddress: "Client Address",
      "client-contact": "Client Contact",
      "quote-reference": "Quote Reference",
      taxRate: "Tax Rate",
      "po-number": "PO Number",
      taxInclusive: "Amounts are",

      "invoice-line-item-title": "Title",
      "invoice-line-item-description": "Description",
      "invoice-line-item-quantity": "Quantity",
      "invoice-line-item-unit-price": "Unit price",
      "invoice-line-item-percentage": "Percentage",
      "invoice-line-item-quoted-amount": "Quoted amount",

      "purchase-order-line-item-title": "Title",
      "purchase-order-line-item-description": "Description",
      "purchase-order-line-item-quantity": "Quantity",
      "purchase-order-line-item-unit-price": "Unit price",

      "quote-line-item-title": "Title",
      "quote-line-item-description": "Description",
      "quote-line-item-quantity": "Quantity",
      "quote-line-item-unit-price": getSimpleLabel("quote-unit-price"),
      "quote-line-item-authority-level": getSimpleLabel("quote-authority-level"),
      "quote-line-item-check-price": getSimpleLabel("quote-check-price"),
      "quote-line-item-sector": getSimpleLabel("quote-sector"),
      "quote-line-item-location": getSimpleLabel("quote-geographical-location"),
    };

    let fieldTitle = null;
    if (fieldName?.startsWith("dynamic-field-")) {
      let dynamicFieldId = fieldName.substring("dynamic-field-".length);
      let dynamicField = form?.fields[dynamicFieldId];
      if (dynamicField) {
        fieldTitle = dynamicField.label;
      }
    } else {
      for (let key in FIELD_NAMES) {
        if (fieldNameWithoutNumbers.startsWith(key)) {
          fieldTitle = FIELD_NAMES[key];
          break;
        }
      }

      if (!fieldTitle) {
        if (fieldName?.startsWith("quote-line-item-")) {
          let fieldNameWithoutPrefix = fieldName.substring("quote-line-item-".length);
          for (let crtFieldName in form?.fields) {
            let crtFieldDetails = form.fields[crtFieldName];
            if (crtFieldDetails?.isForLineItems && fieldNameWithoutPrefix.startsWith(crtFieldName)) {
              fieldTitle = crtFieldDetails.label;
              break;
            }
          }
        }
      }
    }

    if (lineItemIndex !== undefined) {
      fieldTitle = `Line item ${lineItemIndex + 1}${fieldTitle ? ` - ${fieldTitle}` : ""}`;
    }

    if (!fieldTitle) {
      return null;
    }
    return (
      <Typography.Text className="title" data-cy="comment-title">
        {fieldTitle}
      </Typography.Text>
    );
  };

  displayContent = () => {
    const { isEditing, inputValue } = this.state;
    const { apiUser, isNew, createdAt, author, edited, calculatePositions, users, isDisabled, isReply } = this.props;

    let timestamp;
    if (isNew) {
      timestamp = moment().format("DD MMMM YYYY HH:mm");
    } else {
      timestamp = moment(createdAt).format("DD MMMM YYYY HH:mm");
    }
    if (edited) {
      timestamp = `Edited ${timestamp}`;
    }

    if (isNew || isEditing) {
      return (
        <>
          <div className="header">
            <Avatar user={apiUser} />
            <div className="author-and-timestamp">
              <Typography.Text strong className="author" data-cy="comment-author">
                {apiUser.firstName} {apiUser.lastName}
              </Typography.Text>
              <Typography.Text className="timestamp" data-cy="comment-timestamp">
                {timestamp}
              </Typography.Text>
            </div>
          </div>
          {this.displayTitle()}
          <Mentions
            onChange={(inputValue) => {
              this.setState({ inputValue }, this.throttledCalculatePositions);
            }}
            value={inputValue}
            autoSize={{ minRows: 2, maxRows: 6 }}
            autoFocus
            data-cy="comment-input"
            // placeholder={isReply ? "Type your reply" : "Leave a comment"}
          >
            {users.map((user, index) => (
              <Mentions.Option value={user.id} key={`${user.id}-${index}`}>
                {user.firstName} {user.lastName}
              </Mentions.Option>
            ))}
          </Mentions>
          {/* <Input
            data-cy="comment-input"
            defaultValue={inputValue}
            fullWidth
            showBorder
            ref={this.inputRef}
            autoFocus={isNew}
            onChange={(inputValue) => this.setState({ inputValue })}
            onKeyUp={(e) => this.setState({ inputValue: e.target.value })}
            onEnter={() => calculatePositions()}
            multiLine
          /> */}
          <div className="buttons">
            {isNew && (
              <Button
                type="primary"
                data-cy="comment-create-button"
                onClick={() => this.onCreate(false)}
                loading={this.state.isSubmitting}
              >
                Comment
              </Button>
            )}
            {isEditing && (
              <Button type="primary" onClick={this.onEdit} data-cy="comment-save-button">
                Save
              </Button>
            )}
            {isNew && (
              <Button onClick={this.props.onClose} data-cy="comment-cancel-button">
                Cancel
              </Button>
            )}
            {isEditing && (
              <Button onClick={this.onEditCancel} data-cy="comment-cancel-button">
                Cancel
              </Button>
            )}
          </div>
        </>
      );
    } else {
      let authorDetails = users.find((x) => x.id === author);
      return (
        <>
          <div className="header">
            <Avatar user={authorDetails} />
            <div className="author-and-timestamp">
              <Typography.Text strong className="author" data-cy="comment-author">
                {authorDetails?.firstName} {authorDetails?.lastName}
              </Typography.Text>
              <Typography.Text className="timestamp" data-cy="comment-timestamp">
                {timestamp}
              </Typography.Text>
            </div>
            {!isDisabled && (
              <div className="actions">
                <Tooltip
                  title="Mark comment as resolved and hide discussion"
                  placement="topRight"
                  mouseEnterDelay={0.5}
                >
                  <Button
                    icon={<CheckOutlined />}
                    className="mark-as-resolved"
                    data-cy="comment-resolve-button"
                    onClick={this.markAsResolved}
                  />
                </Tooltip>
                {!isReply && (
                  <Tooltip title="Reply" placement="topRight" mouseEnterDelay={0.5}>
                    <Button
                      icon={<ReplyIcon />}
                      className="reply"
                      data-cy="comment-reply-button"
                      onClick={this.openReply}
                    />
                  </Tooltip>
                )}
                <Tooltip title="More options" placement="topRight" mouseEnterDelay={0.5}>
                  <Dropdown
                    trigger={["click"]}
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="edit"
                          data-cy="comment-edit-button"
                          onClick={() => this.setState({ isEditing: true }, calculatePositions)}
                        >
                          Edit
                        </Menu.Item>
                        <Menu.Item key="delete" data-cy="comment-delete-button" onClick={this.onDelete}>
                          Delete
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button icon={<MoreIcon />} className="more" data-cy="comment-more-button" />
                  </Dropdown>
                </Tooltip>
              </div>
            )}
          </div>

          {this.displayTitle()}
          <Typography.Text className="body" data-cy="comment-body">
            {inputValue}
          </Typography.Text>
        </>
      );
    }
  };

  openReply = () => {
    this.setState({ isReplyBoxVisible: true });
  };

  calculateTop = () => {
    const { fieldName } = this.props;
    const selector = `.review-target[data-name="${fieldName}"]`;
    const fieldElement = document.querySelector(selector);

    if (!fieldElement) {
      return null;
    }

    const parentContainer = document.querySelector(".page-content");
    const offset = getOffset({ parent: parentContainer, child: fieldElement });
    return offset.top - 30;
  };

  onReplySend = async () => {};

  displayReplyBox = () => {
    const { isReplyBoxVisible, replyValue } = this.state;
    const { users, apiUser } = this.props;

    if (!isReplyBoxVisible) {
      return null;
    }

    return (
      <Card className="reply-card">
        <div className="content">
          <div className="reply-box">
            <div className="header">
              <Avatar user={apiUser} />
              <div className="author-and-timestamp">
                <Typography.Text strong className="author" data-cy="comment-author">
                  {apiUser.firstName} {apiUser.lastName}
                </Typography.Text>
                <Typography.Text className="timestamp" data-cy="comment-timestamp">
                  {moment().format("DD MMMM YYYY HH:mm")}
                </Typography.Text>
              </div>
            </div>
            <Mentions
              onChange={(replyValue) => {
                this.setState({ replyValue });
              }}
              value={replyValue}
              autoSize={{ minRows: 2, maxRows: 6 }}
              autoFocus
              data-cy="reply-input"
              placeholder="Type your reply"
            >
              {users.map((user, index) => (
                <Mentions.Option value={user.id} key={`${user.id}-${index}`}>
                  {user.firstName} {user.lastName}
                </Mentions.Option>
              ))}
            </Mentions>
            <Button type="primary" data-cy="send-reply-button" onClick={() => this.onCreate(true)}>
              Reply
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  displayReviewThread = () => {
    const { reviewThread } = this.props;
    return (
      <div className="review-reply-list">
        {reviewThread
          .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
          .map((item) => {
            return (
              <LiveReviewComment
                {...this.props}
                {...item}
                key={item.id}
                reviewThread={undefined}
                parent={this.props}
                isReply
              />
            );
          })}
      </div>
    );
  };

  render() {
    const { isHidden } = this.state;
    const { isNew, visible, id, offsetTop, isAnimated, fieldName, reviewThread, isReply } = this.props;

    if (isHidden) {
      return null;
    }
    const top = this.calculateTop();
    if (top === null) {
      return null;
    }
    const style = { top: top + (offsetTop || 0) };

    return (
      <div
        className={cx("live-review-comment", {
          "is-new": isNew,
          invisible: !visible,
          "is-animated": isAnimated,
          "is-reply": isReply,
        })}
        style={style}
        data-id={id}
        data-top={top}
        data-cy="review-comment"
        data-name={fieldName}
        data-is-new={isNew}
      >
        <Card>
          <div className="content">{this.displayContent()}</div>
        </Card>
        {this.displayReplyBox()}
        {reviewThread ? this.displayReviewThread() : null}
      </div>
    );
  }
}
