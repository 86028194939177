import { useState } from "react";
import { Tooltip, Button } from "antd";
import cx from "classnames";
import { UpOutlined, QuestionCircleFilled } from "@ant-design/icons";

import { sendNotificationsToMentionedPeople } from "common/mentionHelpers";
import { processIdForDisplay, getUserSuggestions } from "common/helpers";
import { getSimpleLabel } from "common/labels";
import { isSlateValueEmpty } from "common/slateHelpers";

import Textarea from "DocumentForm/Textarea/Textarea";

import "./ReviewCommentBox.scss";

export default function ReviewCommentBox({ onSubmitComment, taskRevision, isReply, users, apiUser, task }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [numberToRefreshTextarea, setNumberToRefreshTextarea] = useState(0);

  async function onSubmit(commentBody) {
    if (isSlateValueEmpty(commentBody)) {
      return;
    }
    setCommentValue("");
    sendNotificationsToMentionedPeople({
      textToSearch: commentBody,
      mentionOptions: users.map((x) => x.id),
      users,
      notificationText: `${apiUser.firstName} ${apiUser.lastName} mentioned you in a review for ${getSimpleLabel(
        "task"
      )} ${task.project?.title} ${task.title} (${processIdForDisplay(task.id)})`,
      link: `${window.location.pathname}${window.location.search}`,
      apiUser,
    });
    await onSubmitComment(commentBody);
    setNumberToRefreshTextarea((oldNumber) => oldNumber + 1);
  }

  function onKeyUp(e) {
    if ((e.key === "Enter" && e.shiftKey === true) || (e.key === "Enter" && e.ctrlKey)) {
      onSubmit(commentValue);
    }
  }

  return (
    <div className={cx("review-comment-box", { "reply-box": isReply, "is-expanded": isExpanded })} onKeyUp={onKeyUp}>
      {!isReply && (
        <Tooltip title={!isExpanded ? "Expand the comment box" : "Collapse the comment box"} placement="left">
          <Button
            className="expand-collapse-button"
            icon={<UpOutlined />}
            onClick={() => setIsExpanded((oldIsExpanded) => !oldIsExpanded)}
          />
        </Tooltip>
      )}

      {/* <Mentions
        onChange={(value) => setCommentValue(value)}
        value={commentValue}
        placeholder={isReply ? "Type your reply" : "Leave a comment"}
      >
        {users.map((user, index) => (
          <Mentions.Option value={user.id} key={`${user.id}-${index}`}>
            {user.firstName} {user.lastName}
          </Mentions.Option>
        ))}
      </Mentions> */}
      {/* <Textarea minHeight={64} suggestions={getUserSuggestions({ users })} /> */}
      <Textarea
        key={numberToRefreshTextarea}
        minHeight={isExpanded ? window.innerHeight / 2 : 64}
        suggestions={getUserSuggestions({ users })}
        includeImageFormatting={false}
        basicFormattingOnly
        extraToolbarButtons={["attachment"]}
        // noBorder
        includeAlignmentButtons={false}
        includeWarningAboutLargeAttachments={false}
        autocompleteStartChars={["@"]}
        onChange={(value) => setCommentValue(value)}
      />

      <div className="buttons-container">
        <Tooltip
          overlayClassName="review-comment-box-send-tooltip"
          title={
            <>
              <b>Enter</b> adds a new line. <br />
              <b>Shift</b> + <b>Enter</b> or <b>Ctrl</b> + <b>Enter</b> sends the message.
              <br />
              <br />
              The "Send" button is disabled when the message is empty.
            </>
          }
          placement="leftBottom"
        >
          <QuestionCircleFilled className="tooltip-icon" />
        </Tooltip>
        <Button
          type="primary"
          className={cx("comment", {
            disabled: taskRevision.reviewStatus === "CLOSED",
          })}
          onClick={() => {
            onSubmit(commentValue);
          }}
          disabled={taskRevision.reviewStatus === "CLOSED"}
        >
          {isReply ? "Send" : "Send"}
        </Button>
      </div>
    </div>
  );
}
