import { Typography } from "antd";

function SlateNode({ node }) {
  let style = {};
  if (node.align) {
    style.textAlign = node.align;
  }
  switch (node.type) {
    case "paragraph":
      return (
        <p style={style}>
          {node.children.map((child, index) => (
            <SlateNode key={index} node={child} />
          ))}
        </p>
      );
    case "heading":
      return (
        <h1 style={style}>
          {node.children.map((child, index) => (
            <SlateNode key={index} node={child} />
          ))}
        </h1>
      );
    case "bulleted-list":
      return (
        <ul style={style}>
          {node.children.map((child, index) => (
            <SlateNode key={index} node={child} />
          ))}
        </ul>
      );
    case "numbered-list":
      return (
        <ol style={style}>
          {node.children.map((child, index) => (
            <SlateNode key={index} node={child} />
          ))}
        </ol>
      );
    case "list-item":
      return (
        <li>
          {node.children.map((child, index) => (
            <SlateNode key={index} node={child} />
          ))}
        </li>
      );
    case "text":
      return node.text;
    default:
      if (node.text) {
        let textContent = node.text;
        if (node.bold) {
          textContent = <b>{textContent}</b>;
        }
        if (node.underline) {
          textContent = <u>{textContent}</u>;
        }
        return <span>{textContent}</span>;
      }
      console.error("Unknown node type", node.type, node);
      return null;
  }
}

export default function RichTextDisplay({ content }) {
  let parsedContent;
  try {
    parsedContent = JSON.parse(content);
  } catch (e) {
    console.error("Error parsing content", e);
    return null;
  }

  let result = parsedContent.map((node, index) => <SlateNode key={index} node={node} />);

  return result;
}
