import moment from "moment";

import { INVOICE_DEFAULT_EXPECT_PAYMENT_AFTER_DAYS } from "common/constants";
import {
  getTimesheetBlocksForInvoiceLineItem,
  getInvoicePaymentTerms,
} from "common/invoiceHelpers/sharedInvoiceHelpers";
import { roundToQuarter, roundToDecimals } from "common/mathHelpers";
import { getFeesForClient } from "common/feeHelpers";
import { processIdForDisplay } from "common/shared";

function isInvoiceLineItemHourly(invoiceLineItem) {
  return invoiceLineItem.taskId || invoiceLineItem.quoteLineItem?.isHourly;
}

function getHourlyInvoiceLineItems({ invoice, withVariationMention, organisationDetails, client, quotes }) {
  if (!invoice?.lineItems) {
    return [];
  }
  let result = [];
  let isFirstGroup = true;
  let invoiceLineItems = invoice.lineItems.items;

  for (let i = 0; i < invoiceLineItems.length; i++) {
    let invoiceLineItem = invoiceLineItems[i];

    let quote;
    if (invoiceLineItem.quoteLineItem) {
      quote = quotes.find((x) => x.id === invoiceLineItem.quoteLineItem.quoteId);
    }

    let feesData = getFeesForClient({
      organisationDetails,
      quote,
      clientDetails: client,
      currency: "GBP",
    });

    if (!isInvoiceLineItemHourly(invoiceLineItem)) {
      continue;
    }

    let quoteLineItem = invoiceLineItem.quoteLineItem;

    let timesheetBlocksToDisplay = [];

    let timesheetBlocksForInvoiceLineItem = getTimesheetBlocksForInvoiceLineItem({
      invoiceLineItem,
      timesheetBlocks: invoice.timesheetBlocks.items,
    });

    timesheetBlocksForInvoiceLineItem.forEach((timesheetBlock) => {
      let hours = roundToQuarter(timesheetBlock.hours);

      let title = timesheetBlock.description || "";
      if (withVariationMention && timesheetBlock.variation) {
        title = `(Variation to original scope)\n${title}`;
      }

      let feeDefinition = feesData.find((x) => x.id === timesheetBlock.feeRole);

      let feeRole = feeDefinition?.label || timesheetBlock.feeRole;

      timesheetBlocksToDisplay.push({
        title,
        description: timesheetBlock.tags && timesheetBlock.tags.join(", "),
        quantity: hours,
        unitPrice: `${timesheetBlock.rateWithoutCurrency}`,
        feeRole,
        unitPriceAndFeeRole: `${feeRole}: ${global.formatCurrency("GBP", timesheetBlock.rateWithoutCurrency)}`,
        amount: roundToDecimals(timesheetBlock.rateWithoutCurrency * roundToDecimals(hours)),
        date: timesheetBlock.startAt,
      });
    });

    if (timesheetBlocksToDisplay.length > 0) {
      let title = "";
      if (quoteLineItem) {
        if (quoteLineItem.resultingTaskId && quoteLineItem.resultingTaskId !== "nothing") {
          title += `${processIdForDisplay(quoteLineItem.resultingTaskId)} - `;
        }
        title += quoteLineItem.title || invoiceLineItem.title;
      } else {
        title += `${processIdForDisplay(invoiceLineItem.taskId || "")} - ${
          invoiceLineItem.task?.title || invoiceLineItem.title
        }`;
      }

      result.push({
        title: `${isFirstGroup ? "" : "\n"}${title}`,
        description: null,
        quantity: null,
        unitPrice: null,
        amount: null,
      });
      result.push(...timesheetBlocksToDisplay);
      if (isFirstGroup) {
        isFirstGroup = false;
      }
    }
  }

  return result;
}

const fields = [
  {
    id: "invoiceId",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Invoice ID",
    value: ({ invoice }) => processIdForDisplay(invoice.id),
  },
  {
    id: "invoiceClientName",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Client name",
    value: ({ invoice }) => {
      return invoice?.client?.name;
    },
  },
  {
    id: "invoiceProjectTitle",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Project title",
    value: ({ invoice }) => {
      return invoice?.project?.title;
    },
  },
  {
    id: "invoicePONumber",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Invoice PO Number",
    value: ({ invoice, project }) => {
      if (invoice.poNumber) {
        return invoice.poNumber;
      }

      if (project && project.poNumber) {
        return project.poNumber;
      }
    },
  },
  {
    id: "invoiceExpectPaymentAfterDays",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Invoice payment terms (days)",
    value: ({ invoice, client, organisationDetails }) => {
      return getInvoicePaymentTerms({ invoice, client, organisationDetails });
    },
  },
  {
    id: "invoiceTotal",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Total",
    value: ({ invoice }) => {
      if (!invoice) {
        return "";
      }
      return global.formatCurrency("GBP", invoice.total);
    },
  },
  {
    id: "invoicePaidAmount",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Amount paid",
    value: ({ invoice }) => {
      if (!invoice) {
        return "";
      }
      return global.formatCurrency("GBP", invoice.amountPaid);
    },
  },
  {
    id: "invoiceRemainingAmount",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Amount remaining",
    value: ({ invoice }) => {
      if (!invoice) {
        return "";
      }

      return global.formatCurrency("GBP", roundToDecimals(invoice.total - invoice.amountPaid));
    },
  },
  {
    id: "invoiceSubtotal",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Subtotal",
    value: ({ invoice }) => {
      return global.formatCurrency("GBP", invoice.subtotal);
    },
  },
  {
    id: "invoiceSubtotalNumberOnly",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Subtotal (number only)",
    value: ({ invoice }) => {
      return invoice.subtotal;
    },
  },
  {
    id: "invoiceTotalTax",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Total tax",
    value: ({ invoice }) => {
      return global.formatCurrency("GBP", invoice.totalTax);
    },
  },
  {
    id: "invoiceTaxRate",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Tax rate",
    value: ({ invoice }) => {
      return `${invoice?.taxRate}%`;
    },
  },
  {
    id: "invoiceReviewApprovedAt",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Review approved at",
    value: ({ invoice, dateFormat, addDays }) => {
      if (!invoice || !invoice.reviewApprovedAt) {
        return "";
      }
      return moment(invoice.reviewApprovedAt).add(addDays, "day").format(dateFormat);
    },
  },
  {
    id: "invoiceReviewApprovedAtOrToday",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Review approved at or today",
    value: ({ invoice, dateFormat, addDays }) => {
      if (!invoice || !invoice.reviewApprovedAt) {
        return moment().add(addDays, "day").format(dateFormat);
      }
      return moment(invoice.reviewApprovedAt).add(addDays, "day").format(dateFormat);
    },
  },
  {
    id: "invoiceDate",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Invoice date",
    value: ({ invoice, dateFormat }) => {
      return moment(invoice.invoiceDate || invoice.createdAt).format(dateFormat);
    },
  },
  {
    id: "invoiceDueDate",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Invoice due date",
    value: ({ invoice, client, organisationDetails, dateFormat }) => {
      let paymentExpectedAfterDays =
        invoice.expectPaymentAfterDays ||
        client.defaultExpectPaymentAfterDays ||
        organisationDetails?.settings?.invoice?.defaultExpectPaymentAfterDays ||
        INVOICE_DEFAULT_EXPECT_PAYMENT_AFTER_DAYS;

      return moment(invoice.invoiceDate || invoice.createdAt)
        .add(paymentExpectedAfterDays, "days")
        .format(dateFormat);
    },
  },
  {
    id: "invoiceAssigneeSignature",
    fieldTypes: ["signature"],
    label: "Assignee signature",
    value: ({ invoice, users }) => {
      const user = users.find((user) => user.id === invoice.assignedTo);
      if (!user) {
        return undefined;
      }

      return {
        image: user.signature,
        firstName: user.firstName,
        lastName: user.lastName,
      };
    },
  },
  {
    id: "invoiceAssignee",
    fieldTypes: ["textfield"],
    label: "Assignee full name",
    value: ({ invoice, users }) => {
      let user = users.find((x) => x.id === invoice.assignedTo);
      if (!user) {
        return "";
      }
      return `${user.firstName ? `${user.firstName} ` : ""}${user.lastName || ""}`;
    },
  },
  {
    id: "invoiceAssigneeInitials",
    fieldTypes: ["textfield"],
    label: "Assignee initials",
    value: ({ invoice, users }) => {
      const assignee = users.find((user) => user.id === invoice.assignedTo);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.firstName[0]}${assignee.lastName[0]}`;
    },
  },
  {
    id: "invoiceAssigneePosition",
    fieldTypes: ["textfield"],
    label: "Assignee position",
    value: ({ invoice, users }) => {
      const assignee = users.find((user) => user.id === invoice.assignedTo);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.position}`;
    },
  },
  {
    id: "invoiceAssigneeQualifications",
    fieldTypes: ["textfield"],
    label: "Assignee qualifications",
    value: ({ invoice, users }) => {
      const assignee = users.find((user) => user.id === invoice.assignedTo);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.qualifications}`;
    },
  },

  {
    id: "invoiceReviewerSignature",
    fieldTypes: ["signature"],
    label: "Reviewer signature",
    value: ({ invoice, users }) => {
      const user = users.find((user) => user.id === invoice.checkedBy);
      if (!user) {
        return undefined;
      }

      return {
        image: user.signature,
        firstName: user.firstName,
        lastName: user.lastName,
      };
    },
  },
  {
    id: "invoiceReviewer",
    fieldTypes: ["textfield"],
    label: "Reviewer full name",
    value: ({ invoice, users }) => {
      let user = users.find((x) => x.id === invoice.checkedBy);
      if (!user) {
        return "";
      }
      return `${user.firstName ? `${user.firstName} ` : ""}${user.lastName || ""}`;
    },
  },
  {
    id: "invoiceReviewerInitials",
    fieldTypes: ["textfield"],
    label: "Reviewer initials",
    value: ({ invoice, users }) => {
      const assignee = users.find((user) => user.id === invoice.checkedBy);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.firstName[0]}${assignee.lastName[0]}`;
    },
  },
  {
    id: "invoiceReviewerPosition",
    fieldTypes: ["textfield"],
    label: "Reviewer position",
    value: ({ invoice, users }) => {
      const assignee = users.find((user) => user.id === invoice.checkedBy);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.position}`;
    },
  },
  {
    id: "invoiceReviewerQualifications",
    fieldTypes: ["textfield"],
    label: "Reviewer qualifications",
    value: ({ invoice, users }) => {
      const assignee = users.find((user) => user.id === invoice.checkedBy);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.qualifications}`;
    },
  },

  // {
  //   id: "invoiceLineItems",
  //   fieldTypes: ["repeatFor"],
  //   label: "Each line item with PO and embedded date",
  //   repeatForFieldName: "invoiceLineItem",
  //   value: ({ invoice }) => {
  //     let result = [];
  //     let invoiceLineItems = invoice.lineItems.items;
  //     for (let i = 0; i < invoiceLineItems.length; i++) {
  //       let invoiceLineItem = invoiceLineItems[i];
  //       if (!invoiceLineItem.quoteLineItem) {
  //         result.push(invoiceLineItem);
  //       } else {
  //         let quoteLineItem = invoiceLineItem.quoteLineItem;
  //         if (!quoteLineItem.isHourly) {
  //           result.push(invoiceLineItem);
  //         } else {
  //           let timesheetBlocksForQuoteLineItem = [];
  //           invoice.timesheetBlocks.items.forEach((timesheetBlock) => {
  //             if (timesheetBlock.quoteLineItemId === quoteLineItem.id) {
  //               timesheetBlocksForQuoteLineItem.push({
  //                 title: `${moment(timesheetBlock.startAt).format("DD/MM/YYYY")}: ${timesheetBlock.feeRole} - ${
  //                   timesheetBlock.description
  //                 }`,
  //                 description: timesheetBlock.tags && timesheetBlock.tags.join(", "),
  //                 quantity: timesheetBlock.hours,
  //                 unitPrice: timesheetBlock.rateWithoutCurrency,
  //                 amount: timesheetBlock.rateWithoutCurrency * timesheetBlock.hours,
  //               });
  //             }
  //           });
  //           if (timesheetBlocksForQuoteLineItem.length > 0) {
  //             let title = quoteLineItem.title;
  //             if (quoteLineItem.quote && quoteLineItem.quote.poNumber) {
  //               title = `(PO ${quoteLineItem.quote.poNumber})\n${title}`;
  //             }
  //             result.push({
  //               title: `\n${title}`,
  //               description: null,
  //               quantity: null,
  //               unitPrice: null,
  //               amount: null,
  //             });
  //             result.push(...timesheetBlocksForQuoteLineItem);
  //           }
  //         }
  //       }
  //     }
  //     return result;
  //   },
  // },
  {
    id: "nonHourlySubtotal",
    label: "Non-hourly items subtotal",
    value: ({ invoice }) => {
      let result = 0;
      let invoiceLineItems = invoice.lineItems.items;
      for (let i = 0; i < invoiceLineItems.length; i++) {
        let invoiceLineItem = invoiceLineItems[i];
        if (!isInvoiceLineItemHourly(invoiceLineItem)) {
          result = roundToDecimals(result + roundToDecimals(invoiceLineItem.amount));
        }
      }
      return global.formatCurrency("GBP", result);
    },
  },
  {
    id: "hourlySubtotal",
    label: "Hourly items subtotal",
    value: ({ invoice }) => {
      let result = 0;
      let invoiceLineItems = invoice.lineItems.items;
      for (let i = 0; i < invoiceLineItems.length; i++) {
        let invoiceLineItem = invoiceLineItems[i];
        if (isInvoiceLineItemHourly(invoiceLineItem)) {
          result = roundToDecimals(result + roundToDecimals(invoiceLineItem.amount));
        }
      }
      return global.formatCurrency("GBP", result);
    },
  },
  {
    id: "nonHourlyInvoiceLineItem",
    repeatForFieldName: "invoiceLineItem",
    fieldTypes: ["repeatFor"],
    label: "Each non-hourly line item",
    value: ({ invoice }) => {
      let result = [];
      let invoiceLineItems = invoice.lineItems.items;
      for (let i = 0; i < invoiceLineItems.length; i++) {
        let invoiceLineItem = invoiceLineItems[i];
        if (!isInvoiceLineItemHourly(invoiceLineItem)) {
          let quotedAmount = invoiceLineItem.amount;
          if (invoiceLineItem.quoteLineItem) {
            quotedAmount = invoiceLineItem.quoteLineItem?.amount;
          }
          let rawPercentageInvoicedThisTime = (invoiceLineItem.amount / quotedAmount) * 100;
          let percentageInvoicedThisTime = Math.round(rawPercentageInvoicedThisTime * 100) / 100;
          result.push({
            ...invoiceLineItem,
            resultingTaskId:
              invoiceLineItem.quoteLineItem?.resultingTaskId &&
              invoiceLineItem.quoteLineItem?.resultingTaskId !== "nothing"
                ? processIdForDisplay(invoiceLineItem.quoteLineItem?.resultingTaskId)
                : "",
            quotedAmount,
            percentageInvoicedThisTime,
          });
        }
      }
      return result;
    },
  },
  {
    id: "hourlyInvoiceLineItem",
    repeatForFieldName: "invoiceLineItem",
    fieldTypes: ["repeatFor"],
    label: "Each hourly line item",
    value: ({ invoice, organisationDetails, client, quotes }) => {
      return getHourlyInvoiceLineItems({ invoice, withVariationMention: false, organisationDetails, client, quotes });
    },
  },
  {
    id: "hourlyInvoiceLineItemWithVariation",
    repeatForFieldName: "invoiceLineItem",
    fieldTypes: ["repeatFor"],
    label: "Each hourly line item - mention variation",
    value: ({ invoice, quotes, organisationDetails }) => {
      const items = getHourlyInvoiceLineItems({ invoice, withVariationMention: true, quotes, organisationDetails });
      return items;
    },
  },
  {
    id: "peopleOnHourlyItems",
    fieldTypes: ["textfield"],
    label: "Number of users on hourly line items",
    value: ({ invoice }) => {
      let users = [];
      for (let timesheetBlock of invoice.timesheetBlocks.items) {
        if (!users.includes(timesheetBlock.userId)) {
          users.push(timesheetBlock.userId);
        }
      }

      return users.length;
    },
  },
  {
    id: "totalHoursOnHourlyItems",
    fieldTypes: ["textfield"],
    label: "Total hours on hourly items",
    value: ({ invoice }) => {
      let hours = invoice.timesheetBlocks.items.reduce((sum, timesheetBlock) => {
        let hours = roundToQuarter(timesheetBlock.hours);
        return roundToDecimals(sum + hours);
      }, 0);

      return hours;
    },
  },
  {
    id: "hourlyInvoiceLineItemByPerson",
    repeatForFieldName: "invoiceLineItem",
    fieldTypes: ["repeatFor"],
    label: "Each hourly line item, grouped by person",
    value: ({ invoice, users }) => {
      let result = [];
      let invoiceLineItems = invoice.lineItems.items;
      let timesheetBlocksByUser = {};

      for (let i = 0; i < invoiceLineItems.length; i++) {
        let invoiceLineItem = invoiceLineItems[i];
        if (!invoiceLineItem.quoteLineItem?.isHourly) {
          continue;
        }
        let quoteLineItem = invoiceLineItem.quoteLineItem;
        invoice.timesheetBlocks.items.forEach((timesheetBlock) => {
          let hours = roundToQuarter(timesheetBlock.hours);
          if (timesheetBlock.quoteLineItemId === quoteLineItem.id) {
            let enhancedTimesheetBlock = {
              title: `${timesheetBlock.description || ""}`,
              description: timesheetBlock.tags && timesheetBlock.tags.join(", "),
              quantity: hours,
              unitPrice: `${timesheetBlock.rateWithoutCurrency}`,
              feeRole: timesheetBlock.feeRole,
              unitPriceAndFeeRole: `${timesheetBlock.feeRole}: ${global.formatCurrency(
                "GBP",
                timesheetBlock.rateWithoutCurrency
              )}`,
              amount: timesheetBlock.rateWithoutCurrency * hours,
              date: timesheetBlock.startAt,
              timesheetBlockStartAt: timesheetBlock.startAt,
              timesheetBlockEndAt: timesheetBlock.endAt,
            };
            if (!timesheetBlocksByUser[timesheetBlock.userId]) {
              timesheetBlocksByUser[timesheetBlock.userId] = [];
            }
            timesheetBlocksByUser[timesheetBlock.userId].push(enhancedTimesheetBlock);
          }
        });
      }

      let counter = 0;
      for (let userId in timesheetBlocksByUser) {
        counter++;
        let userDetails = users.find((x) => x.id === userId);
        let timesheetBlocksForUser = timesheetBlocksByUser[userId];

        result.push({
          title: `\nOperative ${counter}: ${userDetails.firstName} ${userDetails.lastName}`,
          description: null,
          quantity: null,
          unitPrice: null,
          amount: null,
        });
        result.push(...timesheetBlocksForUser);
      }

      return result;
    },
  },
  {
    id: "invoiceLineItemsCustomFieldFromQuoteLineItems",
    label: "Custom field from quote line items",
    value: ({ invoice, customField }) => {
      if (!customField) {
        return;
      }

      let result = [];

      for (let invoiceLineItem of invoice.lineItems.items) {
        if (invoiceLineItem.quoteLineItem) {
          let quoteLineItem = invoiceLineItem.quoteLineItem;
          let customFieldValue = quoteLineItem?.customFields?.find((x) => x.id === customField)?.value;
          if (customFieldValue && !result.includes(customFieldValue)) {
            result.push(customFieldValue);
          }
        }
      }

      return result.join("; ");
    },
  },
];
export function getFields() {
  return fields;
}
