import { useState, useEffect } from "react";
import moment from "moment";

import { Spin, Select } from "antd";
import { Line } from "@ant-design/charts";
import { getLabel } from "common/helpers";
import DatePicker from "DatePicker/DatePicker";

import {
  group,
  capitalise,
  formatDates,
  processHistoricData,
  getHistoricData,
} from "OrganisationPage/organisationHelpers";

import Card from "Card/Card";
import "./EngineeringChart.scss";

export default function EngineeringChart(props) {
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);
  const [chartData, setChartData] = useState([]);
  const { organisationDetails } = props;

  const [startDate, setStartDate] = useState(moment(organisationDetails.createdAt));
  const [endDate, setEndDate] = useState(moment().subtract(1, "day").endOf("day"));
  const [dataType, setDataType] = useState("task");
  const [groupBy, setGroupBy] = useState("day");

  useEffect(() => {
    getChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, dataType, groupBy]);

  async function getChartData() {
    setIsPreloaderVisible(true);
    if (!startDate || !endDate) {
      return;
    }

    const historicData = await getHistoricData({
      organisationDetails,
      startDate,
      endDate,
    });

    if (!historicData.length) {
      setChartData([]);
      setIsPreloaderVisible(false);

      return null;
    }

    const dates = processHistoricData(historicData, dataType);
    const groupedData = group({ dates, period: groupBy, startDate, endDate });
    const newChartData = formatDates(groupedData);

    setChartData(newChartData);
    setIsPreloaderVisible(false);
  }

  function handleDateChange(value) {
    if (value) {
      const [start, end] = value;

      const startDate = moment(start).startOf("day");
      const endDate = moment(end).endOf("day");

      setStartDate(startDate);
      setEndDate(endDate);
    }
  }

  function handleDisplayChange(value) {
    setDataType(value);
  }

  function handleGroupByChange(period) {
    setGroupBy(period);
  }

  if (!chartData) {
    return null;
  }

  let startDateFormatted = startDate.format("YYYY-MM-DD");
  let minValueForStartDate = 0;

  if (chartData.length > 0) {
    let dataObjectsForStartDate = chartData.filter((data) => data.day === startDateFormatted);
    if (dataObjectsForStartDate.length > 0) {
      minValueForStartDate = (dataObjectsForStartDate || []).reduce((acc, curr) => {
        if (!acc) {
          return curr;
        }
        return acc.value < curr.value ? acc : curr;
      }, dataObjectsForStartDate[0]).value;
    }
  }

  const config = {
    data: chartData,
    xField: "day",
    yField: "value",
    seriesField: "category",
    color: ({ category }) => {
      // Define a color mapping based on the category
      const colors = {
        All: "#aaaaaa", // gray
        Finished: "#4cc23a", // Blue for category B
        Active: "#19aae8", // Blue for category B
      };
      return colors[category];
    },
    animation: false,
    legend: {
      itemMarginBottom: 50,
      position: "right",
      marker: {
        symbol: "square",
        fill: "fill",
      },
    },

    yAxis: {
      min: minValueForStartDate || 0,
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
  };

  return (
    <>
      <Card
        withSpace
        title={`Engineering for ${organisationDetails.id}`}
        actions={
          <div className="engineering-actions">
            <span className="engineering-action">
              <span className="engineering-action-title">Group by: </span>
              <Select defaultValue="Day" style={{ width: 100 }} onChange={handleGroupByChange}>
                <Select.Option value="day">Day</Select.Option>
                <Select.Option value="week">Week</Select.Option>
                <Select.Option value="month">Month</Select.Option>
              </Select>
            </span>
            <span className="engineering-action">
              <span className="engineering-action-title">Display: </span>
              <Select defaultValue="task" style={{ width: 100 }} onChange={handleDisplayChange}>
                <Select.Option value="task">
                  {getLabel({
                    id: "Tasks",
                    defaultValue: "Tasks",
                  })}
                </Select.Option>
                <Select.Option value="project">
                  {getLabel({
                    id: "Projects",
                    defaultValue: "Projects",
                  })}
                </Select.Option>
                <Select.Option value="user">Users</Select.Option>
                <Select.Option value="client">Clients</Select.Option>
              </Select>
            </span>
            <DatePicker.RangePicker
              className="engineering-action"
              dropdownClassName="date-range-picker"
              defaultValue={[startDate, endDate]}
              onChange={handleDateChange}
              ranges={{
                "Last week": [moment().subtract(1, "day").subtract(1, "weeks"), moment().subtract(1, "day")],
                "Last month": [moment().subtract(1, "day").subtract(1, "months"), moment().subtract(1, "day")],
                "Last 6 months": [moment().subtract(1, "day").subtract(6, "months"), moment().subtract(1, "day")],
                "Last 12 months": [moment().subtract(1, "day").subtract(12, "months"), moment().subtract(1, "day")],
                "Since the beginning": [moment(organisationDetails.createdAt), moment().subtract(1, "day")],
              }}
            />
          </div>
        }
      >
        <div className="engineering-chart-wrapper">
          <span className="engineering-chart-title">{capitalise(dataType)}s in your organisation</span>
          <div className="chart">
            {isPreloaderVisible && (
              <div className="preloader">
                <Spin />
              </div>
            )}
            <Line {...config} style={{ width: "80%", height: 300 }} className="engineering-chart" />
          </div>
        </div>
      </Card>
    </>
  );
}
