import React from "react";
import cx from "classnames";

import "./LinearIndefinitePreloader.scss";

export function LinearIndefinitePreloader({ position = "top" }) {
  return (
    <div className={cx("linear-indefinite-preloader", position)}>
      <div className="preloader-line"></div>
    </div>
  );
}

export default React.memo(LinearIndefinitePreloader, () => true);
