import React from "react";
import cx from "classnames";
import { UserOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

import UserCard from "UserCard/UserCard";
import { PUBLIC_S3_BUCKET_URL } from "common/publicS3BucketUrl";
import DraughtHubLogoSymbolOnly from "graphics/draughthub_logo_symbol_only.png";

import { stringToColor } from "common/helpers";

import "./Avatar.scss";

type Props = {
  user?: any;
  onClick?: (e: React.MouseEvent, user: any) => void;
  selected?: boolean;
  className?: string;
  size?: string;
  noWrap?: boolean;
  withBorder?: boolean;
  showLabel?: boolean;
  showImage?: boolean;
  maxLabelLength?: number;
  disableUserCard?: boolean;
  disabled?: boolean;
  showHoverAnimation?: boolean;
  displayAsColumn?: boolean;
};

type State = {};

export default class Avatar extends React.Component<Props, State> {
  displayAvatarOnly = () => {
    const {
      user,
      onClick,
      selected,
      className,
      size = "normal",
      withBorder,
      showLabel,
      showImage = true,
      maxLabelLength,
      showHoverAnimation = true,
      displayAsColumn = false,
      disabled = false,
      noWrap = false,
    } = this.props;
    // const { image } = this.state;
    let image;

    if (user && user.id !== "unassigned" && user.avatarKey) {
      image = `${PUBLIC_S3_BUCKET_URL}/public/${user.avatarKey}`;
    } else if (user === "draughthub") {
      image = DraughtHubLogoSymbolOnly;
    }

    let avatarIcon: React.ReactElement | null = null;

    let firstName = user?.firstName;
    let lastName = user?.lastName;

    let initials = "X";
    if (user && user.id !== "unassigned") {
      initials = (firstName || "").charAt(0) + (lastName || "").charAt(0);
    }
    if (!initials && user && user.id) {
      let wordsInEmailAddressWithPeriod = user.id.split("@")[0].split(".");
      if (wordsInEmailAddressWithPeriod.length > 1) {
        initials = wordsInEmailAddressWithPeriod[0].charAt(0) + wordsInEmailAddressWithPeriod[1].charAt(0);
      } else {
        let wordsInEmailAddressWithUnderscore = user.id.split("@")[0].split("_");
        if (wordsInEmailAddressWithUnderscore.length > 1) {
          initials = wordsInEmailAddressWithUnderscore[0].charAt(0) + wordsInEmailAddressWithUnderscore[1].charAt(0);
        } else {
          let wordsInEmailAddressWithDash = user.id.split("@")[0].split("-");
          if (wordsInEmailAddressWithDash.length > 1) {
            initials = wordsInEmailAddressWithDash[0].charAt(0) + wordsInEmailAddressWithDash[1].charAt(0);
          } else {
            let wordsInEmailAddressWithPlus = user.id.split("@")[0].split("+");
            if (wordsInEmailAddressWithPlus.length > 1) {
              initials = wordsInEmailAddressWithPlus[0].charAt(0) + wordsInEmailAddressWithPlus[1].charAt(0);
            } else {
              initials = user.id.substring(0, 2);
            }
          }
        }
      }
    }
    if (initials) {
      avatarIcon = (
        <span className="avatar-initials" data-cy="avatar-initials">
          {initials}
        </span>
      );
    }
    const bgColor = stringToColor({
      string: initials,
      saturation: 70,
      lightness: 45,
    });

    let additionalProps: {
      onClick?: (e) => void;
    } = {};
    if (onClick) {
      additionalProps.onClick = (e) => {
        onClick(e, user);
      };
    }

    let style: {
      backgroundColor?: string;
    } = {};
    let imageStyle: {
      backgroundImage?: string;
    } = {};
    if (user === "draughthub") {
      imageStyle.backgroundImage = `url('${image}')`;
    } else if (user && user.id !== "unassigned") {
      if (user.avatarKey) {
        imageStyle.backgroundImage = `url('${image}')`;
        style.backgroundColor = "transparent";
      } else {
        style.backgroundColor = bgColor;
      }
    } else {
      avatarIcon = <UserOutlined className="unassigned-icon" />;
    }
    let labelElement: React.ReactElement | null = null;

    if (showLabel) {
      if (user) {
        const labelText = `${firstName || ""} ${lastName || ""}`.trim();
        let trimmedLabelText = labelText;
        if (maxLabelLength) {
          trimmedLabelText = labelText.substring(0, maxLabelLength);
          if (trimmedLabelText !== labelText) {
            trimmedLabelText += "...";
          }
        }
        if (!trimmedLabelText) {
          trimmedLabelText = user.id;
        }
        labelElement = <span className="label">{trimmedLabelText}</span>;
      } else {
        labelElement = <span className="label">Unassigned</span>;
      }
    }

    return (
      <div
        data-cy="avatar"
        data-email={user ? user.id : "unassigned"}
        className={cx("avatar", size, className, {
          draughthub: user === "draughthub",
          clickable: additionalProps.onClick,
          selected,
          "with-photo": user?.avatarKey,
          "without-photo": !user?.avatarKey,
          "with-border": withBorder,
          "with-label": showLabel,
          "without-label": !showLabel,
          "show-hover-animation": showHoverAnimation,
          disabled: user?.isDisabled || disabled,
          "display-as-column": displayAsColumn,
          "show-image": showImage,
          wrap: !noWrap,
        })}
        {...additionalProps}
      >
        {(avatarIcon || image || !showLabel) && (
          <div className="symbol" data-cy="symbol" style={style}>
            {image ? <div className="image" style={imageStyle} /> : avatarIcon}
          </div>
        )}

        {labelElement}
      </div>
    );
  };

  render() {
    const { user, disableUserCard } = this.props;

    if (disableUserCard || !user) {
      return this.displayAvatarOnly();
    }

    return (
      <Tooltip
        title={disableUserCard ? null : <UserCard user={user} />}
        overlayClassName={cx("user-card-container", {
          disabled: user.isDisabled,
        })}
        mouseEnterDelay={0.7}
      >
        {this.displayAvatarOnly()}
      </Tooltip>
    );
  }
}
