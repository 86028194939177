import { useState } from "react";
import { Storage } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { Form, Modal, Button, Input, Typography, Space, message, notification, Checkbox } from "antd";
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { readAndCompressImage } from "browser-image-resizer";

import withSubscriptions from "common/withSubscriptions";
import { INVALID_CHARACTERS, MAX_INITIALS_LENGTH } from "common/constants";
import { createClientInApi, getLabel } from "common/helpers";
import { getExtensionFromKey } from "common/shared";
import { useForceUpdate } from "common/helpers";

import ClientContactModal from "Modals/ClientContactModal/ClientContactModal";
import Upload from "Upload/Upload";

import "./CreateClientModal.scss";

export function CreateClientModal({ onClose, onSave, organisationDetails }) {
  const history = useHistory();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isClientContactModalVisible, setIsClientContactModalVisible] = useState(false);
  const [selectedContact, setSelectedContact] = useState();
  const forceUpdate = useForceUpdate();

  async function onClientContactModalSubmit(contactDetails) {
    if (selectedContact) {
      const contacts = form.getFieldsValue().contacts;
      const index = contacts.findIndex((contact) => contact.id === selectedContact.id);
      contacts[index] = contactDetails;
      form.setFieldsValue({ contacts });
    } else {
      const contacts = form.getFieldsValue().contacts || [];
      contacts.push(contactDetails);
      form.setFieldsValue({ contacts });
    }

    forceUpdate();
    setSelectedContact(undefined);
    setIsClientContactModalVisible(false);
  }

  async function onSubmit(params) {
    const { logo } = params;
    try {
      setIsLoading(true);
      if (!logo) {
        await triggerCreateClient(params);
      } else {
        // if we have a logo, then we need to upload it to S3 before
        // proceeding to create the client record in the database
        const extension = getExtensionFromKey(logo.name);

        if (!["png", "jpg", "jpeg"].includes(extension.toLowerCase())) {
          alert("Only .png, .jpg and .jpeg formats are allowed");
          setIsLoading(false);
          return;
        }
        const resizedLogo = await readAndCompressImage(logo, {
          quality: 0.8,
          maxWidth: 500,
          maxHeight: 500,
          mimeType: logo.type,
        });

        const fileName = `${organisationDetails.id}/clients/${Date.now()}${Math.floor(Math.random() * 10000)}`;
        const { key } = await Storage.put(fileName, resizedLogo, {
          contentType: "image/png",
        });

        form.setFieldsValue({ key });
        await triggerCreateClient({ ...params, key });
      }
      form.resetFields();
      setIsLoading(false);
      onClose();
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      notification.error({
        message: (
          <Typography.Text>
            Could not create{" "}
            {getLabel({
              id: "client",
              defaultValue: "client",
            })}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  async function triggerCreateClient(params) {
    const client = await createClientInApi({
      ...params,
      organisation: organisationDetails.id,
    });

    if (onSave) {
      onSave(client);
    }
    message.success({
      content: (
        <Typography.Text onClick={() => history.push(`/clients/${client.id}`)} style={{ cursor: "pointer" }}>
          Client <b>{params.name}</b> has been created. <b>Click here to go to it.</b>
        </Typography.Text>
      ),
      className: "create-client-success",
    });
  }

  const parentForContactModal = {
    contacts: form.getFieldsValue().contacts,
  };

  return (
    <>
      <Modal
        maskClosable={false}
        title={`Create ${getLabel({
          id: "client",
          defaultValue: "client",
        })}`}
        open={true}
        onOk={onSubmit}
        onCancel={() => {
          form.resetFields();
          onClose();
        }}
        footer={null}
        className="create-client-modal full-screen-on-mobile"
      >
        <Form layout="vertical" form={form} initialValues={{}} onFinish={onSubmit}>
          <Space direction="vertical">
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: `You must specify a ${getLabel({
                    id: "client",
                    defaultValue: "client",
                  })} name`,
                },
              ]}
            >
              <Input autoComplete="off" className="client-name" />
            </Form.Item>

            {organisationDetails.usesClientInitials && (
              <Form.Item
                label="Initials"
                name="initials"
                rules={[
                  {
                    required: true,
                    message: `You must choose ${getLabel({
                      id: "client",
                      defaultValue: "client",
                    })} initials`,
                  },
                  {
                    validator: async (_, initials) => {
                      if (initials.length > MAX_INITIALS_LENGTH) {
                        throw new Error(`Initials cannot contain more than ${MAX_INITIALS_LENGTH} characters`);
                      }
                      for (let i = 0; i < INVALID_CHARACTERS.length; i++) {
                        if (initials.includes(INVALID_CHARACTERS[i])) {
                          throw new Error(
                            `Value contains at least one of the following invalid characters: ${INVALID_CHARACTERS.join(
                              " "
                            )}`
                          );
                        }
                      }
                    },
                  },
                ]}
              >
                <Input autoComplete="off" className="client-initials" />
              </Form.Item>
            )}

            <Form.Item
              name="isPriority"
              label={`Priority ${getLabel({
                id: "client",
                defaultValue: "client",
              })}`}
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>

            <Form.Item name="contacts" label="Contacts">
              <Button
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  setSelectedContact(undefined);
                  setIsClientContactModalVisible(true);
                }}
              >
                Add contact
              </Button>
              {form.getFieldsValue().contacts?.map((contact, index) => (
                <div key={index} className="contact-item">
                  <Button
                    icon={<EditOutlined />}
                    type="link"
                    onClick={() => {
                      setSelectedContact(contact);
                      setIsClientContactModalVisible(true);
                    }}
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    type="link"
                    onClick={() => {
                      Modal.confirm({
                        title: "Delete contact",
                        content: "Are you sure you want to delete this contact?",
                        onOk: () => {
                          const contacts = form.getFieldsValue().contacts;
                          contacts.splice(index, 1);
                          form.setFieldsValue({ contacts });
                          forceUpdate();
                        },
                      });
                    }}
                  />
                  <span>
                    {contact.firstName} {contact.lastName} {contact.id ? `(${contact.id})` : ""}{" "}
                    {contact.position ? `(${contact.position})` : ""}
                  </span>
                </div>
              ))}
            </Form.Item>

            <Form.Item name="logo" label="Logo" getValueFromEvent={(e, file) => file}>
              <Upload size="normal" />
            </Form.Item>

            <div className="submit-container">
              <Button type="primary" htmlType="submit" loading={isLoading} className="submit-create-client-modal">
                {isLoading ? "Creating" : "Submit"}
              </Button>
            </div>
          </Space>
        </Form>
      </Modal>
      {isClientContactModalVisible && (
        <ClientContactModal
          onClose={() => setIsClientContactModalVisible(false)}
          onSubmit={onClientContactModalSubmit}
          parent={parentForContactModal}
          contact={selectedContact}
        />
      )}
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: CreateClientModal,
    subscriptions: ["organisationDetails"],
  })
);
