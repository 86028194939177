import moment from "moment";

import { KEY_TYPES } from "../shared";
import { getFullDetailsForNaming } from "./namingHelpers";

export async function getFileName({ data, type }) {
  if (data.fileType.toUpperCase() === "QUOTE") {
    return data.quoteId;
  }

  if (data.fileType.toUpperCase() === "INVOICE") {
    return data.invoiceId;
  }

  if (data.fileType.toUpperCase() === "PURCHASE_ORDER") {
    return data.purchaseOrderId;
  }

  let { fileTypeCount, sameTemplateCount, taskIdWithoutOrg, task, file, organisationDetails, taskRevision } =
    await getFullDetailsForNaming(data, type);

  let projectNumber = task.projectId.split("-")[1];

  let sheet;
  let fileNumber;

  if (data.sheetName) {
    sheet = file.sheets.items.find((x) => x.name === data.sheetName);
  }

  let result = `${projectNumber}-CON`;
  let taskNumber = taskIdWithoutOrg.split("-")[1];
  if (parseInt(taskNumber) < 10) {
    taskNumber = `0${taskNumber}`;
  }

  switch (data.fileType.toUpperCase()) {
    case "AUTOCAD":
    case "REVIT":
    case "BRICSCAD":
      result += `-DRG-${taskNumber}`;
      break;
    case "EXCEL":
      result += `-CA-${taskNumber}`;
      break;

    default:
      let templateId = data.templateId || data.file?.templateId;
      const templateDetails = organisationDetails.templates.items.find((x) => x.id === templateId);
      if (templateDetails) {
        if (templateDetails?.name?.toUpperCase().includes("CALCULATIONS")) {
          result += "-CA";
        } else if (templateDetails?.name?.toUpperCase().includes("ENGINEERING STATEMENT")) {
          result += "-RP";
        }
      }

      result += `-${taskNumber}`;
      break;
  }

  if (data.sheetName) {
    result += `-SHEET_TITLE`;
  } else {
    result += `-FILE_TITLE`;
  }

  result += `-REV`;

  if (type !== KEY_TYPES.SHEET_REFERENCE && data.sheetName) {
    result += ` ${data.sheetName.split(" ").join("_")}`;
  }
  // console.log("getFileName() result =", result, "type =", type, data.sheetName);
  return result;
}

async function changeFileNameAtDownloadTime(data) {
  // console.log('changeFileNameAtDownloadTime() data =', data);
  let { fileName, sheetRevisionName, file, type, task, invoice, projects, clients, sheetRevision } = data;

  let sheet;
  if (sheetRevision?.sheetId) {
    sheet = file.sheets.items.find((x) => x.id === sheetRevision.sheetId);
  }

  if (type === KEY_TYPES.INVOICE) {
    let date = moment(invoice.createdAt);

    const projectDetails = projects.find((x) => x.id === invoice.projectId);
    const clientDetails = clients.find((x) => x.id === invoice.clientId);

    return `${clientDetails?.name} ${projectDetails?.title} ${date.format("MMM YYYY")} ${invoice.id}.pdf`;
  }

  if ((file?.type === "AUTOCAD" && type?.includes("SHEET")) || file?.type !== "AUTOCAD") {
    let fileVersion = file.versions.items.find((x) => x.id === sheetRevision.fileVersionId);
    if (sheet?.customReferenceNumber && sheet.customReferenceNumber !== "undefined") {
      return sheet.customReferenceNumber;
    } else if (fileVersion?.customId && fileVersion.customId !== "undefined") {
      return fileVersion.customId;
    }
    if (fileName.includes(" ")) {
      let fileNameParts = fileName.split(" ");
      fileNameParts.pop();
      fileName = fileNameParts.join(" ");
    }
    if (sheet) {
      let sheetNumber = `${sheet.description}`;
      if (sheetNumber.length < 2) {
        sheetNumber = `0${sheetNumber}`;
      }
      return fileName
        .split("SHEET_TITLE")
        .join(sheetNumber)
        .split("FILE_TITLE")
        .join(sheetNumber)
        .split("REV")
        .join(sheetRevisionName);
    }
  } else {
    let fileVersion = file.versions.items.slice(-1)[0];
    if (sheet?.customReferenceNumber && sheet?.customReferenceNumber !== "undefined") {
      return sheet.customReferenceNumber;
    } else if (fileVersion?.customId && fileVersion.customId !== "undefined") {
      return fileVersion.customId;
    } else {
      return fileName;
    }
  }

  return fileName;
}

function changeSheetReference({ sheet, file, sheetRevisionName, referenceNumber }) {
  if (["AUTOCAD", "REVIT", "BRICSCAD", "REPORT", "EXCEL"].includes(file?.type.toUpperCase())) {
    let sheetNumber = `${sheet.description}`;
    if (sheetNumber.length < 2) {
      sheetNumber = `0${sheetNumber}`;
    }
    return referenceNumber.split("SHEET_TITLE").join(sheetNumber).split("REV").join(sheetRevisionName);
  } else {
    return referenceNumber;
  }
}

function getProjectId({ organisation, extraOffset }) {
  const projectNumber =
    parseInt(organisation.projectIdOffset || 0) + parseInt(organisation.projectCount || 0) + 1 + parseInt(extraOffset);
  return `${organisation.id}-${projectNumber}`;
}

async function getTaskId({ organisation, projectDetails, extraOffset }) {
  const taskNumber = parseInt(projectDetails.taskCount || 0) + 1 + parseInt(extraOffset);
  let taskNumberAsString = `${taskNumber}`;

  return `${projectDetails.id}-${taskNumberAsString}`;
}

function getQuoteId({ organisation, projectDetails, extraOffset }) {
  const quoteNumber = parseInt(projectDetails.quoteCount || 0) + 1 + parseInt(extraOffset);
  let quoteNumberAsString = quoteNumber >= 10 ? `${quoteNumber}` : `0${quoteNumber}`;
  return `${projectDetails.id.split("-")[1]}-CON-QT-${quoteNumberAsString}`;
}

function getInvoiceId({ organisation, projectDetails, extraOffset }) {
  const invoiceNumber = parseInt(projectDetails.invoiceCount || 0) + 1 + parseInt(extraOffset);
  let invoiceNumberAsString = invoiceNumber >= 10 ? `${invoiceNumber}` : `0${invoiceNumber}`;
  return `${projectDetails.id.split("-")[1]}-CON-INV-${invoiceNumberAsString}`;
}

function getPurchaseOrderId({ organisation, projectDetails, extraOffset }) {
  const poNumber = parseInt(projectDetails.purchaseOrderCount || 0) + 1 + parseInt(extraOffset);
  return `${projectDetails.id}-PO${poNumber}`;
}

function getSheetDescription({ task, file, taskRevision, sheetCount }) {
  const fileType = file.type;
  let sheetCountForFileType = sheetCount;
  const latestTaskRevision = taskRevision || task.revisions.items[task.revisions.items.length - 1];

  latestTaskRevision.files?.items.forEach((crtFile) => {
    if (crtFile.type !== fileType || crtFile.isArchived) {
      return;
    }

    sheetCountForFileType += crtFile.sheets?.items.filter((x) => x.includeInPublish).length;
  });

  return String(1 + sheetCountForFileType);
}

function getRequestId({ organisationDetails, extraOffset }) {
  const requestNumber = parseInt(organisationDetails.requestCount || 0) + 1 + parseInt(extraOffset);
  return `${organisationDetails.id}-REQ${requestNumber}`;
}

function getTaskRevisionName({ task }) {
  if (!task || !task.revisions || !task.revisions.items || task.revisions.items.length === 0) {
    return "P01";
  }

  const nonArchivedRevisions = task.revisions.items.filter((x) => !x.isArchived);
  const latestTaskRevision = nonArchivedRevisions[nonArchivedRevisions.length - 1];

  let previousStatusIsConstruction = latestTaskRevision.name.startsWith("C");

  let numberPart = (parseInt(latestTaskRevision.name.substring(1)) || 0) + 1;

  if (numberPart < 10) {
    numberPart = `0${numberPart}`;
  }

  let newName = "";
  if (previousStatusIsConstruction) {
    newName = `C${numberPart}`;
  } else {
    newName = `P${numberPart}`;
  }

  return newName;
}

function getSheetRevisionName({ sheet, newStatus }) {
  let newStatusIsConstruction = newStatus?.toUpperCase().split(" ").join("_").includes("CONSTRUCTION");
  if (!sheet || !sheet.revisions || !sheet.revisions.items || sheet.revisions.items.length === 0) {
    return newStatusIsConstruction ? "C01" : "P01";
  }

  const nonArchivedRevisions = sheet.revisions.items.filter((x) => !x.isArchived);
  const latestSheetRevision = nonArchivedRevisions[nonArchivedRevisions.length - 1];

  let previousStatusIsConstruction = latestSheetRevision.status
    .toUpperCase()
    .split(" ")
    .join("_")
    .includes("CONSTRUCTION");

  let newName = "";

  let numberPart = (parseInt(latestSheetRevision.name.substring(1)) || 0) + 1;

  if (numberPart < 10) {
    numberPart = `0${numberPart}`;
  }

  if (newStatusIsConstruction) {
    if (previousStatusIsConstruction) {
      newName = `C${numberPart}`;
    } else {
      newName = "C01";
    }
  } else {
    newName = `P${numberPart}`;
  }

  return newName;
}

function getQuoteRevisionName({ quote }) {
  const possibleNames = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  if (!quote || !quote.currentRevisionName) {
    return possibleNames[0];
  }

  const nameIndexOfLatestSheetRevision = possibleNames.findIndex((x) => x === quote.currentRevisionName);
  if (nameIndexOfLatestSheetRevision === -1) {
    return null;
  }
  return possibleNames[nameIndexOfLatestSheetRevision + 1];
}

async function getFrontendFileName({ organisationDetails, task, taskRevision, templateId, fileType }) {
  if (!templateId) {
    return;
  }
  const templateDetails = organisationDetails.templates.items.find((x) => x.id === templateId);
  const filesWithSameTemplate = taskRevision.files.items.filter((file) => file.templateId === templateDetails?.id);
  let filesWithSameTemplateCount = filesWithSameTemplate.length;
  const deletedFilesByType = JSON.parse(taskRevision.deletedFilesByType || "{}");
  if (deletedFilesByType[fileType]) {
    filesWithSameTemplateCount += deletedFilesByType[fileType];
  }

  const result = `${templateDetails?.name} - ${String(1000 + filesWithSameTemplateCount + 1).substring(1)}`;
  return result;
}

// async function getTimelineBlockColor({ task, timelineBlock }) {
//   if (!window.location.origin?.includes("localhost")) {
//     return;
//   }

//   let hasTaskRevision =
//     timelineBlock.taskRevisionId &&
//     task?.revisions &&
//     window.organisationDetails.settings?.timeline?.planTaskRevisionsInsteadOfTasks;

//   let taskRevisionName;

//   if (timelineBlock.taskRevisionId) {
//     if (hasTaskRevision) {
//       let revision = task.revisions.items.find((x) => x.id === timelineBlock.taskRevisionId);
//       if (revision) {
//         taskRevisionName = revision.name;
//       }
//     }
//   }

//   if (!task || !taskRevisionName) {
//     return;
//   }

//   let taskRevisionNameUpperCase = taskRevisionName.toUpperCase().split(" ").join("_");

//   let color = "#444444";

//   switch (taskRevisionNameUpperCase) {
//     case "SURVEY":
//       color = "#FF69B4";
//       break;
//     case "TESTING":
//       color = "#31a4de";
//       break;
//     case "DESIGN":
//       color = "#1fb312";
//       break;
//     case "NMD_INSTALLATION":
//       color = "#decd31";
//       break;
//     case "RADIX_INSTALLATION":
//       color = "#FFA500";
//       break;
//     case "OTHER":
//       color = "#FF0000";
//       break;
//   }

//   return color;
// }

const functions = {
  getFileName,
  changeFileNameAtDownloadTime,
  changeSheetReference,
  getProjectId,
  getTaskId,
  getQuoteId,
  getInvoiceId,
  getPurchaseOrderId,
  getSheetDescription,
  getTaskRevisionName,
  getSheetRevisionName,
  getQuoteRevisionName,
  getFrontendFileName,
  getRequestId,
  // getTimelineBlockColor,
};

export default functions;
