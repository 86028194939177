import moment from "moment";

const fields = [
  {
    id: "quoteId",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Quote ID",
    value: ({ quote }) => {
      return `${quote.id}${quote.currentRevisionName || ""}`;
    },
  },
  {
    id: "quoteTitle",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Quote title",
    value: ({ quote }) => quote.title,
  },

  {
    id: "quoteAssigneeSignature",
    fieldTypes: ["signature"],
    label: "Assignee signature",
    value: ({ quote, users }) => {
      const user = users.find((user) => user.id === quote.assignedTo);
      if (!user) {
        return undefined;
      }

      return {
        image: user.signature,
        firstName: user.firstName,
        lastName: user.lastName,
      };
    },
  },
  {
    id: "quoteAssignee",
    fieldTypes: ["textfield"],
    label: "Assignee full name",
    value: ({ quote, users }) => {
      let user = users.find((x) => x.id === quote.assignedTo);
      if (!user) {
        return "";
      }
      return `${user.firstName ? `${user.firstName} ` : ""}${user.lastName || ""}`;
    },
  },
  {
    id: "quoteAssigneeInitials",
    fieldTypes: ["textfield"],
    label: "Assignee initials",
    value: ({ quote, users }) => {
      const assignee = users.find((user) => user.id === quote.assignedTo);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.firstName[0]}${assignee.lastName[0]}`;
    },
  },
  {
    id: "quoteAssigneePosition",
    fieldTypes: ["textfield"],
    label: "Assignee position",
    value: ({ quote, users }) => {
      const assignee = users.find((user) => user.id === quote.assignedTo);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.position}`;
    },
  },
  {
    id: "quoteAssigneeQualifications",
    fieldTypes: ["textfield"],
    label: "Assignee qualifications",
    value: ({ quote, users }) => {
      const assignee = users.find((user) => user.id === quote.assignedTo);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.qualifications}`;
    },
  },

  {
    id: "quoteReviewerSignature",
    fieldTypes: ["signature"],
    label: "Reviewer signature",
    value: ({ quote, users }) => {
      const user = users.find((user) => user.id === quote.checkedBy);
      if (!user) {
        return undefined;
      }

      return {
        image: user.signature,
        firstName: user.firstName,
        lastName: user.lastName,
      };
    },
  },
  {
    id: "quoteReviewer",
    fieldTypes: ["textfield"],
    label: "Reviewer full name",
    value: ({ quote, users }) => {
      let user = users.find((x) => x.id === quote.checkedBy);
      if (!user) {
        return "";
      }
      return `${user.firstName ? `${user.firstName} ` : ""}${user.lastName || ""}`;
    },
  },
  {
    id: "quoteReviewerInitials",
    fieldTypes: ["textfield"],
    label: "Reviewer initials",
    value: ({ quote, users }) => {
      const assignee = users.find((user) => user.id === quote.checkedBy);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.firstName[0]}${assignee.lastName[0]}`;
    },
  },
  {
    id: "quoteReviewerPosition",
    fieldTypes: ["textfield"],
    label: "Reviewer position",
    value: ({ quote, users }) => {
      const assignee = users.find((user) => user.id === quote.checkedBy);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.position}`;
    },
  },
  {
    id: "quoteReviewerQualifications",
    fieldTypes: ["textfield"],
    label: "Reviewer qualifications",
    value: ({ quote, users }) => {
      const assignee = users.find((user) => user.id === quote.checkedBy);
      if (!assignee) {
        return undefined;
      }
      return `${assignee.qualifications}`;
    },
  },

  {
    id: "quoteTotal",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Total",
    value: ({ quote }) => {
      return global.formatCurrency("GBP", quote.total);
    },
  },
  {
    id: "quoteSubtotal",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Subtotal",
    value: ({ quote }) => {
      return global.formatCurrency("GBP", quote.subtotal);
    },
  },
  {
    id: "quoteTaxRate",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Tax rate",
    value: ({ quote }) => {
      return `${quote?.taxRate}%`;
    },
  },
  {
    id: "quoteTotalTax",
    type: "string",
    fieldTypes: ["textfield"],
    label: "Total tax",
    value: ({ quote }) => {
      return global.formatCurrency("GBP", quote.totalTax);
    },
  },
  {
    id: "quoteReviewApprovedAt",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Review approved at",
    value: ({ quote, dateFormat, addDays }) => {
      if (!quote || !quote.reviewApprovedAt) {
        return "";
      }
      return moment(quote.reviewApprovedAt).add(addDays, "day").format(dateFormat);
    },
  },
  {
    id: "quoteReviewApprovedAtOrToday",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Review approved at or today",
    value: ({ quote, dateFormat, addDays }) => {
      if (!quote || !quote.reviewApprovedAt) {
        return moment().add(addDays, "day").format(dateFormat);
      }
      return moment(quote.reviewApprovedAt).add(addDays, "day").format(dateFormat);
    },
  },
  {
    id: "quoteLineItem",
    repeatForFieldName: "quoteLineItem",
    fieldTypes: ["repeatFor"],
    label: "Each line item",
    value: ({ quote }) => {
      return quote?.lineItems?.items;
    },
  },
];
export function getFields() {
  return fields;
}
