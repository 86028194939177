import { useEffect, useState } from "react";
import { Button, Spin } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useGetSetState } from "react-use";
import cx from "classnames";

import withSubscriptions from "common/withSubscriptions";
import { Organisation } from "common/types";
import fetchAndSetAdminTask from "NonTaskReports/fetchAndSetAdminTask";

import EngineeringChart from "./EngineeringTab/EngineeringChart/EngineeringChart";
import EngineeringTasksCard from "./EngineeringTab/EngineeringTasksCard/EngineeringTasksCard";
import EngineeringProjectsCard from "./EngineeringTab/EngineeringProjectsCard/EngineeringProjectsCard";
import EngineeringUsersCard from "./EngineeringTab/EngineeringUsersCard/EngineeringUsersCard";
import EngineeringClientsCard from "./EngineeringTab/EngineeringClientsCard/EngineeringClientsCard";
import FinancialsChart from "./FinancialsTab/FinancialsChart/FinancialsChart";
import CustomApplicationPage from "CustomApplicationPage/CustomApplicationPage";
import AddFileToTaskRevisionModal from "Modals/AddFileToTaskRevisionModal/AddFileToTaskRevisionModal";
import Tabs from "reusableComponents/Tabs/Tabs";

import "./AnalyticsPage.scss";

type Props = {
  organisationDetails: Organisation;
  history: RouteComponentProps["history"];
  setBoxedLayout: (boxedLayout: boolean) => void;
  apiUser: any;
  tasks: any;
};

interface State {
  adminTask?: any;
  isAddPageModalVisible: boolean;
}

function AnalyticsPage(props: Props) {
  const { tasks, organisationDetails, apiUser, history, setBoxedLayout } = props;
  const [activeTab, setActiveTab] = useState("engineering");

  const [getState, setState] = useGetSetState<State>({
    adminTask: undefined,
    isAddPageModalVisible: false,
  });

  useEffect(() => {
    fetchAndSetAdminTask({
      tasks,
      apiUser,
      organisationDetails,
      setState,
    });
  }, [organisationDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");
    if (tab) {
      setActiveTab(tab);
    }

    setBoxedLayout(false);
    return () => {
      setBoxedLayout(true);
    };
  }, []); //eslint-disable-line

  const { adminTask } = getState();

  if (!adminTask) {
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
        <Spin />
      </div>
    );
  }

  const { isAddPageModalVisible } = getState();

  let tabItems = [
    {
      id: "engineering",
      title: "Engineering",
      content: (
        <div className="engineering-chart">
          <EngineeringChart organisationDetails={organisationDetails} />
        </div>
      ),
    },
    {
      id: "financials",
      title: "Financials",
      content: (
        <div className="financials-tab">
          <FinancialsChart organisationDetails={organisationDetails} />
        </div>
      ),
    },
  ];

  if (organisationDetails.fileTypesUsed?.includes("APP_PAGE")) {
    let latestAdminTaskRevision = adminTask?.revisions.items.slice(-1)[0];
    let appPageFiles = latestAdminTaskRevision?.files.items.filter((file) => file.type === "APP_PAGE");

    for (let file of appPageFiles) {
      let tabId = `custom-page-${file.id}`;
      tabItems.push({
        id: tabId,
        title: file.name,
        content: (
          <CustomApplicationPage
            key={tabId}
            file={file}
            organisationDetails={organisationDetails}
            urlToRedirectToAfterDelete={"/analytics"}
            apiUser={apiUser}
          />
        ),
      });
    }
  }

  let shouldHideHeader = window.location.href?.includes("hide-header=true");

  return (
    <div className={cx("analytics-page", { "hide-header": shouldHideHeader })}>
      <Tabs
        className="analytics-page-tabs"
        activeTab={activeTab}
        onTabClick={(tabKey) => {
          history.replace(`/analytics?tab=${tabKey}`);
          setActiveTab(tabKey);
        }}
        tabBarExtraContent={{
          right: (
            <>
              {/* @ts-ignore */}
              {organisationDetails.fileTypesUsed?.includes("APP_PAGE") && (
                <Button
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  style={{ marginRight: "0.5rem" }}
                  onClick={() => {
                    setState({ isAddPageModalVisible: true });
                  }}
                >
                  Add dashboard
                </Button>
              )}
            </>
          ),
        }}
        items={tabItems}
      />

      {isAddPageModalVisible && (
        <AddFileToTaskRevisionModal
          visible={true}
          task={adminTask}
          taskRevision={adminTask.revisions.items.slice(-1)[0]}
          fileType="APP_PAGE"
          parentType="ORGANISATION"
          onClose={() => {
            setState({ isAddPageModalVisible: false });
            fetchAndSetAdminTask({
              tasks,
              apiUser,
              organisationDetails,
              setState,
            });
          }}
        />
      )}
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: AnalyticsPage,
    subscriptions: ["organisationDetails", "tasks", "projects", "users", "clients"],
  })
);
