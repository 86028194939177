import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import localForage from "localforage";
import { ConfigProvider } from "antd";
import { Amplify, Auth, Storage } from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";

import { callGraphQLSimple, getRestEndpoint, fetchCollection } from "common/apiHelpers";

import "common/globalDeclarations";
import awsExports from "./aws-exports";
import getAccountName from "common/getAccountName";
import { roundToDecimals } from "common/mathHelpers";

import App from "App/App";

import "./index.scss";

const localDatabase = localForage.createInstance({
  name: "draughthub-settings",
});

window.localDatabase = localDatabase;
window.Auth = Auth;
window.Storage = Storage;
window.awsExports = awsExports;
window.isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
window.s3Urls = {};
window.pdfjs = window["pdfjs-dist/build/pdf"];

global.isBrowser = true;
window.randomUUID = () => {
  if (window.crypto && window.crypto.randomUUID) {
    return window.crypto.randomUUID();
  }
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

window.formatCurrency = function formatCurrency(currency: string, value: number) {
  let roundedValue = roundToDecimals(value, 2);
  return new Intl.NumberFormat("en-EN", {
    style: "currency",
    currency,
  }).format(roundedValue);
};
global.formatCurrency = window.formatCurrency;
window.callGraphQLSimple = callGraphQLSimple;
window.fetchCollection = fetchCollection;

window.authorId = `${Date.now()}-${Math.floor(Math.random() * 10000)}-${Math.floor(Math.random() * 100000)}`;

const sentryReplayConfig = {
  networkDetailAllowUrls: [awsExports.aws_appsync_graphqlEndpoint, getRestEndpoint()],
  maskAllText: false,
  maskAllInputs: false,
  blockAllMedia: false,
  mask: [".sentry-mask-sensitive"],
  unmask: [".sentry-mask", "[data-sentry-mask]"],
};

if (window.location.hostname === "localhost") {
  // Sentry.init({
  //   dsn: "https://6186b09da9d4eb3b18aea0aec3630450@o4504357391106048.ingest.sentry.io/4505804359532544",
  //   integrations: [
  //     new Sentry.BrowserTracing({
  //       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //       tracePropagationTargets: ["localhost"],
  //     }),
  //     new Sentry.Replay(sentryReplayConfig),
  //   ],
  //   // Performance Monitoring
  //   tracesSampleRate: 0, // Capture 100% of the transactions, reduce in production!
  //   // Session Replay
  //   replaysSessionSampleRate: 0, // between 0 and 1, with 1 being 100%
  //   replaysOnErrorSampleRate: 0, // between 0 and 1, with 1 being 100%
  // });
} else {
  Sentry.init({
    dsn: "https://801a875d0a5c51ed61498da3b38dff33@o4504357391106048.ingest.sentry.io/4505804336857088",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["https://draughthub.com/api/"],
      }),
      new Sentry.Replay(sentryReplayConfig),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 10% of transactions
    // Session Replay
    replaysSessionSampleRate: 0, // between 0 and 1, with 1 being 100%
    replaysOnErrorSampleRate: 1, // between 0 and 1, with 1 being 100%
  });
}

const { oauth, ...awsExportsWithoutOAuth } = awsExports;

let OAuthConfig = {};

const accountName = getAccountName();
window.accountName = accountName;

if (accountName === "EIS") {
  OAuthConfig = {
    oauth: {
      domain: "eis-draughthub.auth.eu-west-2.amazoncognito.com",
      scope: ["openid", "email", "profile"],
      redirectSignIn: window.location.origin + "/",
      redirectSignOut: window.location.origin + "/",
      responseType: "code",
    },
  };
}

Amplify.configure({
  ...awsExportsWithoutOAuth,
  ...OAuthConfig,

  API: {
    graphql_headers: async () => ({
      Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    }),
  },
});
loadApp();

function loadApp() {
  ReactDOM.render(
    <Router>
      <ConfigProvider
        theme={{
          token: {
            // colorPrimary: "#19aae8",
            colorPrimary: "#19aae8",
            colorInfo: "#19aae8",
            colorTextBase: "#002f44",
            colorBorder: "#d2e4ec",
            borderRadius: 4,
            fontFamily: "Inter",
            fontSize: 13,
            linkDecoration: "none",
          },
        }}
      >
        <App />
      </ConfigProvider>
    </Router>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
