import { useState } from "react";
import moment from "moment";
import { Table } from "antd";
import Card from "Card/Card";
import Switch from "Switch/Switch";
import ActivityItemPDFButton from "ActivityItemPDFButton/ActivityItemPDFButton";

import "./PurchaseOrderActivity.scss";

export default function PurchaseOrderActivity({ purchaseOrder, users }) {
  const [includeTotalChanges, setIncludeTotalChanges] = useState(false);
  const [includeReviewActivity, setIncludeReviewActivity] = useState(false);

  return (
    <Card
      title="Purchase order Activity"
      className="purchase-order-activity-card"
      actions={
        <div className="activity-filters">
          <div className="purchase-order-filter-item">
            <Switch
              data-cy="switch-display-review-activity"
              checked={includeReviewActivity}
              onChange={(checked) => {
                setIncludeReviewActivity(checked);
              }}
              label="Display review activity"
            />
          </div>
          <div className="purchase-order-filter-item">
            <Switch
              data-cy="switch-display-changes-to-total"
              checked={includeTotalChanges}
              onChange={(checked) => {
                setIncludeTotalChanges(checked);
              }}
              label="Display changes to the total"
            />
          </div>
        </div>
      }
    >
      <Table
        pagination={{ hideOnSinglePage: true, pageSize: 500 }}
        tableLayout="fixed"
        bordered={true}
        scroll={{ x: 770 }}
        columns={[
          {
            title: "Title",
            dataIndex: "content",
            key: "content",
            width: 350,
            align: "left",
          },
          {
            title: "Author",
            dataIndex: "author",
            key: "author",
            width: 150,
          },
          {
            title: "Purchase order total",
            dataIndex: "totalFormatted",
            key: "total",
            width: 100,
          },
          {
            title: "Date",
            dataIndex: "date",
            key: "date",
            width: 170,
          },
          {
            title: "Download PDF",
            dataIndex: "download",
            key: "download",
            width: 100,
            render: (_, record) => {
              return <ActivityItemPDFButton parent={purchaseOrder} activityItem={record} />;
            },
          },
        ]}
        dataSource={[...purchaseOrder.activity.items]
          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          .filter((activityItem) => {
            if (activityItem.type === "TOTAL_CHANGED") {
              return includeTotalChanges;
            } else if (activityItem.type.startsWith("REVIEW")) {
              return includeReviewActivity;
            }
            return true;
          })
          .map((activityItem) => {
            let content = activityItem.content;

            if (!content) {
              switch (activityItem.type) {
                case "TOTAL_CHANGED":
                  content = "Total changed";
                  break;
                case "IN_PROGRESS":
                  content = "Purchase order is back in progress";
                  break;
                case "DRAFT":
                  content = "Purchase order is now a draft";
                  break;
                default:
                  content = `Purchase order ${activityItem.type.toLowerCase()}`;
                  break;
              }
            }

            let author;
            if (activityItem.author) {
              let authorDetails = users?.find((user) => user.id === activityItem.author);
              if (!authorDetails) {
                author = activityItem.author;
              } else {
                author = `${authorDetails.firstName} ${authorDetails.lastName}`;
              }
            }
            return {
              ...activityItem,
              content,
              author,
              dateAgo: `${moment(activityItem.createdAt).fromNow()}`,
              date: moment(activityItem.createdAt).format("DD MMM YYYY - HH:mm:ss"),
              totalFormatted: window.formatCurrency("GBP", activityItem.total),
            };
          })}
      />
    </Card>
  );
}
