import { getSimpleLabel } from "common/labels";

const fields = [
  {
    id: "EIS_projects_for_design_solution",
    label: "EIS - Projects for Design Solution",
    repeatForFieldName: "project",
    value: async ({ form, client }) => {
      let projects = [];
      let query = `
        query ListProjectsByOrganisation(
          $organisation: ID
          $title: ModelStringKeyConditionInput
          $sortDirection: ModelSortDirection
          $filter: ModelProjectFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listProjectsByOrganisation(
            organisation: $organisation
            title: $title
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
          ) {
            items {
              id
              title
              description
              assignedTo
              createdAt
              tasks {
                items {
                  id
                  title
                  status
                  isFinished
                  isArchived
                  isHidden
                }
              }
            }
            nextToken
          }
        }
        `;
      let nextToken;
      while (true) {
        let variables = {
          organisation: window.organisationDetails.id,
          limit: 1000,
          nextToken,
          filter: {
            clientId: {
              eq: "82652c1f-f8a9-4d08-9463-531e33b82136",
            },
          },
        };
        let response = (
          await window.callGraphQLSimple({
            message: `Failed to fetch ${getSimpleLabel("projects")}`,
            queryRaw: query,
            variables,
          })
        ).data.listProjectsByOrganisation;
        nextToken = response.nextToken;
        projects = [...projects, ...response.items];

        if (!nextToken) {
          break;
        }
      }
      return projects;
    },
  },
];
export function getFields() {
  return fields;
}
