import { Button, Modal, Tooltip, Form, Input as AntInput, message } from "antd";
import { useGetSetState } from "react-use";
import { PlusCircleOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";

import { callGraphQLSimple } from "common/apiHelpers";

import Card from "Card/Card";
import Input from "Input/Input";

import "./Menus.scss";

export default function Menus({ organisationDetails }) {
  const [getState, setState] = useGetSetState({
    isAddingNewMenuItem: false,
    selectedMenuItem: null,
    selectedMenuForAddingItem: null,
  });

  const [formMenuItem] = Form.useForm();

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  const { isAddingNewMenuItem, selectedMenuItem, selectedMenuForAddingItem } = getState();

  async function onAddMenuClick() {
    await callGraphQLSimple({
      queryName: "updateOrganisation",
      variables: {
        input: {
          id: organisationDetails.id,
          menus: [
            ...(organisationDetails.menus || []),
            {
              id: `${Date.now()}-${Math.floor(Math.random() * 1000)}`,
              label: `New menu ${moment().format("DD-MM-YYYY HH:mm:ss")}`,
              items: [],
            },
          ],
        },
      },
    });
  }

  async function onMenuRename({ menu, label }) {
    await callGraphQLSimple({
      queryName: "updateOrganisation",
      variables: {
        input: {
          id: organisationDetails.id,
          menus: (organisationDetails.menus || []).map((x) => (x.id === menu.id ? { ...x, label } : x)),
        },
      },
    });
  }

  async function onMenuItemUpdate({ menu, item, label, url }) {
    let newMenus = JSON.parse(JSON.stringify(organisationDetails.menus || []));

    let selectedMenuInNewMenus = newMenus.find((x) => x.id === menu.id);

    if (!selectedMenuInNewMenus) {
      message.error("Selected menu not found in existing menus");
      return;
    }

    let updatedMenuItem = JSON.parse(JSON.stringify(item));
    if (label !== undefined) {
      updatedMenuItem.label = label;
    }

    if (url !== undefined) {
      updatedMenuItem.url = url;
    }

    selectedMenuInNewMenus.items = selectedMenuInNewMenus.items.map((x) => (x.id === item.id ? updatedMenuItem : x));

    await callGraphQLSimple({
      queryName: "updateOrganisation",
      variables: {
        input: {
          id: organisationDetails.id,
          menus: newMenus,
        },
      },
    });
  }

  function onDeleteMenuItemClick(menu, item) {
    Modal.confirm({
      title: "Delete menu item",
      content: (
        <>
          Are you sure you want to delete the menu item <b>{item.label}</b>?
        </>
      ),
      onOk: async () => {
        let newMenus = JSON.parse(JSON.stringify(organisationDetails.menus || []));

        let selectedMenuInNewMenus = newMenus.find((x) => x.id === menu.id);

        if (!selectedMenuInNewMenus) {
          message.error("Selected menu not found in existing menus");
          return;
        }

        selectedMenuInNewMenus.items = selectedMenuInNewMenus.items.filter((x) => x.id !== item.id);

        await callGraphQLSimple({
          queryName: "updateOrganisation",
          variables: {
            input: {
              id: organisationDetails.id,
              menus: newMenus,
            },
          },
        });
      },
    });
  }

  function onDeleteMenuClick(menu) {
    Modal.confirm({
      title: "Delete menu",
      content: (
        <>
          Are you sure you want to delete the menu <b>{menu.label}</b>?
        </>
      ),
      onOk: async () => {
        await callGraphQLSimple({
          queryName: "updateOrganisation",
          variables: {
            input: {
              id: organisationDetails.id,
              menus: (organisationDetails.menus || []).filter((x) => x.id !== menu.id),
            },
          },
        });
      },
    });
  }

  async function onFormItemSubmit(values) {
    let newMenus = JSON.parse(JSON.stringify(organisationDetails.menus || []));
    let { selectedMenuItem, selectedMenuForAddingItem } = getState();

    if (!selectedMenuForAddingItem) {
      message.error("No menu selected");
      return;
    }

    let selectedMenuInNewMenus = newMenus.find((x) => x.id === selectedMenuForAddingItem.id);

    if (!selectedMenuInNewMenus) {
      message.error("Selected menu not found in existing menus");
      return;
    }

    if (selectedMenuItem) {
      selectedMenuInNewMenus.items = selectedMenuInNewMenus.items.map((x) =>
        x.id === selectedMenuItem.id ? { ...x, ...values } : x
      );
    } else {
      selectedMenuInNewMenus.items.push({
        id: `${Date.now()}-${Math.floor(Math.random() * 1000)}`,
        ...values,
      });
    }

    await callGraphQLSimple({
      queryName: "updateOrganisation",
      variables: {
        input: {
          id: organisationDetails.id,
          menus: newMenus,
        },
      },
    });

    formMenuItem.resetFields();
    setState({
      isAddingNewMenuItem: false,
      selectedMenuItem: null,
      selectedMenuForAddingItem: null,
    });
  }

  function displayMenu(menu) {
    return (
      <div key={menu.id} className="menu">
        <div className="menu-header">
          <Input
            className="menu-label"
            defaultValue={menu.label}
            fullWidth
            onChange={(label) => onMenuRename({ menu, label })}
            fireOnChangeWithoutBlurWithDebounce
          />
          <div className="menu-top-level-buttons">
            <Tooltip title="Delete menu">
              <Button icon={<DeleteOutlined />} onClick={() => onDeleteMenuClick(menu)} />
            </Tooltip>
            <Tooltip title="Add item to menu">
              <Button
                icon={<PlusOutlined />}
                onClick={() => {
                  setState({
                    selectedMenuItem: null,
                    isAddingNewMenuItem: true,
                    selectedMenuForAddingItem: menu,
                  });
                }}
              />
            </Tooltip>
          </div>
        </div>
        {displayMenuItems(menu, menu.items)}
      </div>
    );
  }

  function displayMenuItems(menu, items) {
    if (!items?.length) {
      return null;
    }

    return (
      <ul className="menu-items">
        {items.map((item) => {
          return (
            <li key={item.id} className="menu-item">
              <div className="menu-item-details">
                <Input
                  className="menu-item-label"
                  onChange={(label) => onMenuItemUpdate({ menu, item, label })}
                  defaultValue={item.label}
                  fullWidth
                />
                <Input
                  className="menu-item-url"
                  onChange={(url) => onMenuItemUpdate({ menu, item, url })}
                  defaultValue={item.url}
                  fullWidth
                />
              </div>
              <div className="menu-item-buttons">
                <Tooltip title="Delete menu item">
                  <Button icon={<DeleteOutlined />} onClick={() => onDeleteMenuItemClick(menu, item)} />
                </Tooltip>
              </div>
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <>
      <Card
        title="Menus"
        className="menus"
        actions={
          <Button type="primary" icon={<PlusCircleOutlined />} onClick={onAddMenuClick}>
            Add menu
          </Button>
        }
      >
        {(organisationDetails.menus || []).map(displayMenu)}
      </Card>
      {(selectedMenuItem || isAddingNewMenuItem) && (
        <Modal
          title={selectedMenuItem ? "Edit menu item" : "Add menu item"}
          open={true}
          onCancel={() => {
            formMenuItem.resetFields();
            setState({ isAddingNewMenuItem: false });
          }}
          footer={null}
        >
          <Form form={formMenuItem} initialValues={selectedMenuItem} onFinish={onFormItemSubmit}>
            <Form.Item
              label="Label"
              name="label"
              rules={[
                {
                  required: true,
                  message: "You must specify a label",
                },
              ]}
            >
              <AntInput autoComplete="off" />
            </Form.Item>
            <Form.Item
              label="URL"
              name="url"
              rules={[
                {
                  required: true,
                  message: "You must specify a url",
                },
              ]}
            >
              <AntInput autoComplete="off" />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
}
