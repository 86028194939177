import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Button, Modal, Typography, Breadcrumb, Empty, Tooltip, Checkbox, notification, message } from "antd";
import { Storage } from "aws-amplify";
import cx from "classnames";
import query from "query-string";
import { readAndCompressImage } from "browser-image-resizer";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  UndoOutlined,
  // TableOutlined,
  // MenuOutlined,
  FolderAddOutlined,
  DownloadOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { downloadBlob, openAttachment, calculateReadableSize } from "common/helpers";
import { callGraphQLSimple, callRest } from "common/apiHelpers";
import getS3File from "common/getS3File";
import withSubscriptions from "common/withSubscriptions";
import { KEY_TYPES, getFilenameFromKey, getExtensionFromKey, encodeKey, getAttachmentTypeFromKey } from "common/shared";
import { VersionsIcon } from "common/icons";
import { isAuthorised } from "common/permissions";
import {
  THRESHOLD_FOR_FULL_SIZE_VERSION,
  ATTACHMENTS_MAX_IMAGE_WIDTH,
  ATTACHMENTS_MAX_IMAGE_HEIGHT,
  ATTACHMENTS_MAX_UPLOAD_FILE_COUNT,
  ATTACHMENTS_CONFIRMATION_FILE_COUNT,
  ATTACHMENTS_CONFIRMATION_TOTAL_SIZE,
} from "common/constants";
import { getSimpleLabel } from "common/labels";

import CopyLinkButton from "CopyLinkButton/CopyLinkButton";
import Card from "Card/Card";
import FilePreview from "FilePreview/FilePreview";
import CreateFolderModal from "Modals/CreateFolderModal/CreateFolderModal";
import EmailDetailsModal from "Modals/EmailDetailsModal/EmailDetailsModal";
import AttachmentListItem from "./AttachmentListItem/AttachmentListItem";
import UploadProgressView from "./UploadProgressView/UploadProgressView";
import DragAndDrop from "DragAndDrop/DragAndDrop";
import VersionsModal from "Modals/VersionsModal/VersionsModal";
import Input from "Input/Input";
import DocumentDetailsModal from "Modals/DocumentDetailsModal/DocumentDetailsModal";
// import AttachmentThumbnail from "./AttachmentThumbnail/AttachmentThumbnail";

import "./Attachments.scss";

const Buffer = require("buffer/").Buffer;
const clientId = "947855302333-5k6s5596khup2sdkpugasn2unnjdfjg2.apps.googleusercontent.com";
const developerKey = "AIzaSyDZiusBP1t5a2wFEueS_cx8xX4V8bfTKx4";

// The Client ID obtained from the Google API Console. Replace with your own Client ID.

// Replace with your own project number from console.developers.window.googlecom.
// See "Project number" under "IAM & Admin" > "Settings"
const appId = "947855302333";

// Scope to use to access user's Drive items.
const scope = [
  "https://www.googleapis.com/auth/drive",
  "https://www.googleapis.com/auth/drive.file",
  "https://www.googleapis.com/auth/drive.readonly",
  "https://www.googleapis.com/auth/drive.metadata.readonly",
  "https://www.googleapis.com/auth/drive.appdata",
  "https://www.googleapis.com/auth/drive.metadata",
  "https://www.googleapis.com/auth/drive.photos.readonly",
];

let oauthToken;
let pickerApiLoaded = false;

export class Attachments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreateFolderModalOpen: false,
      isPreparingDownload: false,
      isDownloadWaitModalOpen: false,
      rootPrefix: null,
      realPrefix: null,
      isHome: false,
      viewType: "LIST",
      selectedItems: [],
      isUploadViewVisible: false,
      pendingFiles: [],
      uploadIsFinished: false,
      items: null,
      nonSearchItems: null, // to be used to cache the items before a search
      isTrashOpen: false,
      isLoading: false,
      pdfPreviewData: null,
      isLoadingFile: false,
      isVersionsModalVisible: false,
      isFilePreviewVisible: false,
      isSelectAllChecked: false,
      versions: [],
      documentViewModalAttachment: null,
      failedUploads: [],
      emailPreviewData: null,
      isLoadingEmail: false,
      searchValue: "",
      isSearchActive: true, // to create a window of time where the search is not active while a user has just clicked on a folder in the search results
    };

    this.foldersToBeCreated = [];
    this.filesUploadedCount = 0;

    this.fileInputRef = React.createRef();
    this.lastAttachmentSearchId = 0;
  }

  componentDidMount() {
    const { organisationDetails, defaultPath, task, isDocumentLibrary, selectedItems, defaultRelativePath } =
      this.props;

    let newRootPrefix;
    if (defaultPath) {
      newRootPrefix = defaultPath + "/";
      if (newRootPrefix.startsWith("Document Library")) {
        this.setState({ isDocumentLibrary: true });
      }
    } else {
      const currentQueryParams = query.parse(window.location.search);
      if (currentQueryParams.attachmentPath && currentQueryParams.attachmentPath.length > 0) {
        newRootPrefix = currentQueryParams.attachmentPath;
      } else if (task) {
        if (organisationDetails?.settings?.task?.dontCreateTaskFolders) {
          newRootPrefix = ``;
        } else {
          newRootPrefix = `${task.id}/`;
        }
      } else if (isDocumentLibrary) {
        newRootPrefix = "Document Library/";
      } else {
        newRootPrefix = "";
      }

      if (defaultRelativePath) {
        newRootPrefix += defaultRelativePath + "/";
      }
    }

    if (selectedItems) {
      this.setState({ selectedItems: selectedItems }, this.onChange);
    }

    while (newRootPrefix?.includes("//")) {
      newRootPrefix = newRootPrefix.replace("//", "/");
    }

    this.setState({ rootPrefix: newRootPrefix }, () => {
      this.fetchFiles();
      this.updateUrl();
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { project, task, organisationDetails } = this.props;
    const parent = project || task || organisationDetails;
    const oldParent = prevProps.project || prevProps.task || prevProps.organisationDetails;

    if (this.state.rootPrefix !== prevState.rootPrefix) {
      if (this.props.onPrefixChange && typeof this.props.onPrefixChange === "function") {
        this.props.onPrefixChange(this.state.rootPrefix.split("//").join("/"));
      }
    }

    if (
      (this.state.rootPrefix !== prevState.rootPrefix && prevState.rootPrefix !== null) ||
      parent?.itemSubscription !== oldParent?.itemSubscription
    ) {
      this.fetchFiles();
    } else {
      if (this.state.isSearchActive && this.state.searchValue !== prevState.searchValue) {
        if (this.state.searchValue) {
          this.search();
        } else {
          this.setState({
            items: this.state.nonSearchItems,
            nonSearchItems: null,
          });
        }
      }
    }
  }

  search = async () => {
    const { searchValue } = this.state;
    if (!this.state.realPrefix) {
      setTimeout(() => {
        this.search();
      }, 100);
      return;
    }

    let searchId = Date.now();
    this.lastAttachmentSearchId = searchId;

    let realPrefixWithoutTrailingSlash = this.state.realPrefix;
    if (realPrefixWithoutTrailingSlash.slice(-1) === "/") {
      realPrefixWithoutTrailingSlash = realPrefixWithoutTrailingSlash.slice(0, -1);
    }

    let searchResults = [];

    let query = `${this.state.realPrefix || ""}`;
    if (searchValue) {
      query += `*${searchValue || ""}*`;
    }

    this.setState({ isLoading: true });

    try {
      let response = await callRest({
        message: "Failed to search attachments",
        method: "GET",
        route: `/search?q=${encodeURIComponent(query.toLowerCase())}`,
      });

      if (searchId !== this.lastAttachmentSearchId) {
        return;
      }

      searchResults = response || [];

      let searchItems = searchResults?.map((item) => {
        return {
          key: item._source.id,
          name: item._source.name,
          type: item._source.type,
          size: item._source.size,
          lastModified: item._source.s3UpdatedAt,
        };
      });

      let newState = {
        items: searchItems,
        isLoading: false,
      };

      if (!this.state.nonSearchItems) {
        newState.nonSearchItems = this.state.items;
      }

      this.setState(newState);
    } catch (e) {
      let newState = {
        isLoading: false,
      };

      if (!this.state.nonSearchItems) {
        newState.nonSearchItems = this.state.items;
      }

      this.setState(newState);
    }
  };

  getHomePrefix = () => {
    const { task, project, client, request, apiUser } = this.props;
    const parent = task || project || client || request;
    const rootPrefix = `public/${parent?.organisation || apiUser.organisation}`;
    return rootPrefix;
  };

  listAttachments = async ({ project, prefix, isDocumentLibrary }) => {
    const { defaultPath, client, request } = this.props;

    let realPrefix;
    if (isDocumentLibrary) {
      realPrefix = `${this.getHomePrefix()}/${prefix}`;
    } else if (defaultPath) {
      realPrefix = `${this.getHomePrefix()}/${this.state.rootPrefix}`;
    } else if (client) {
      const clientFolder = await encodeKey({
        type: KEY_TYPES.CLIENT_FOLDER,
        data: {
          organisation: client.organisation,
          clientId: client.id,
        },
      });
      realPrefix = `${clientFolder}/attachments/${prefix}`;
    } else if (request) {
      const requestFolder = await encodeKey({
        type: KEY_TYPES.REQUEST_FOLDER,
        data: {
          organisation: request.organisation,
          requestId: request.id,
        },
      });
      realPrefix = `${requestFolder}/attachments/${prefix}`;
    } else {
      const projectFolder = await encodeKey({
        type: KEY_TYPES.PROJECT_FOLDER,
        data: {
          organisation: project.organisation,
          projectId: project.id,
        },
      });

      realPrefix = `${projectFolder}/attachments/${prefix}`;
    }
    realPrefix = realPrefix.split("//").join("/");

    // we separately request the non-versioned list of objects and the list of versions,
    // so we can separate the things which have been deleted (both files and folders)
    let [listResponse, listVersionsResponse] = await Promise.all([
      callRest({
        message: "Failed to list attachments",
        method: "GET",
        route: `/s3-list?prefix=${btoa(realPrefix)}&withDelimiter=true`,
        includeCredentials: false,
      }),
      callRest({
        message: "Failed to list attachment versions",
        method: "GET",
        route: `/s3-list-versions?prefix=${btoa(realPrefix)}&withDelimiter=true`,
        includeCredentials: false,
      }),
    ]);

    let contents = [];

    listVersionsResponse.CommonPrefixes.forEach((versionsFolder) => {
      let matchingFolder = listResponse.CommonPrefixes.find((x) => x.Prefix === versionsFolder.Prefix);
      if (!matchingFolder) {
        versionsFolder.deleted = true;
      }
    });

    listVersionsResponse.Versions.forEach((version) => {
      let matchingContentItem = contents.find((x) => x.Key === version.Key);
      if (!matchingContentItem) {
        contents.push({
          ...version,
          Versions: [version],
        });
      } else {
        matchingContentItem.Versions.push(version);
      }
    });
    listVersionsResponse.DeleteMarkers.forEach((deleteMarker) => {
      let matchingContentItem = contents.find((x) => x.Key === deleteMarker.Key);
      if (matchingContentItem) {
        matchingContentItem.DeleteMarker = deleteMarker;
      }
    });
    listVersionsResponse.Contents = contents;
    this.setState({
      realPrefix,
    });
    return listVersionsResponse;
  };

  isDocumentLibrary = () => {
    const { rootPrefix } = this.state;
    return rootPrefix?.includes("Document Library");
  };

  shouldRestrictAccessToThisPrefix = () => {
    const { rootPrefix } = this.state;
    const { organisationDetails } = this.props;
    const patternQuotes = /projects\/[^/]+\/quotes\//;
    const patternInvoices = /projects\/[^/]+\/invoices\//;
    const patternPurchaseOrders = /projects\/[^/]+\/purchase-orders\//;

    if (organisationDetails?.settings?.general?.hideFinancials && !isAuthorised(["QUOTES.WRITE_AMOUNT"])) {
      if (
        patternQuotes.test(rootPrefix) ||
        patternInvoices.test(rootPrefix) ||
        patternPurchaseOrders.test(rootPrefix)
      ) {
        return true;
      }
    }

    return false;
  };

  fetchFiles = async () => {
    this.setState({ items: null });
    const { client, project, task, request, apiUser, organisationDetails } = this.props;
    const { isHome } = this.state;

    this.setState({ isLoading: true });
    let s3ListResponse;
    let items = [];
    let isDocumentLibrary = this.isDocumentLibrary();
    if (isHome) {
      if (organisationDetails?.settings?.documentLibrary?.usesDocumentLibrary) {
        items.push({
          key: `public/${apiUser.organisation}/Document Library`,
          name: "Document Library", // folders' names end in a slash, so we need to go another element back
          type: "FOLDER",
          deleted: false,
        });
      }
      if (client) {
        items.push({
          key: "",
          prefixToUse: "",
          name: "Client attachments", // folders' names end in a slash, so we need to go another element back
          type: "FOLDER",
          deleted: false,
        });
      } else if (request) {
        items.push({
          key: "",
          prefixToUse: "",
          name: `${getSimpleLabel("Request")} attachments`, // folders' names end in a slash, so we need to go another element back
          type: "FOLDER",
          deleted: false,
        });
      } else {
        items.push({
          key: "",
          prefixToUse: "",
          name: "Project attachments", // folders' names end in a slash, so we need to go another element back
          type: "FOLDER",
          deleted: false,
        });
      }
    } else {
      if (this.shouldRestrictAccessToThisPrefix()) {
        this.setState({
          items: [
            {
              key: "",
              prefixToUse: "",
              name: "You do not have permission to view this folder",
              type: "ACCESS_DENIED",
              deleted: false,
            },
          ],
          isLoading: false,
          searchValue: "",
        });
        return;
      }

      if (client || project || request || task?.project || isDocumentLibrary) {
        s3ListResponse = await this.listAttachments({
          project: request || project || task?.project,
          prefix: this.state.rootPrefix,
          isDocumentLibrary,
        });
        s3ListResponse.CommonPrefixes.forEach((item) => {
          let prefixParts = item.Prefix.split("/");
          items.push({
            key: item.Prefix,
            name: prefixParts[prefixParts.length - 2], // folders' names end in a slash, so we need to go another element back
            type: "FOLDER",
            deleted: item.deleted,
          });
        });
        s3ListResponse.Contents.forEach((item) => {
          if (item.Key === s3ListResponse.Prefix) {
            // if we're inside a folder, S3 includes that as part of the contents,
            // even though it's not actually an object, so we want to skip it
            return;
          }

          const type = getAttachmentTypeFromKey(item.Key);

          items.push({
            key: item.Key,
            etag: item.ETag,
            lastModified: item.LastModified,
            storageClass: item.StorageClass,
            name: getFilenameFromKey(item.Key, true),
            type,
            size: item.Size,
            versions: item.Versions,
            deleteMarker: item.DeleteMarker,
          });
        });
      }
    }

    items.sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1));

    this.setState({ items, isLoading: false, searchValue: "" });
  };

  getKey = async (localKey) => {
    const { project, task, client, request, apiUser } = this.props;
    const { rootPrefix } = this.state;
    let parent = task || project || client || request;
    const organisation = parent?.organisation || apiUser.organisation;

    const isDocumentLibrary = this.isDocumentLibrary();

    let fileKey;

    if (isDocumentLibrary) {
      fileKey = `public/${organisation}/${rootPrefix}/${localKey}`;
    } else if (client) {
      fileKey = await encodeKey({
        type: KEY_TYPES.CLIENT_ATTACHMENT,
        data: {
          organisation,
          clientId: client.id,
          attachmentFileName: `${rootPrefix}${localKey}`,
        },
      });
    } else if (request) {
      fileKey = await encodeKey({
        type: KEY_TYPES.REQUEST_ATTACHMENT,
        data: {
          organisation,
          requestId: request.id,
          attachmentFileName: `${rootPrefix}${localKey}`,
        },
      });
    } else {
      fileKey = await encodeKey({
        type: KEY_TYPES.PROJECT_ATTACHMENT,
        data: {
          organisation,
          projectId: task ? task.projectId : project.id,
          attachmentFileName: `${rootPrefix}${localKey}`,
        },
      });
    }

    return fileKey.split("//").join("/");
  };

  onFileInputChange = (e) => {
    let augmentedFiles = [];

    for (let i = 0; i < e.target.files.length; i++) {
      const rawFile = e.target.files[i];
      augmentedFiles.push({
        size: rawFile.size,
        fullPath: `/${rawFile.name}`,
        name: rawFile.name,
        fileObject: rawFile,
        type: rawFile.type,
      });
    }

    this.onFilesDropped(e, augmentedFiles);
  };

  uploadAttachment = (fileKey, attachment, attachmentContainer, attachmentKey, _this) => {
    const { pendingFiles } = this.state;

    // eliminate repeated slashes from fileKey recursively
    while (fileKey.indexOf("//") !== -1) {
      fileKey = fileKey.replace("//", "/");
    }

    try {
      return Storage.put(fileKey, attachment, {
        contentType: attachment.type,
        progressCallback(progress) {
          let existingStateItem = _this.state[`progress-${attachmentContainer.fullPath}`];
          if (existingStateItem && progress && existingStateItem?.loaded > progress?.loaded) {
            return;
          }
          _this.setState({
            [`progress-${attachmentContainer.fullPath}`]: progress,
          });
        },
      })
        .then(async ({ key }) => {
          await callRest({
            message: "Failed to check attachment existence in trash",
            route: "/checkAttachmentExistenceInTrash",
            method: "POST",
            body: {
              Key: attachmentKey,
            },
            includeCredentials: false,
          });
        })
        .then(() => {
          return attachmentKey;
        });
    } catch (err) {
      this.setState({
        failedUploads: [...(this.state.failedUploads || []), attachmentContainer],
      });

      const newPendingFiles = pendingFiles.filter((file) => file.name !== attachment.name);

      this.setState({ pendingFiles: newPendingFiles });

      if (newPendingFiles.length === 0) {
        this.setState({ uploadIsFinished: true });
        this.triggerUpdateParent();
      }
    }
  };

  onFilesDropped = async (_, attachments) => {
    this.setState({ failedUploads: [] });
    const { apiUser, organisationDetails } = this.props;

    if (attachments.length > ATTACHMENTS_MAX_UPLOAD_FILE_COUNT) {
      Modal.error({
        closable: true,
        maskClosable: true,
        title: <>Too many files at once</>,
        content: (
          <>
            You can only upload a maximum of {ATTACHMENTS_MAX_UPLOAD_FILE_COUNT} files at a time, but you have tried to
            upload {attachments.length} files.
          </>
        ),
      });
      return;
    }

    let totalSize = attachments.reduce((sum, attachment) => {
      return sum + attachment.size;
    }, 0);
    let readableSize = calculateReadableSize({ size: totalSize });

    let delayBetweenEachUpload = 0;
    if (attachments.length > 500) {
      delayBetweenEachUpload = 200;
    } else if (attachments.length > 100) {
      delayBetweenEachUpload = 150;
    } else if (attachments.length > 30) {
      delayBetweenEachUpload = 100;
    }

    if (attachments.length > ATTACHMENTS_CONFIRMATION_FILE_COUNT || totalSize > ATTACHMENTS_CONFIRMATION_TOTAL_SIZE) {
      try {
        await new Promise((resolve, reject) => {
          Modal.confirm({
            closable: true,
            maskClosable: true,
            title: <>Confirm upload</>,
            content: (
              <>
                Are you sure you want to upload these {attachments.length} files, totalling {readableSize}?
              </>
            ),
            onOk: () => resolve(),
            onCancel: () => reject(),
          });
        });
      } catch (e) {
        // we cannot proceed, the user said chose to cancel
        return;
      }
    }
    attachments.forEach((attachment) => {
      attachment.fullPath = attachment.fullPath.substring(1);
    });

    const messageKey = "resizing-image";
    for (let i = 0; i < attachments.length; i++) {
      if (attachments[i].type?.includes("image") && !attachments[i].isFullSizeCopy) {
        if (attachments[i].size >= THRESHOLD_FOR_FULL_SIZE_VERSION) {
          let imageType = attachments[i].type.split("/")[1];
          if (!["jpeg", "jpg", "png"].includes(imageType.toLowerCase())) {
            continue;
          }
          try {
            const file = attachments[i].fileObject;
            let currentSize = file.size;
            let resizeFactorComparedToMax = 1;
            let resizedFile = await readAndCompressImage(file, {
              quality: 0.75,
              maxWidth: ATTACHMENTS_MAX_IMAGE_WIDTH / resizeFactorComparedToMax,
              maxHeight: ATTACHMENTS_MAX_IMAGE_HEIGHT / resizeFactorComparedToMax,
              mimeType: file.type,
            });
            while (currentSize > THRESHOLD_FOR_FULL_SIZE_VERSION) {
              message.loading({
                content: `Resizing image ${attachments[i].fullPath}`,
                key: messageKey,
                duration: 0,
              });
              resizedFile = await readAndCompressImage(file, {
                quality: 0.75,
                maxWidth: ATTACHMENTS_MAX_IMAGE_WIDTH / resizeFactorComparedToMax,
                maxHeight: ATTACHMENTS_MAX_IMAGE_HEIGHT / resizeFactorComparedToMax,
                mimeType: file.type,
              });
              currentSize = resizedFile.size;
              resizeFactorComparedToMax += 0.1;
            }
            message.destroy(messageKey);

            attachments.push({
              ...attachments[i],
              isFullSizeCopy: true,
              fullPath: `/full_size_versions/${attachments[i].fullPath}`,
            });
            attachments[i].fileObject = resizedFile;
            attachments[i].size = attachments[i].fileObject.size;
          } catch {
            // it means the conversion failed, we will just upload the original file
          }
        }
      }
    }
    let progressReset = {};
    for (let propertyName in this.state) {
      if (propertyName.indexOf("progress-") === 0) {
        progressReset[propertyName] = undefined;
      }
    }

    this.setState({
      isUploadViewVisible: true,
      uploadIsFinished: false,
      pendingFiles: attachments,
      ...progressReset,
    });

    try {
      let uploadPromises = [];

      for (let attachmentContainer of attachments) {
        const attachment = attachmentContainer.fileObject;

        let fileKey = (await this.getKey(attachmentContainer.fullPath)).replace("public/", "");
        let localKeyParts = attachmentContainer.fullPath.split("/");

        for (let i = 0; i < localKeyParts.length; i++) {
          const folderKey = localKeyParts.slice(0, i).join("/");
          if (folderKey && !this.foldersToBeCreated.includes(folderKey)) {
            this.foldersToBeCreated.push(folderKey);
          }
        }

        const _this = this;
        const attachmentKey = await this.getKey(attachmentContainer.fullPath);

        uploadPromises.push(this.uploadAttachment(fileKey, attachment, attachmentContainer, attachmentKey, _this));

        await new Promise((resolve) => setTimeout(resolve, delayBetweenEachUpload));
      }

      const uploadResult = await Promise.allSettled(uploadPromises);

      let graphqlPromises = [];

      let folders = new Set();

      // iterate through the list of attachments and record them in graphql
      for (let i = 0; i < uploadResult.length; i++) {
        if (uploadResult[i].status === "fulfilled") {
          let attachmentKey = uploadResult[i].value;
          let attachmentContainer = attachments[i];
          let attachment = attachmentContainer.fileObject;

          let nameWithExtension = attachmentKey.split("/").pop();

          if (!nameWithExtension) {
            continue;
          }

          const splitKey = attachmentKey.split("/");
          let currentPath = "";

          // Generate list of sub-folders to be created
          // Iterate over the split key, excluding the last element as it's the file name

          for (let i = 0; i < splitKey.length - 1; i++) {
            currentPath += (i > 0 ? "/" : "") + splitKey[i];
            const folderObject = {
              Key: currentPath + "/",
              LastModified: null,
              Size: null,
            };
            // Use JSON.stringify to ensure uniqueness when adding to the Set
            folders.add(JSON.stringify(folderObject));
          }

          let extension = nameWithExtension.split(".").pop();
          let extensionLowerCase = extension.toLowerCase();

          if (!nameWithExtension.includes(".")) {
            extension = "";
            extensionLowerCase = "";
          }

          let type;
          // compute type based on extension, e.g. image, pdf, etc.
          if (extensionLowerCase === "pdf") {
            type = "PDF";
          } else if (extensionLowerCase === "jpg" || extensionLowerCase === "jpeg" || extensionLowerCase === "png") {
            type = "IMAGE";
          } // check for video as well
          else if (
            extensionLowerCase === "mp4" ||
            extensionLowerCase === "mov" ||
            extensionLowerCase === "avi" ||
            extensionLowerCase === "wmv"
          ) {
            type = "VIDEO";
          } else {
            type = "OTHER";
          }

          graphqlPromises.push(
            callGraphQLSimple({
              mutation: "createAttachment",
              displayError: false,
              variables: {
                input: {
                  id: attachmentKey,
                  organisation: organisationDetails?.id,
                  createdBy: apiUser.id,
                  s3CreatedAt: new Date(),
                  s3UpdatedAt: new Date(),
                  nameWithoutExtension: nameWithExtension.split(".").slice(0, -1).join("."),
                  extension: nameWithExtension.split(".").pop(),
                  name: nameWithExtension,
                  path: attachmentKey,
                  size: attachment.size,
                  type,
                },
              },
            })
          );
        }
      }

      const uniqueFolders = Array.from(folders).map((folderStr) => JSON.parse(folderStr));

      for (let folder of uniqueFolders) {
        let key = folder.Key;
        // if the key ends in a slash, remove it
        if (key.slice(-1) === "/") {
          key = key.slice(0, -1);
        }
        let name = key.split("/").pop();
        if (!name) {
          continue;
        }
        graphqlPromises.push(
          callGraphQLSimple({
            mutation: "createAttachment",
            displayError: false,
            variables: {
              input: {
                id: key,
                organisation: organisationDetails?.id,
                createdBy: apiUser.id,
                s3CreatedAt: new Date(),
                s3UpdatedAt: new Date(),
                nameWithoutExtension: name,
                extension: "",
                name,
                path: key,
                size: 0,
                type: "FOLDER",
              },
            },
          })
        );
      }

      await Promise.allSettled(graphqlPromises);

      if (this.props.onUpload && typeof this.props.onUpload === "function") {
        this.props.onUpload({ result: uploadResult });
      }

      this.triggerUpdateParent();
      this.setState({ uploadIsFinished: true });
    } catch (e) {
      console.error("error:", e);

      notification.error({
        message: (
          <Typography.Text>
            Could not upload attachment:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  };

  triggerUpdateParent = async () => {
    const { client, request, project, task } = this.props;
    this.fetchFiles();
    if (client) {
      await callGraphQLSimple({
        message: `Could not update ${getSimpleLabel("client")}`,
        queryName: "updateClient",
        variables: {
          input: {
            id: client.id,
            itemSubscription: Math.floor(Math.random() * 1000000),
          },
        },
      });
    } else if (request) {
      await callGraphQLSimple({
        message: `Could not update ${getSimpleLabel("request")}`,
        queryName: "updateRequest",
        variables: {
          input: {
            id: request.id,
            itemSubscription: Math.floor(Math.random() * 1000000),
          },
        },
      });
    } else if (project) {
      await callGraphQLSimple({
        message: `Could not update ${getSimpleLabel("project")}`,
        queryName: "updateProject",
        variables: {
          input: {
            id: project.id,
            itemSubscription: Math.floor(Math.random() * 1000000),
          },
        },
      });
    } else if (task) {
      const upToDateTask = (
        await callGraphQLSimple({
          message: `Could not fetch ${getSimpleLabel("task")}`,
          queryName: "getTaskSimple",
          variables: {
            id: task.id,
          },
        })
      ).data.getTask;
      if (upToDateTask) {
        await callGraphQLSimple({
          message: `Could not update ${getSimpleLabel("task")}`,
          queryName: "updateTask",
          variables: {
            input: {
              id: task.id,
              itemSubscription: Math.floor(Math.random() * 1000000),
            },
          },
        });
      }

      if (task.projectId) {
        const upToDateProject = (
          await callGraphQLSimple({
            message: `Could not fetch ${getSimpleLabel("project")}`,
            queryName: "getProjectSimple",
            variables: {
              id: task.projectId,
            },
          })
        ).data.getProject;

        if (upToDateProject) {
          await callGraphQLSimple({
            message: `Could not update ${getSimpleLabel("project")}`,
            queryName: "updateProject",
            variables: {
              input: {
                id: task.projectId,
                itemSubscription: Math.floor(Math.random() * 1000000),
              },
            },
          });
        }
      }
    }
  };

  downloadSelectedItems = async () => {
    const { project, task, apiUser, client, request } = this.props;

    const { selectedItems, rootPrefix, items } = this.state;

    if (selectedItems.length === 1 && selectedItems[0].slice(-1) !== "/") {
      let attachmentDetails = items.find((x) => x.key === selectedItems[0]);
      this.downloadAttachment(attachmentDetails);
      return;
    }

    let totalSize = selectedItems.reduce((sum, attachment) => {
      const attachmentDetails = items.find((x) => x.key === attachment);
      return sum + (attachmentDetails?.size || 0);
    }, 0);
    let readableSize = calculateReadableSize({ size: totalSize });

    if (selectedItems.length > ATTACHMENTS_CONFIRMATION_FILE_COUNT || totalSize > ATTACHMENTS_CONFIRMATION_TOTAL_SIZE) {
      try {
        await new Promise((resolve, reject) => {
          Modal.confirm({
            closable: true,
            maskClosable: true,
            title: <>Confirm download</>,
            content: (
              <>
                Are you sure you want to download these {selectedItems.length} files, totalling {readableSize}?
              </>
            ),
            onOk: () => resolve(),
            onCancel: () => reject(),
          });
        });
      } catch (e) {
        // we cannot proceed, the user said chose to cancel
        return;
      }
    }

    // this.setState({ isPreparingDownload: true, isDownloadWaitModalOpen: true });
    Modal.info({
      closable: true,
      maskClosable: true,
      className: "attachment-download-notification-modal",
      title: <>We're creating a .zip file with your files</>,
      content: (
        <>This process may take a while. You will receive a notification when the archive is ready to be downloaded. </>
      ),
    });

    let prefix;
    if (task || project) {
      prefix =
        (await encodeKey({
          type: KEY_TYPES.PROJECT_FOLDER,
          data: {
            organisation: client?.organisation || this.props.organisationDetails?.id,
            projectId: project?.id || task?.project.id,
          },
        })) + `/attachments/${rootPrefix}`;
    } else if (client) {
      prefix =
        (await encodeKey({
          type: KEY_TYPES.CLIENT_FOLDER,
          data: {
            organisation: client?.organisation || this.props.organisationDetails?.id,
            clientId: client.id,
          },
        })) + `/attachments/${rootPrefix}`;
    } else if (request) {
      prefix =
        (await encodeKey({
          type: KEY_TYPES.REQUEST_FOLDER,
          data: {
            organisation: request?.organisation || this.props.organisationDetails?.id,
            requestId: request.id,
          },
        })) + `/attachments/${rootPrefix}`;
    } else {
      prefix = rootPrefix;
    }

    try {
      await callRest({
        message: "Failed to download attachments",
        route: "/download-attachments",
        method: "POST",
        body: {
          attachments: selectedItems,
          prefix,
          userId: apiUser.id,
          parentId: task?.id || project?.id,
          organisation: apiUser.organisation,
        },
        includeCredentials: false,
      });
    } catch (e) {
      notification.error({
        message: <Typography.Text>Could not download the selected attachments</Typography.Text>,
        duration: 0,
      });
      // this.setState({
      //   isPreparingDownload: false,
      //   isDownloadWaitModalOpen: false,
      // });
    }
  };

  moveToTrash = async () => {
    const { selectedItems } = this.state;

    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm attachment move to trash",
          className: "delete-attachment-modal",
          maskClosable: true,
          content: <>Are you sure you want to move the selected attachments to trash?</>,
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    try {
      await callRest({
        message: "Failed to move attachments to trash",
        route: "/attachmentTrash",
        method: "POST",
        body: {
          attachments: selectedItems,
        },
        includeCredentials: false,
      });
      this.triggerUpdateParent();
      this.setState({ selectedItems: [] });
    } catch (e) {
      notification.error({
        message: <Typography.Text>Could not move the selected items to trash</Typography.Text>,
        duration: 0,
      });
    }
  };
  restoreFromTrash = async (itemsToRestore) => {
    const { selectedItems, items } = this.state;

    itemsToRestore =
      itemsToRestore ||
      selectedItems.map((itemKey) => {
        const itemDetails = items.find((item) => item.key === itemKey);
        if (itemDetails.type === "FOLDER") {
          return {
            Key: itemDetails.key,
          };
        }
        return itemDetails.deleteMarker;
      });

    try {
      await callRest({
        message: "Failed to restore attachments from trash",
        route: "/attachmentRestore",
        method: "POST",
        body: {
          attachments: itemsToRestore,
        },
        includeCredentials: false,
      });
      this.triggerUpdateParent();
      this.setState({ selectedItems: [] });
    } catch (e) {
      notification.error({
        message: <Typography.Text>Could not restore the selected items from trash</Typography.Text>,
        duration: 0,
      });
    }
  };

  downloadAttachment = async (attachmentDetails, versionDateReadable) => {
    const name = getFilenameFromKey(attachmentDetails.key, false);
    const extension = getExtensionFromKey(attachmentDetails.key);
    let nameToUse = `${name}.${extension}`;

    let latestVersionKey = attachmentDetails.key;
    if (attachmentDetails.deleteMarker) {
      latestVersionKey = attachmentDetails.versions[1];
    }

    if (versionDateReadable) {
      nameToUse = `${name} on ${versionDateReadable}.${extension}`;
    }

    try {
      const signedUrl = await getS3File(latestVersionKey.replace("public/", ""), attachmentDetails.versionId);
      const fileData = await axios.get(signedUrl, { responseType: "blob" });

      downloadBlob({ blob: fileData.data, fileName: nameToUse });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Could not download attachment <b>{nameToUse}</b>
          </Typography.Text>
        ),
        duration: 0,
      });
      console.error("error downloading attachment:", e);
    }
  };

  toggleItem = ({ item, e }) => {
    e.stopPropagation();

    const checked = e.target.checked;
    let selectedItems = [...this.state.selectedItems];
    let itemsAtPrefix = [];

    if (checked) {
      selectedItems.push(item.key);
    } else {
      selectedItems = selectedItems.filter((x) => x !== item.key && !itemsAtPrefix.includes(x));
    }

    this.setState({ selectedItems }, this.onChange);
  };

  onChange = () => {
    if (this.props.onChange) {
      const itemsToSend = this.state.selectedItems.filter((key) => key && key.slice(-1) !== "/");
      const dates = {};
      const sizes = {};
      if (this.state.items) {
        itemsToSend.forEach((key) => {
          dates[key] = this.state.items.find((item) => item.key === key)?.lastModified;
          sizes[key] = this.state.items.find((item) => item.key === key)?.size;
        });
      }
      this.props.onChange(itemsToSend, dates, sizes);
    }
  };

  getVisibleItems = () => {
    let { items, isTrashOpen } = this.state;

    return items.filter(
      (item) =>
        (isTrashOpen &&
          (item.deleteMarker || item.type === "FOLDER") &&
          !item.name.endsWith(".dhubmsg") &&
          !item.name.endsWith(".dhubeml")) ||
        (!isTrashOpen &&
          !item.deleteMarker &&
          !item.deleted &&
          !item.name.endsWith(".dhubmsg") &&
          !item.name.endsWith(".dhubeml"))
    );
  };

  selectAllItems = () => {
    const { allowedFileTypes } = this.props;
    const itemsToSelect = this.getVisibleItems()
      .filter((item) => {
        let itemIsEnabled = true;
        if (allowedFileTypes && !allowedFileTypes.includes(item.type)) {
          itemIsEnabled = false;
        }
        return itemIsEnabled;
      })
      .map((item) => item.key);
    this.setState({ selectedItems: itemsToSelect }, this.onChange);
  };

  deselectAllItems = () => {
    this.setState({ selectedItems: [] }, this.onChange);
  };

  updateUrl = () => {
    const { rootPrefix } = this.state;
    const { history, match, isInModal } = this.props;
    if (isInModal || (!match?.params?.taskId && !match?.params?.projectId)) {
      return;
    }
    const newQueryParams = query.parse(window.location.search);
    newQueryParams.attachmentPath = rootPrefix;
    const newQueryString = query.stringify(newQueryParams);
    history.replace(`${window.location.pathname}?${newQueryString}`);
  };

  displayList = () => {
    let { rootPrefix, selectedItems, items, isLoading } = this.state;
    const { allowedFileTypes } = this.props;

    if (isLoading) {
      return (
        <div className="preloader-container">
          <LoadingOutlined className="preloader" />
        </div>
      );
    }

    if (!items) {
      return null;
    }

    let isSearchResult = !!this.state.searchValue && !!this.state.nonSearchItems;

    const attachmentElements = this.getVisibleItems().map((item, index) => {
      let itemIsEnabled = true;
      if (allowedFileTypes && !allowedFileTypes.includes(item.type)) {
        itemIsEnabled = false;
      }

      return (
        <AttachmentListItem
          item={item}
          key={item.key}
          index={index}
          checked={selectedItems.includes(item.key)}
          enabled={itemIsEnabled}
          isSearchResult={isSearchResult}
          realPrefix={this.state.realPrefix}
          onClick={() => {
            openAttachment.call(this, item);
          }}
          onCheck={(e) => this.toggleItem({ item, e })}
        />
      );
    });

    let noAttachmentsMessage = "No attachments found";

    if (!this.state.searchValue) {
      if (rootPrefix === "") {
        noAttachmentsMessage = "There are no attachments yet";
      } else {
        noAttachmentsMessage = "No items in this folder";
      }
    }

    return (
      <div className="attachments-container view-type-list">
        {attachmentElements.length > 0 && (
          <div className="list-headers attachment-list-header">
            <div className="attachment-content">
              <Typography.Text className="select">
                <Checkbox
                  onChange={(e) => {
                    this.setState({ isSelectAllChecked: e.target.checked });
                    if (e.target.checked) {
                      this.selectAllItems();
                    } else {
                      this.deselectAllItems();
                    }
                  }}
                  checked={this.state.isSelectAllChecked}
                />
              </Typography.Text>
              <Typography.Text className="name">
                Name ({items.length} items
                {selectedItems?.length > 0 ? `, ${selectedItems.length} selected` : ""})
              </Typography.Text>
              <Typography.Text className="file-size">Size</Typography.Text>
              <Typography.Text className="updated-at">Last updated</Typography.Text>
              <Typography.Text className="created-at">Created</Typography.Text>
            </div>
          </div>
        )}
        {attachmentElements.length > 0 ? (
          attachmentElements
        ) : (
          <Empty
            className="no-items-in-folder"
            description={
              <>
                <Typography.Text className="no-attachments-message">{noAttachmentsMessage}</Typography.Text>
                <br />
                {this.displayUploadButton()}
              </>
            }
          />
        )}
      </div>
    );
  };

  displayTopLevelBreadcrumbAfterHome = () => {
    const { rootPrefix, isTrashOpen } = this.state;
    const { task, project, client, request, defaultPath } = this.props;

    let homePrefix = this.getHomePrefix();

    if (rootPrefix?.includes(homePrefix) || defaultPath || this.isDocumentLibrary()) {
      return null;
    }

    if (client) {
      return (
        <Breadcrumb.Item
          onClick={() => {
            this.setState({ rootPrefix: "", isHome: false }, this.updateUrl);
          }}
        >
          Client attachments {isTrashOpen ? <b>(Trash)</b> : null}
        </Breadcrumb.Item>
      );
    } else if (request) {
      return (
        <Breadcrumb.Item
          onClick={() => {
            this.setState({ rootPrefix: "", isHome: false }, this.updateUrl);
          }}
        >
          {getSimpleLabel("Request")} attachments {isTrashOpen ? <b>(Trash)</b> : null}
        </Breadcrumb.Item>
      );
    } else if (task || project) {
      return (
        <Breadcrumb.Item
          onClick={() => {
            this.setState({ rootPrefix: "", isHome: false }, this.updateUrl);
          }}
        >
          Project attachments {isTrashOpen ? <b>(Trash)</b> : null}
        </Breadcrumb.Item>
      );
    } else {
      return null;
    }
  };

  displaySearchBar = () => {
    return (
      <div className="attachments-search-bar">
        <Input
          prefix={<SearchOutlined />}
          showBorder
          fullWidth
          placeholder="Search attachments"
          fireOnChangeWithoutBlurWithDebounce
          debounceDelay={500}
          defaultValue={this.state.searchValue}
          onChange={(value) => {
            this.setState({ searchValue: value });
          }}
          allowClear
        />
      </div>
    );
  };

  displayBreadcrumbs = () => {
    let { rootPrefix, isTrashOpen, isHome } = this.state;
    const { isDocumentLibrary, defaultPath, includeHomeButton = true, includeCopyLinkButton = false } = this.props;

    // remove multiple slashes from rootPrefix and only leave one wherever they might be
    // do it recursively to ensure all slashes are removed
    if (rootPrefix) {
      let counter = 0;
      while (rootPrefix.includes("//") && counter < 100) {
        rootPrefix = rootPrefix.split("//").join("/");

        // this is just to prevent an infinite loop
        counter++;
      }
    }

    let levels = [""];
    if (rootPrefix === "/") {
      levels = [""];
    } else if (rootPrefix) {
      levels = rootPrefix.split("/");
    }

    return (
      <Breadcrumb
        className={cx("attachments-breadcrumbs", {
          "is-trash-open": isTrashOpen,
        })}
      >
        {rootPrefix && includeCopyLinkButton ? (
          <CopyLinkButton
            url={rootPrefix}
            tooltipContent={
              <>
                Copy current path: <br />
                {rootPrefix}
              </>
            }
            successMessageContent="Path copied"
          />
        ) : null}
        {!isDocumentLibrary && includeHomeButton && (
          <Breadcrumb.Item
            onClick={() => {
              this.setState({ rootPrefix: this.getHomePrefix(), isHome: true }, this.updateUrl);
            }}
          >
            Home {isTrashOpen ? <b>(Trash)</b> : null}
          </Breadcrumb.Item>
        )}

        {this.displayTopLevelBreadcrumbAfterHome()}

        {isHome
          ? null
          : levels.map((level, i, list) => {
              if (defaultPath) {
                if (defaultPath.startsWith("projects") && level === "projects") {
                  return null;
                }
              }

              // if (i === 0 && client) {
              //   level = "Client attachments";
              // }

              return (
                <Breadcrumb.Item
                  key={i}
                  data-cy="folder-name"
                  onClick={() => {
                    const newPrefix =
                      rootPrefix
                        .split("/")
                        .slice(0, i + 1)
                        .join("/")
                        .split("//")
                        .join("/") + "/";
                    this.setState(
                      {
                        rootPrefix: newPrefix,
                      },
                      this.updateUrl
                    );
                  }}
                >
                  {level}
                </Breadcrumb.Item>
              );
            })}
      </Breadcrumb>
    );
  };

  openCreateFolderModal = () => {
    this.setState({
      isCreateFolderModalOpen: true,
    });
  };

  displayModalPreparingDownload = () => {
    return (
      <Modal
        maskClosable={false}
        title="We are getting your files..."
        visible={true}
        footer={null}
        onCancel={() => this.setState({ isDownloadWaitModalOpen: false })}
        className="preparing-download-modal"
      >
        <Typography.Paragraph>
          You will receive a zip file when the files have finished downloading.
        </Typography.Paragraph>
      </Modal>
    );
  };

  displayModalLoadingFile = () => {
    return (
      <Modal
        maskClosable={false}
        title="Loading file..."
        open={true}
        footer={null}
        className="loading-file-modal"
        onCancel={() => this.setState({ isLoadingFile: false })}
      >
        <LoadingOutlined />
      </Modal>
    );
  };

  showAttachmentVersions = async () => {
    const selectedAttachment = this.state.selectedItems[0];

    const versionsResponse = await callRest({
      message: "Failed to fetch attachment versions",
      method: "GET",
      route: `/s3-list-versions?prefix=${btoa(selectedAttachment)}`,
      includeCredentials: false,
    });

    this.setState({
      isVersionsModalVisible: true,
      versions: versionsResponse.Versions.sort((a, b) => (a.LastModified < b.LastModified ? 1 : -1)),
    });
  };

  onGoogleDriveAuthApiLoad = () => {
    window.gapi.auth.authorize(
      {
        client_id: clientId,
        scope: scope,
        immediate: false,
      },
      this.handleGoogleDriveAuthResult
    );
  };

  handleGoogleDriveAuthResult = (authResult) => {
    if (authResult && !authResult.error) {
      oauthToken = authResult.access_token;
      this.createGoogleDrivePicker();
    }
  };

  createGoogleDrivePicker = () => {
    if (pickerApiLoaded && oauthToken) {
      var picker = new window.google.picker.PickerBuilder()
        .addView(
          new window.google.picker.DocsView(window.google.picker.ViewId.DOCS)
            .setIncludeFolders(true)
            .setSelectFolderEnabled(true)
        )
        .addView(
          new window.google.picker.DocsView(window.google.picker.ViewId.DOCS)
            .setIncludeFolders(true)
            .setOwnedByMe(false)
            .setSelectFolderEnabled(true)
        )
        .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
        .enableFeature(window.google.picker.Feature.SUPPORT_DRIVES)
        .setAppId(appId)
        .setOAuthToken(oauthToken)
        .setDeveloperKey(developerKey)
        .setCallback(this.googleDrivePickerCallback)
        .build();
      picker.setVisible(true);
    }
  };

  googleDrivePickerCallback = async (data) => {
    if (data.action === window.google.picker.Action.PICKED) {
      for (let i = 0; i < data.docs.length; i++) {
        const file = data.docs[i];
        const unsupportedMimeTypes = [
          "application/vnd.google-apps.document",
          "application/vnd.google-apps.spreadsheet",
          "application/vnd.google-apps.presentation",
          "application/vnd.google-apps.form",
          "application/vnd.google-apps.drawing",
          "application/vnd.google-apps.map",
          "application/vnd.google-apps.script",
          "application/vnd.google-apps.site",
          "application/vnd.google-apps.jam",
        ];

        if (unsupportedMimeTypes.includes(file.mimeType)) {
          return Modal.error({
            title: "Unsupported file type",
            content: `The file ${file.name} is not supported. Please export it to a different format from Google Drive and then upload it here.`,
          });
        }

        message.loading({
          content: "Fetching files from Google Drive...",
          key: "google-drive-loading-message",
          duration: 0,
        });

        if (file.mimeType === "application/vnd.google-apps.folder") {
          await this.downloadFilesInFolder(file.id, oauthToken, file.name + "/");
        } else {
          await this.uploadFileFromGoogleDrive(file);
        }

        this.fetchFiles();
      }

      message.success({
        content: "Files successfully uploaded from Google Drive",
        key: "google-drive-loading-message",
      });
    }
  };

  downloadFilesInFolder = async (folderId, accessToken, folderPath = "") => {
    const result = await axios.get(`https://www.googleapis.com/drive/v3/files`, {
      params: {
        q: `'${folderId}' in parents`,
        fields: "files(id, name, mimeType)",
        supportsAllDrives: true,
        includeItemsFromAllDrives: true,
      },
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    });

    for (const file of result.data.files) {
      if (file.mimeType === "application/vnd.google-apps.folder") {
        await this.downloadFilesInFolder(file.id, accessToken, folderPath + file.name + "/");
      } else {
        await this.uploadFileFromGoogleDrive(file);
      }
    }
  };

  uploadFileFromGoogleDrive = async (file) => {
    const { task, project } = this.props;
    const { rootPrefix } = this.state;
    const _this = this;

    const config = {
      method: "GET",
      url: `https://www.googleapis.com/drive/v3/files/${file.id}`,
      responseType: "arraybuffer",
      params: {
        alt: "media",
      },
      headers: {
        Authorization: "Bearer " + oauthToken,
      },
    };

    const downloadedFile = await axios(config).then((response) => {
      return Buffer.from(response.data, "binary");
    });

    let fileKey = await encodeKey({
      type: KEY_TYPES.PROJECT_ATTACHMENT,
      data: {
        organisation: task ? task.organisation : project.organisation,
        projectId: task ? task.projectId : project.id,
        attachmentFileName: `${rootPrefix}${file.name}`,
      },
    });

    fileKey = fileKey.replace("public/", "");

    await Storage.put(fileKey, downloadedFile, {
      contentType: file.type,
      progressCallback(progress) {
        let existingStateItem = _this.state[`progress-${fileKey}`];
        if (existingStateItem && progress && existingStateItem?.loaded > progress?.loaded) {
          return;
        }
        _this.setState({ [`progress-${fileKey}`]: progress });
      },
    })
      .then(async () => {
        await callRest({
          message: "Failed to check attachment existence in trash",
          route: "/checkAttachmentExistenceInTrash",
          method: "POST",
          body: {
            Key: fileKey,
          },
          includeCredentials: false,
        });
      })
      .then(() => fileKey);
  };

  onPickerApiLoad = () => {
    pickerApiLoaded = true;
  };

  openGoogleDrivePicker = () => {
    window.gapi.load("auth", { callback: this.onGoogleDriveAuthApiLoad });
    window.gapi.load("picker", { callback: this.onPickerApiLoad });
  };

  displayUploadFromGoogleDriveButton = () => {
    const { organisationDetails } = this.props;

    if (
      this.props.readOnly ||
      this.state.isTrashOpen ||
      this.props.presentationOnly ||
      !organisationDetails?.settings?.general?.usesUploadFromGoogleDrive
    ) {
      return null;
    }

    return (
      <>
        <Button
          className="upload-from-google-drive-button"
          type="primary"
          onClick={() => this.openGoogleDrivePicker()}
          disabled={this.state.isTrashOpen}
        >
          Upload from Google Drive
        </Button>
      </>
    );
  };

  displayUploadButton = () => {
    if (this.props.readOnly || this.state.isTrashOpen || this.props.presentationOnly) {
      return null;
    }
    return (
      <label htmlFor="single-upload" className="upload-button-container">
        <Button
          type="dark"
          icon={<CloudUploadOutlined />}
          disabled={this.state.isTrashOpen}
          onClick={() => {
            this.fileInputRef.current.click();
          }}
        >
          <span>Upload</span>
        </Button>

        <input
          ref={this.fileInputRef}
          disabled={this.state.isTrashOpen}
          id="single-upload"
          style={{ display: "none" }}
          type="file"
          onChange={this.onFileInputChange}
          multiple="multiple"
          data-cy="upload-input"
        />
      </label>
    );
  };

  displayDeleteButton = () => {
    const { task, project } = this.props;
    const { selectedItems } = this.state;

    const parent = task || project;

    if (this.props.readOnly || this.state.isTrashOpen || this.props.presentationOnly) {
      return null;
    }

    if (this.state.isTrashOpen) {
      return (
        <Button
          disabled={selectedItems.length === 0}
          onClick={() => this.restoreFromTrash()}
          className="restore-from-trash"
        >
          <UndoOutlined />
          Restore
        </Button>
      );
    }

    return (
      <Tooltip title="Move to trash">
        <Button
          disabled={parent?.isFinished || parent?.isArchived || selectedItems.length === 0}
          onClick={this.moveToTrash}
          className="move-to-trash"
        >
          <DeleteOutlined />
        </Button>
      </Tooltip>
    );
  };

  displayTrashButton = () => {
    if (this.props.readOnly || this.props.presentationOnly) {
      return null;
    }

    if (this.state.isTrashOpen) {
      return (
        <Button
          onClick={() =>
            this.setState(
              {
                isTrashOpen: false,
                rootPrefix: this.props.task ? `${this.props.task.id}/` : "",
              },
              this.updateUrl
            )
          }
          type="primary"
        >
          Close trash
        </Button>
      );
    }

    return (
      <Button
        onClick={() =>
          this.setState(
            {
              isTrashOpen: true,
              rootPrefix: this.props.task ? `${this.props.task.id}/` : "",
            },
            this.updateUrl
          )
        }
      >
        Open trash
      </Button>
    );
  };

  render() {
    const {
      isCreateFolderModalOpen,
      isDownloadWaitModalOpen,
      isLoadingFile,
      isPreparingDownload,
      rootPrefix,
      selectedItems,
      isUploadViewVisible,
      pendingFiles,
      uploadIsFinished,
      isVersionsModalVisible,
      versions,
      documentViewModalAttachment,
    } = this.state;
    const {
      task,
      isInModal,
      project,
      request,
      windowWidth,
      windowHeight,
      presentationOnly,
      allowDownload = true,
    } = this.props;

    return (
      <>
        <div className={cx("attachments", { "is-in-modal": isInModal, "presentation-only": presentationOnly })}>
          {this.state.pdfPreviewData && (
            <DocumentDetailsModal
              attachment={documentViewModalAttachment}
              document={this.state.pdfPreviewData}
              onClose={() => this.setState({ pdfPreviewData: null })}
              allowDownload={allowDownload}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
            />
          )}
          {this.state.emailPreviewData && (
            <EmailDetailsModal
              attachment={documentViewModalAttachment}
              emailData={this.state.emailPreviewData}
              onClose={() => this.setState({ emailPreviewData: null })}
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              openAttachment={(attachment) => openAttachment.call(this, attachment)}
            />
          )}
          <DragAndDrop onChange={this.onFilesDropped} disabled={this.props.readOnly || this.props.presentationOnly}>
            <Card
              className={cx("attachments-card")}
              title={
                <>
                  <div className="actions-container">
                    {!isUploadViewVisible && (
                      <>
                        {/* <div className="attachment-view-selector">
                      <Tooltip title="Thumbnail view">
                        <TableOutlined
                          className={cx({ active: viewType === "GRID" })}
                          onClick={() => this.setState({ viewType: "GRID" })}
                        />
                      </Tooltip>
                      <Tooltip title="List view">
                        <MenuOutlined
                          className={cx({ active: viewType === "LIST" })}
                          onClick={() => this.setState({ viewType: "LIST" })}
                        />
                      </Tooltip>
                    </div> */}

                        <>
                          {this.displayTrashButton()}
                          <Button
                            disabled={!selectedItems || selectedItems.length !== 1 || selectedItems[0].endsWith("/")}
                            onClick={this.showAttachmentVersions}
                            className="show-versions"
                          >
                            <VersionsIcon />
                            Versions
                          </Button>

                          {this.displayDeleteButton()}

                          {allowDownload && (
                            <Tooltip title="Download">
                              <Button
                                disabled={!selectedItems || selectedItems.length === 0 || isPreparingDownload}
                                onClick={this.downloadSelectedItems}
                                className="download"
                                data-cy="download-button"
                              >
                                {isPreparingDownload ? <LoadingOutlined /> : <DownloadOutlined />}
                              </Button>
                            </Tooltip>
                          )}

                          {!this.props.readOnly && !this.state.isTrashOpen && !this.props.presentationOnly && (
                            <Tooltip title="Create folder">
                              <Button
                                icon={<FolderAddOutlined />}
                                onClick={this.openCreateFolderModal}
                                className="create-folder"
                              />
                            </Tooltip>
                          )}

                          {this.displayUploadButton()}
                          {this.displayUploadFromGoogleDriveButton()}
                        </>
                      </>
                    )}
                  </div>
                  {this.displaySearchBar()}
                  {this.displayBreadcrumbs()}
                </>
              }
            >
              {isUploadViewVisible ? (
                <UploadProgressView
                  pendingFiles={pendingFiles}
                  parentState={this.state}
                  uploadIsFinished={uploadIsFinished}
                  failedUploads={this.state.failedUploads}
                  onDone={() => {
                    this.setState({ isUploadViewVisible: false });
                    this.setState({ failedUploads: [] });
                  }}
                />
              ) : (
                // <div className="content">{viewType === "GRID" ? this.displayGrid() : this.displayList()}</div>
                this.displayList()
              )}
            </Card>
          </DragAndDrop>
        </div>
        {isVersionsModalVisible && (
          <VersionsModal
            versions={versions}
            onVersionClick={(version, versionDateReadable) => this.downloadAttachment(version, versionDateReadable)}
            onClose={() => this.setState({ isVersionsModalVisible: false })}
          />
        )}
        {this.state.isFilePreviewVisible && (
          <FilePreview
            attachments={this.getVisibleItems()}
            initiallySelectedAttachment={this.state.documentViewModalAttachment}
            onClose={() => this.setState({ isFilePreviewVisible: false, documentViewModalAttachment: undefined })}
            organisationId={this.props.organisationDetails?.id}
          />
        )}
        {isCreateFolderModalOpen ? (
          <CreateFolderModal
            task={task}
            project={project}
            request={request}
            organisationDetails={this.props.organisationDetails}
            prefix={rootPrefix}
            isDocumentLibrary={this.isDocumentLibrary()}
            onClose={() => this.setState({ isCreateFolderModalOpen: false })}
          />
        ) : null}
        {isDownloadWaitModalOpen ? this.displayModalPreparingDownload() : null}
        {isLoadingFile ? this.displayModalLoadingFile() : null}
      </>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: Attachments,
    subscriptions: ["organisationDetails"],
  })
);
