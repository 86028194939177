import { notification } from "antd";

import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";
import { getUppercaseStatus } from "common/helpers";
import { changeTaskStatus } from "common/changeTaskStatus";
import { HAS_SHEETS, FILE_TYPES_DETAILS } from "common/shared";
import { downloadPDF, downloadSheetPDF } from "common/helpers";

export async function recalculateTaskEstimatedHours(taskId) {
  const task = (
    await callGraphQLSimple({
      message: `Failed to load ${getSimpleLabel("task")}`,
      queryName: "getTaskSimple",
      variables: {
        id: taskId,
      },
    })
  ).data.getTask;

  const taskEstimatedHours = task.revisions.items.reduce((total, revision) => {
    return total + revision.estimatedHours;
  }, 0);

  await callGraphQLSimple({
    message: `Failed to update ${getSimpleLabel("task")}`,
    queryName: "updateTask",
    variables: {
      input: {
        id: taskId,
        estimatedHours: taskEstimatedHours,
      },
    },
  });
}

export async function updateDeletedFiles(taskRevision, fileType) {
  const deletedFilesByType = JSON.parse(taskRevision.deletedFilesByType || "{}");
  if (!deletedFilesByType.hasOwnProperty(fileType)) {
    deletedFilesByType[fileType] = 0;
  }
  deletedFilesByType[fileType]++;

  await callGraphQLSimple({
    message: `Failed to update ${getSimpleLabel("task revision")}`,
    queryName: "updateTaskRevision",
    variables: {
      input: {
        id: taskRevision.id,
        deletedFilesByType: JSON.stringify(deletedFilesByType),
      },
    },
  });
}

export async function checkTasksBasedOnStatus({ tasks, organisationDetails }) {
  const atLeastOneStatusMeansDone = (organisationDetails.taskStatuses || []).find((x) => x.type === "DONE");

  if (!atLeastOneStatusMeansDone) {
    return;
  }

  for (let task of tasks) {
    if (task.isHidden) {
      continue;
    }
    let statusDetails = (organisationDetails.taskStatuses || []).find(
      (statusDetails) => getUppercaseStatus(statusDetails.name) === getUppercaseStatus(task.status)
    );

    let statusMeansDone = false;
    if (statusDetails && statusDetails.type === "DONE") {
      statusMeansDone = true;
    }

    if (!!task.isFinished !== statusMeansDone) {
      console.log("Task status does not match isFinished", task.id, task.status, task.isFinished, statusMeansDone);
      await changeTaskStatus({
        taskId: task.id,
        status: task.status,
        organisationDetails,
        authorIsUnknown: true,
        recordActivity: false,
      });
    }
  }
}

export async function downloadLatestPDF({ file, task, users, organisationDetails }) {
  file = (
    await callGraphQLSimple({
      message: "Could not retrieve file details",
      queryName: "getFile",
      variables: {
        id: file.id,
      },
    })
  ).data.getFile;
  const latestFileVersion = file.versions.items.slice(-1)[0];

  let hasPDF = true;
  if (!latestFileVersion) {
    hasPDF = false;
  } else if (file.type !== "REPORT" && !latestFileVersion.savedAt && !latestFileVersion.publishEndAt) {
    hasPDF = false;
  }

  if (!hasPDF) {
    notification.info({
      message: <span>{FILE_TYPES_DETAILS[file.type]?.label} file does not yet have a PDF export</span>,
    });
    return;
  }

  if (!HAS_SHEETS[file.type]) {
    downloadSheetPDF({
      sheetRevision: file.sheets.items[0].revisions.items.slice(-1)[0],
      file,
      task,
    });
  } else {
    downloadPDF({
      fileKey: file.versions.items[file.versions.items.length - 1].exports[0].key,
      file,
      task,
    });
  }
}
