import moment from "moment";

export const PUBLIC_USER_EMAIL = "draughthub.public@gmail.com";
export const PUBLIC_USER_PASSWORD = "publicpassword";
export const PUBLIC_USER_ORGANISATION = "PUBLIC";
export const PUBLIC_S3_BUCKET_NAME = "draughthub-public-assets";

export const MAX_SHEETS = 30;
export const DRAUGHTHUB_LINK_URL =
  "https://draughthub-link-prod.s3-eu-west-1.amazonaws.com/public/binaries/latest/DraughtHub_Link_Installer.exe";
export const DEFAULT_PAGE_LENGTH = 1000;
export const IS_NEW_THRESHOLD = 3600 * 24 * 1000; // milliseconds
export const CONSIDER_PUBLISH_FAILED_THRESHOLD = 1000 * 60;
export const REFRESH_S3_INTERVAL_DELAY = 1000 * 60 * 5; // 5 minutes

export const CREATE_RETRY_LIMIT = 20;
export const CUSTOM_TASK_FIELDS_WITHOUT_FILTERS = ["SINGLE_FILE", "GEOGRAPHICAL_LOCATION"];
export const ATTACHMENTS_MAX_UPLOAD_FILE_COUNT = 1000;
export const ATTACHMENTS_CONFIRMATION_FILE_COUNT = 20;
export const ATTACHMENTS_CONFIRMATION_TOTAL_SIZE = 100 * 1024 * 1024;
export const MIN_DOCUMENT_FORM_WIDTH = 400;
export const INVOICE_DEFAULT_EXPECT_PAYMENT_AFTER_DAYS = 30;

export const REVIEW_MOBILE_BREAKPOINT = 900;

export const FILE_TYPES_ORDER = {
  AUTOCAD: 0,
  BRICSCAD: 1,
  MATHCAD: 2,
  SMATH: 3,
  EXCEL: 4,
  WORD: 5,
  POWERPOINT: 6,
  REPORT: 7,
  BLUEBEAM: 8,
  REVIT: 9,
};

export const PROGRAMS = ["AUTOCAD", "BRICSCAD", "MATHCAD", "EXCEL", "SMATH", "WORD", "BLUEBEAM", "REVIT"];

export const FILE_TYPES_READABLE = {
  AUTOCAD: "AutoCAD",
  BRICSCAD: "BricsCAD",
  MATHCAD: "MathCAD",
  SMATH: "SMath",
  EXCEL: "Excel",
  WORD: "Word",
  BLUEBEAM: "PDF",
  REVIT: "Revit",
  POWERPOINT: "PowerPoint",
  REPORT: "Report",
  APP_PAGE: "Dashboard",
};

export const DOCUMENT_EDITOR_DATA_SOURCES = {
  client: "Client",
  clientContact: "Client contact",
  clientAddress: "Client address",
  supplier: "Supplier",
  supplierContact: "Supplier contact",
  supplierAddress: "Supplier address",
  project: "Project",
  task: "Task",
  taskRevision: "Task revision",
  file: "File",
  sheet: "Sheet",
  day: "Day",
  general: "General",
  quote: "Quote",
  quoteLineItem: "Quote line item",
  invoice: "Invoice",
  invoiceLineItem: "Invoice line item",
  purchaseOrder: "Purchase order",
  purchaseOrderLineItem: "Purchase order line item",
  attachment: "Attachment",
  form: "Form",
  formElement: "Form element",
  formElementChild: "Form element child",
  organisation: "Organisation",
  user: "User",
  apiUser: "Logged in user",
  quoteFees: "Quote fees",
  timesheetBlock: "Timesheet block",
  timelineBlock: "Timeline block",
  request: "Request",
  RDX: "RADIX",
  BYRNE: "Byrne Bros",
  PSD: "PSD",
  EIS: "EIS",
};

const TEMPLATE_EDITOR_SCALE_FACTOR = 2.8333333333;
export const TEMPLATE_EDITOR_SPACE_BETWEEN_PAGES = 16 * 3;

export const DOCUMENT_PAGE_SIZES_MILLIMITERS = {
  A4: {
    label: "A4 Portrait",
    height: 297,
    width: 210,
  },
  A4_LANDSCAPE: {
    label: "A4 Landscape",
    height: 210,
    width: 297,
  },
  A3: {
    label: "A3 Portrait",
    height: 420,
    width: 297,
  },
  A3_LANDSCAPE: {
    label: "A3 Landscape",
    height: 297,
    width: 420,
  },
  A2: {
    label: "A2 Portrait",
    height: 594,
    width: 420,
  },
  A2_LANDSCAPE: {
    label: "A2 Landscape",
    height: 420,
    width: 594,
  },
  A1: {
    label: "A1 Portrait",
    height: 841,
    width: 594,
  },
  A1_LANDSCAPE: {
    label: "A1 Landscape",
    height: 594,
    width: 841,
  },
  "1080p": {
    label: "1080p",
    height: 1080 / 4,
    width: 1920 / 4,
  },
};

export let DOCUMENT_PAGE_SIZES = {};
Object.keys(DOCUMENT_PAGE_SIZES_MILLIMITERS).forEach((pageSize) => {
  const mmSizeDetails = DOCUMENT_PAGE_SIZES_MILLIMITERS[pageSize];
  DOCUMENT_PAGE_SIZES[pageSize] = {
    ...mmSizeDetails,
    width: mmSizeDetails.width * TEMPLATE_EDITOR_SCALE_FACTOR,
    height: mmSizeDetails.height * TEMPLATE_EDITOR_SCALE_FACTOR,
  };
});

export const SUPPORTS_EXTERNAL_REFERENCES = {
  AUTOCAD: true,
  BRICSCAD: true,
  MATHCAD: false,
  SMATH: false,
  EXCEL: false,
  POWERPOINT: false,
  REPORT: false,
};

export const DEFAULT_FILE_NAMES = {
  AUTOCAD: "Drawing",
  BRICSCAD: "Drawing",
  MATHCAD: "Calculations",
  SMATH: "Calculations",
  EXCEL: "Calculations",
  POWERPOINT: "Sketch",
  REPORT: "Report",
};

export const THRESHOLD_FOR_FULL_SIZE_VERSION = 407200;

export const ATTACHMENTS_MAX_IMAGE_WIDTH = 1000;
export const ATTACHMENTS_MAX_IMAGE_HEIGHT = 1000;

export const TASK_RELATIONSHIPS = [
  {
    value: "NEEDS_CAT_2_CHECK",
    label: "Has a Cat 2 check",
    corresponding: "CAT_2_CHECK_FOR",
    color: "#0a5a8f",
  },
  {
    value: "NEEDS_CAT_3_CHECK",
    label: "Cat 3 check",
    corresponding: "CAT_3_CHECK_FOR",
    color: "#0a5a8f",
  },
  {
    value: "RELATES",
    label: "Relates to",
    corresponding: "RELATES",
    color: "#1abc9c",
  },
  {
    value: "DEPENDS_ON",
    label: "Depends on",
    corresponding: "DEPENDED_ON_BY",
    color: "#9b59b6",
  },
  {
    value: "DEPENDED_ON_BY",
    label: "Is Depended on by",
    corresponding: "DEPENDS_ON",
    color: "#9b59b6",
  },
  {
    value: "BLOCKS",
    label: "Blocks",
    corresponding: "BLOCKED_BY",
    color: "#e74c3c",
  },
  {
    value: "BLOCKED_BY",
    label: "Is blocked by",
    corresponding: "BLOCKS",
    color: "#e74c3c",
  },
  {
    value: "SPLIT_FROM",
    label: "Split from",
    corresponding: "SPLIT_TO",
    color: "#34495e",
  },
  {
    value: "SPLIT_TO",
    label: "Split to",
    corresponding: "SPLIT_FROM",
    color: "#34495e",
  },
  {
    value: "DUPLICATED_BY",
    label: "Is duplicated by",
    corresponding: "DUPLICATES",
    color: "#f39c12",
  },
  {
    value: "DUPLICATES",
    label: "Duplicates",
    corresponding: "DUPLICATED_BY",
    color: "#f39c12",
  },
  {
    value: "CLONED_BY",
    label: "Is cloned by",
    corresponding: "CLONES",
    color: "#95a5a6",
  },
  {
    value: "CLONES",
    label: "Clones",
    corresponding: "CLONED_BY",
    color: "#95a5a6",
  },
  {
    value: "CAT_2_CHECK_FOR",
    label: "Cat 2 check for",
    corresponding: "NEEDS_CAT_2_CHECK",
    hidden: true,
    color: "#f39c12",
  },
  {
    value: "CAT_3_CHECK_FOR",
    label: "Cat 3 check for",
    corresponding: "NEEDS_CAT_3_CHECK",
    hidden: true,
    color: "#0a5a8f",
  },
];

export const PDF_INSERT_MARKER = "_x|x_";
export const ATTACHMENT_COUNT_LIMIT = 500;

export const FILE_TYPE_EXTENSIONS = {
  AUTOCAD: ["dwg"],
  BRICSCAD: ["dwg"],
  MATHCAD: ["xmcd"],
  SMATH: ["sm"],
  EXCEL: ["xlsm", "xls", "xlsx"],
  WORD: ["docx", "doc", "docm"],
  BLUEBEAM: ["pdf"],
  REVIT: ["rvt"],
  POWERPOINT: ["pptm", "pptx", "ppt"],
  REPORT: ["json"],
  APP_PAGE: ["json"],
};

export const FILES_TO_PUBLISH_ON_CREATE = [
  "AUTOCAD",
  "BRICSCAD",
  "MATHCAD",
  "SMATH",
  "EXCEL",
  "POWERPOINT",
  "WORD",
  "BLUEBEAM",
  "REVIT",
];
export const FILES_TO_OPEN_ON_CREATE = [
  "AUTOCAD",
  "BRICSCAD",
  "MATHCAD",
  "SMATH",
  "EXCEL",
  "WORD",
  "POWERPOINT",
  "BLUEBEAM",
  "REVIT",
];

export const ATTACHMENT_INSERT_PATTERN = /\[(.+)\]+/g;
export const MAX_ATTACHMENT_SIZE_BYTES = 1048576;
export const S3_URL_CACHE_MAX_AGE = 1000 * 60 * 3; // 3 minutes

export const COLORS = ["#19aae8", "#ff4d4f", "#44bd32", "#ffbc0d"];
export const VERSION_VISIBLE_COUNT = 10;
export const INVALID_CHARACTERS = ["/"];
export const MAX_INITIALS_LENGTH = 5;

export const ALLOWANCE_TYPES = [
  {
    label: "Holidays",
    type: "HOLIDAY",
    intervalType: "HOLIDAY_ALLOWANCE",
    isSick: false,
  },
  {
    label: "Sick days",
    type: "SICK",
    intervalType: "SICK_ALLOWANCE",
    isSick: true,
  },
];

export const CUSTOM_FIELD_TYPES = [
  {
    label: "Single-line text box",
    value: "TEXT",
  },
  {
    label: "Multi-line text box",
    value: "TEXTAREA",
  },
  {
    label: "Number",
    value: "NUMBER",
  },
  // {
  //   label: "Switch",
  //   value: "SWITCH",
  // },
  {
    label: "Checkbox",
    value: "CHECKBOX",
  },
  {
    label: "Checkbox list",
    value: "CHECKBOX_LIST",
  },
  {
    label: "Radio button list",
    value: "RADIO_LIST",
  },
  {
    label: "Single file",
    value: "SINGLE_FILE",
  },
  // {
  //   label: "Multiple files",
  //   value: "MULTIPLE_FILES",
  // },
  {
    label: "Geographical location",
    value: "GEOGRAPHICAL_LOCATION",
  },
  {
    label: "User",
    value: "USER",
  },
  {
    label: "Date",
    value: "DATE",
  },
];

export const GENERIC_AVATAR_URL = "https://draughthub-public-assets.s3.eu-west-2.amazonaws.com/avatar-person.png";
export const READ_NOTIFICATION_DELETE_AFTER_DAYS = 2;
export const UNREAD_NOTIFICATION_DELETE_AFTER_DAYS = 21;

export const TASK_STATUS_TYPES_READABLE = {
  TO_DO: "To Do",
  IN_PROGRESS: "In Progress",
  DONE: "Done",
};

export const QUOTE_STATUSES = [
  { label: "Draft", value: "DRAFT" },
  { label: "Sent", value: "SENT" },
  { label: "Accepted", value: "ACCEPTED" },
  { label: "Rejected", value: "REJECTED" },
];

export const REQUEST_STATUSES = [
  { label: "Information submitted", value: "INFORMATION_SUBMITTED", meansInProgress: true },
  { label: "More information requested", value: "MORE_INFORMATION_REQUESTED", meansInProgress: true },
  { label: "Awaiting approval", value: "AWAITING_APPROVAL", meansInProgress: false },
  { label: "Approved", value: "APPROVED", meansInProgress: true },
  { label: "Work in progress", value: "WORK_IN_PROGRESS", meansInProgress: true },
  { label: "Rejected", value: "REJECTED", meansInProgress: false, needsReason: true },
  { label: "Cancelled", value: "CANCELLED", meansInProgress: false, needsReason: true },
  { label: "On hold", value: "ON_HOLD", meansInProgress: false, needsReason: true },
  { label: "Finished", value: "FINISHED", meansInProgress: false },
];

export const INVOICE_STATUSES = [
  { label: "Draft", value: "DRAFT" },
  { label: "Sent", value: "SENT" },
  { label: "Cancelled", value: "CANCELLED" },
];

export const PURCHASE_ORDER_STATUSES = [
  { label: "Draft", value: "DRAFT" },
  { label: "Sent", value: "SENT" },
  { label: "Paid", value: "PAID" },
];

export const ASYNC_JOB_STATUSES = [
  { label: "Pending", value: "PENDING", color: "#002f44" },
  { label: "In Progress", value: "IN_PROGRESS", color: "#19aae8" },
  { label: "Finished", value: "FINISHED", color: "#4cc23a" },
  { label: "Failed", value: "FAILED", color: "#ff4d4f" },
  { label: "Cancelled", value: "CANCELLED", color: "#ffbc0d" },
];

export const ASYNC_JOB_TYPES = [
  { label: "Publish", value: "PUBLISH" },
  { label: "Manipulate file", value: "MANIPULATE_DWG" },
  { label: "Query", value: "QUERY" },
];

export const REVIEW_SECONDARY_STATUS_READABLE = {
  COMMENTS_ADDRESSED: "Comments addressed",
  INFO_REQUIRED: "Author requested information",
};

export const REVIEW_STATUS_READABLE = {
  CLOSED: {
    label: "Review cancelled",
    color: "",
  },
  IN_PROGRESS: {
    label: "Review in progress",
    color: "blue",
  },
  WITH_COMMENTS: {
    label: "Reviewer left comments",
    color: "cyan",
  },
  CHANGES_REQUESTED: {
    label: "Reviewer requested changes",
    color: "volcano",
  },
  SUCCESS: {
    label: "Review approved",
    color: "green",
  },
};

export const PDF_SIZES = {
  AWD: {
    AUTOCAD: {
      a3: 0.5,
      AWD_AUTOCAD_a3: 0.5,
    },
  },
  DEFAULT: 1,
};

export const CURRENCIES = [
  { label: "", value: "GBP" },
  { label: "", value: "USD" },
  { label: "", value: "AUD" },
  { label: "", value: "CAD" },
  { label: "", value: "EUR" },
];

export const DEFAULT_TAX_RATE = 20;

export const BULLET_POINT_CHARACTER = "•";
export const BULLET_POINT_CHARACTER_OLD = "●";

export const TIMELINE_PSEUDO_TASKS = [
  "HOLIDAY",
  "SICK",
  "VIDEO CALL",
  "UNIVERSITY",
  "SITE VISIT",
  "R&D",
  "FLOAT",
  "PART-TIME DAY",
  "RENAME ME",
];
export const TIMELINE_DEFAULT_ZOOM_LEVEL = 4;
export const TIMELINE_DEFAULT_TASK_LENGTH_HOURS = 4;
export const TIMELINE_DEFAULT_SNAP_COEFFICIENT_DAYS = 0.5;
export const TIMELINE_DEFAULT_HOURS_IN_A_DAY = 8;
export const TIMELINE_DEFAULT_DAY_START_HOURS = 9;
export const TIMELINE_DEFAULT_LENGTH_DAYS = 14;
export const TIMELINE_DEFAULT_START_DATE = moment().startOf("week").add(1, "days").format("YYYY-MM-DD");
export const TIMELINE_DEFAULT_END_DATE = moment()
  .startOf("week")
  .add(TIMELINE_DEFAULT_LENGTH_DAYS + 1, "days")
  .format("YYYY-MM-DD");

export const TIMESHEET_HOUR_HEIGHT = 64;

export const REVIEW_PAGE_SCALE = 1;
export const SHEET_NAMES_INVALID_CHARACTERS_PATTERN = /[^0-9 \-_a-z]/gi;

export const QUOTE_REJECTION_REASONS = [
  {
    value: "COST",
    label: "Cost",
  },
  {
    value: "TIMESCALES",
    label: "Timescales",
  },
  {
    value: "CLIENT_DID_NOT_WIN_JOB",
    label: "Client did not win job",
  },
  {
    value: "CLIENT_PREFERENCE",
    label: "Client preference",
  },
  {
    value: "NO_RESPONSE",
    label: "No response from client",
  },
  {
    value: "OTHER",
    label: "Other",
  },
];

export const SHEET_NAMES_TO_IGNORE = ["A3", "helpers"]; // TODO: move this to the database, per template

export const DOCUMENT_FORM_FIELD_TYPES = [
  {
    label: "Section heading",
    value: "section-heading",
  },
  {
    label: "Separator",
    value: "separator",
  },
  {
    label: "Text field (single line)",
    value: "textfield",
    canBeMandatory: true,
  },
  {
    label: "Text area (multi-line)",
    value: "textarea",
    canBeMandatory: true,
  },
  {
    label: "Numerical input",
    value: "number",
    canBeMandatory: true,
  },
  {
    label: "Checkbox",
    value: "checkbox",
    canBeMandatory: true,
  },
  {
    label: "Date picker",
    value: "date-picker",
    canBeMandatory: true,
    hasAllowClear: true,
  },
  {
    label: "Date range picker",
    value: "date-range-picker",
    canBeMandatory: true,
    hasAllowClear: true,
  },
  {
    label: "Checkbox list (multiple choice)",
    value: "checkbox-list",
    canBeMandatory: true,
  },
  {
    label: "Radio button list (single choice)",
    value: "radio-list",
    canBeMandatory: true,
  },
  {
    label: "Dropdown (single choice)",
    value: "dropdown",
    canBeMandatory: true,
    hasAllowClear: true,
  },
  {
    label: "Attachment picker",
    value: "attachmentPicker",
    canBeMandatory: true,
  },
  {
    label: "Attachment uploader",
    value: "attachmentUploader",
    canBeMandatory: true,
  },
  {
    label: "Modal window containing other fields",
    value: "modalWithFields",
  },
  {
    label: "Section",
    value: "section",
  },
  {
    label: "Section list",
    value: "dynamicSectionList",
  },
  {
    label: "Nested field list - input via modal",
    value: "nestedFieldListWithModal",
  },
  {
    label: "Nested field list - input without modal",
    value: "nestedFieldListNoModal",
  },
  {
    label: "Spreadsheet",
    value: "spreadsheet",
  },
  {
    label: "Button",
    value: "button",
  },
  {
    label: "Project picker",
    value: "projectPicker",
    canBeMandatory: true,
  },
  {
    label: "Task picker",
    value: "taskPicker",
    canBeMandatory: true,
  },
];

export const TIMELINE_DEFAULT_DATE_RANGES_OPTIONS = {
  "Last 4 weeks": [moment().startOf("week").subtract(4, "weeks"), moment()],
  "This week": [moment().startOf("week"), moment().startOf("week").endOf("week")],
  "Following week": [moment().startOf("week").add(1, "weeks"), moment().startOf("week").add(1, "weeks").endOf("week")],
  "Next 2 weeks": [moment().startOf("week"), moment().startOf("week").add(1, "weeks").endOf("week")],
  "Next 4 weeks": [moment().startOf("week"), moment().startOf("week").add(4, "weeks").endOf("week")],
  "This month": [moment().startOf("month"), moment().startOf("month").endOf("month")],
  "This month and next": [moment().startOf("month"), moment().startOf("month").add(1, "month").endOf("month")],
};

export const REVIEW_DEFAULT_COMMENT_FONT_SIZE = 5;
export const REVIEW_MIN_COMMENT_FONT_SIZE = 5;
export const REVIEW_ACTIVITY_ITEMS_PER_PAGE = 40;

// cookies
export const COOKIE_NAME_DASHBOARD_EXCLUDED_COLUMNS = "excluded-dashboard-table-columns";
export const COOKIE_NAME_PROJECT_FILTERS = "project-filters";
export const COOKIE_NAME_QUOTE_FILTERS = "quote-filters";
export const COOKIE_NAME_REQUEST_FILTERS = "request-filters";
export const COOKIE_NAME_STOCK_ITEM_FILTERS = "stock-item-filters";
export const COOKIE_NAME_INVOICE_FILTERS = "invoice-filters";
export const COOKIE_NAME_PURCHASE_ORDER_FILTERS = "purchase-order-filters";
export const COOKIE_NAME_TEMPLATE_EDITOR_PANEL_WIDTH = "template-editor-panel-width";
export const COOKIE_NAME_TEMPLATE_EDITOR_HIERARCHY_WIDTH = "template-editor-hierarchy-width";
export const COOKIE_NAME_QUOTE_PREVIEW = "is-quote-preview-enabled";
export const COOKIE_NAME_QUOTE_ARE_COMMENTS_VISIBLE = "are-quote-comments-visible";
export const COOKIE_NAME_ORGANISATION_PRIMARY_TAB = "organisation-primary-tab";
export const COOKIE_NAME_ORGANISATION_SECONDARY_TAB = "organisation-secondary-tab";
export const COOKIE_NAME_TIMELINE_RESTORED_ZOOM_LEVEL = "timeline-page-zoom-level";
export const COOKIE_NAME_TIMELINE_HOURS_IN_A_DAY = "timeline-hours-in-a-day";
export const COOKIE_NAME_TIMELINE_HOUR_BLOCK_WIDTH = "timelinehour-block-width";
export const COOKIE_NAME_TIMELINE_SNAP_COEFFICIENT_DAYS = "timeline-snap-coefficient-days";
export const COOKIE_NAME_TIMELINE_DEFAULT_TASK_LENGTH_HOURS = "timeline-default-task-length-hours";
export const COOKIE_NAME_TIMELINE_FILTER_CLIENT_ID = "timeline-filter-client-id";
export const COOKIE_NAME_TIMELINE_FILTER_PROJECT_ID = "timeline-filter-project-id";
export const COOKIE_NAME_TIMELINE_FILTER_TASK_ID = "timeline-filter-task-id";
export const COOKIE_NAME_TIMELINE_SHOW_PSEUDO_TASKS = "timeline-show-pseudo-tasks";
export const COOKIE_NAME_TIMELINE_SHOW_TASK_IDS = "timeline-show-task-ids";
export const COOKIE_NAME_TIMELINE_SHOW_TASK_TITLES = "timeline-show-task-titles";
export const COOKIE_NAME_TIMELINE_SHOW_PROJECT_TITLES = "timeline-show-project-titles";
export const COOKIE_NAME_TIMELINE_DEFAULT_DATE_RANGE = "timeline-default-date-range";
export const COOKIE_NAME_TIMELINE_USER_ROWS = "timeline-user-rows";
export const COOKIE_NAME_TEMPLATE_EDITOR_DISPLAY_PADDING_LIMIT = "template-editor-display-padding-limit";
export const COOKIE_NAME_TEMPLATE_EDITOR_DISPLAY_PAGE_HEIGHT_LIMIT = "template-editor-display-page-height-limit";
export const COOKIE_NAME_SELECTED_TEAMS = "selected-teams";

export const DOCUMENT_FORM_SPREADSHEET_DEFAULT_ROWS = 8;
export const DOCUMENT_FORM_SPREADSHEET_DEFAULT_COLUMNS = 17;
export const DEFAULT_SPREADSHEET_CELL_WIDTH = 128;

export const QUOTE_HIDDEN_LINE_ITEM_COLUMN_OPTIONS = [
  {
    label: "Authority level",
    value: "authorityLevel",
  },
  {
    label: "Check price",
    value: "checkPrice",
  },
  {
    label: "Hourly checkbox",
    value: "isHourly",
  },
  {
    label: "Unit price",
    value: "unitPrice",
  },
  {
    label: "Description",
    value: "description",
  },
];

export const LETTERS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",

  "AA",
  "AB",
  "AC",
  "AD",
  "AE",
  "AF",
  "AG",
  "AH",
  "AI",
  "AJ",
  "AK",
  "AL",
  "AM",
  "AN",
  "AP",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AV",
  "AW",
  "AX",
  "AY",
  "AZ",

  "BA",
  "BB",
  "BC",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BK",
  "BL",
  "BM",
  "BN",
  "BP",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BU",
  "BV",
  "BW",
  "BX",
  "BY",
  "BZ",

  "CA",
  "CB",
  "CC",
  "CD",
  "CE",
  "CF",
  "CG",
  "CH",
  "CI",
  "CJ",
  "CK",
  "CL",
  "CM",
  "CN",
  "CP",
  "CQ",
  "CR",
  "CS",
  "CT",
  "CU",
  "CV",
  "CW",
  "CX",
  "CY",
  "CZ",
];

export const FORM_NESTED_FIELD_LIST_FIELDS_TO_IGNORE = ["addedToTemplate"];

export const STOCK_ITEM_TYPES = [
  {
    label: "Tool",
    value: "TOOL",
  },
  {
    label: "Machine",
    value: "MACHINE",
  },
  {
    label: "Contractor",
    value: "CONTRACTOR",
  },
];

export const TIMELINE_COLORS = [
  "#ff4d4f",
  "#ff7a45",
  "#fa8c16",
  "#faad14",
  "#fadb14",
  "#a0d911",
  "#52c41a",
  "#13c2c2",
  "#1890ff",
  "#85a5ff",
  "#b37feb",
  "#f759ab",

  "#ff7875",
  "#ff9c6e",
  "#ffa940",
  "#ffc53d",
  "#ffec3d",
  "#bae637",
  "#73d13d",
  "#36cfc9",
  "#40a9ff",
  "#85a5ff",
  "#d3adf7",
  "#ff85c0",

  "#ffa39e",
  "#ffbb96",
  "#ffd591",
  "#d48806",
  "#d4b106",
  "#7cb305",
  "#389e0d",
  "#87e8de",
  "#91d5ff",
  "#adc6ff",
  "#d3adf7",
  "#ffadd2",
];

export const CUSTOM_TASK_FIELD_MARKER = "custom__";

export const WEEKDAY_OPTIONS = [
  {
    label: "Monday",
    value: 1,
  },
  {
    label: "Tuesday",
    value: 2,
  },
  {
    label: "Wednesday",
    value: 3,
  },
  {
    label: "Thursday",
    value: 4,
  },
  {
    label: "Friday",
    value: 5,
  },
  {
    label: "Saturday",
    value: 6,
  },
  {
    label: "Sunday",
    value: 7,
  },
];
