import moment from "moment";
import {
  getSheetReferenceNumberAsync,
  getAutoGeneratedSheetReferenceNumberAsync,
  getCustomSheetReferenceNumber,
} from "common/naming";
import { getDatesForDocumentTransmittal } from "./taskRevision";

const fields = [
  {
    id: "sheetName",
    fieldTypes: ["textfield"],
    label: "Sheet name",
    value: ({ sheet }) => sheet.name,
  },
  {
    id: "sheetTitle",
    fieldTypes: ["textfield"],
    label: "Sheet title",
    value: ({ sheet }) => {
      if (!sheet) {
        return "";
      }

      return sheet.description;
    },
  },
  {
    id: "sheetReference",
    fieldTypes: ["textfield"],
    label: "Sheet reference",
    value: async ({ sheet, file }) => {
      if (!sheet || !file) {
        return "";
      }

      return await getSheetReferenceNumberAsync({ sheet, file });
    },
  },
  {
    id: "sheetAutoGeneratedReference",
    fieldTypes: ["textfield"],
    label: "Auto-generated sheet reference",
    value: ({ sheet, file }) => {
      if (!sheet || !file) {
        return "";
      }

      return getAutoGeneratedSheetReferenceNumberAsync({ sheet, file });
    },
  },
  {
    id: "sheetCustomReference",
    fieldTypes: ["textfield"],
    label: "Custom sheet reference",
    value: ({ sheet, file }) => {
      if (!sheet || !file) {
        return "";
      }

      return getCustomSheetReferenceNumber({ sheet, file });
    },
  },
  {
    id: "sheetIndex",
    fieldTypes: ["textfield"],
    label: "Sheet index",
    isIndex: true,
    value: ({ file, sheet, numberPrefix, numberPrefixDigitsToRemove }) => {
      let sheetsInFile = JSON.parse(JSON.stringify(file.sheets.items)).sort((a, b) => (a.order < b.order ? -1 : 1));
      let sheetIndex = sheetsInFile.findIndex((x) => x.id === sheet.id) + 1;
      return String(parseFloat(numberPrefix) + sheetIndex).substring(parseFloat(numberPrefixDigitsToRemove));
    },
  },
  {
    id: "sheetRevisions",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision",
    repeatForFieldName: "sheetRevision",
    value: (params) => {
      const { sheet } = params;
      return sheet?.revisions.items;
    },
  },
  {
    id: "sheetRevisionCellsInDocumentTransmittal",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision cell in document transmittal",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet, taskRevision }) => {
      if (!sheet) {
        return [];
      }

      const sheetRevisions = sheet?.revisions.items || [];
      let sheetRevisionNamesSet = new Set();
      const documentTransmittalDates = getDatesForDocumentTransmittal(taskRevision);
      const cells = documentTransmittalDates.map((date) => {
        const sheetRevision = sheetRevisions.find((sheetRevision) => {
          if (sheetRevisionNamesSet.has(sheetRevision.name)) {
            return false;
          }
          let approvalDateMatches = moment(sheetRevision.reviewAcceptDate).isSame(date, "day");
          if (approvalDateMatches) {
            sheetRevisionNamesSet.add(sheetRevision.name);
          }
          return approvalDateMatches;
        });

        if (sheetRevision) {
          return sheetRevision;
        } else {
          let fakeSheetRevision = {
            name: " ",
          };
          return fakeSheetRevision;
        }
      });
      return cells;
    },
  },
  {
    id: "sheetRevisionsLatest3",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 3",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-3),
  },
  {
    id: "sheetRevisionsLatest4",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 4",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-4),
  },
  {
    id: "sheetRevisionsLatest5",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 5",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-5),
  },
  {
    id: "sheetRevisionsLatest6",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 6",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-6),
  },
  {
    id: "sheetRevisionsLatest7",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 7",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-7),
  },
  {
    id: "sheetRevisionsLatest8",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 8",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-8),
  },
  {
    id: "sheetRevisionsLatest9",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 9",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-9),
  },
  {
    id: "sheetRevisionsLatest10",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 10",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-10),
  },
  {
    id: "sheetRevisionsLatest11",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 11",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-11),
  },
  {
    id: "sheetRevisionsLatest12",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 12",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-12),
  },
  {
    id: "sheetRevisionsLatest13",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 13",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-13),
  },
  {
    id: "sheetRevisionsLatest14",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 14",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-14),
  },
  {
    id: "sheetRevisionsLatest15",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 15",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-15),
  },
  {
    id: "sheetRevisionsLatest16",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 16",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-16),
  },
  {
    id: "sheetRevisionsLatest17",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 17",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-17),
  },
  {
    id: "sheetRevisionsLatest18",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 18",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-18),
  },
  {
    id: "sheetRevisionsLatest19",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 19",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-19),
  },
  {
    id: "sheetRevisionsLatest20",
    fieldTypes: ["repeatFor"],
    label: "Each sheet revision - latest 20",
    repeatForFieldName: "sheetRevision",
    value: ({ sheet }) =>
      [...(sheet?.revisions.items || [])].sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)).slice(-20),
  },
  {
    id: "sheetRevisionName",
    fieldTypes: ["textfield"],
    label: "Current sheet revision name",
    value: ({ sheetRevision }) => {
      if (!sheetRevision) {
        return "";
      } else {
        return sheetRevision.name;
      }
    },
  },
  {
    id: "sheetRevisionStatus",
    fieldTypes: ["textfield"],
    label: "Current sheet revision status",
    value: ({ sheetRevision }) => {
      if (!sheetRevision) {
        return "";
      } else {
        return getReadableStatus(sheetRevision.status);
      }
    },
  },
  {
    id: "sheetRevisionStatusUpperCase",
    fieldTypes: ["textfield"],
    label: "Current sheet revision status - uppercase",
    value: ({ sheetRevision }) => {
      if (!sheetRevision) {
        return "";
      } else {
        return sheetRevision.status.toUpperCase().split(" ").join("_");
      }
    },
  },
  {
    id: "sheetRevisionDescription",
    fieldTypes: ["textfield"],
    label: "Current sheet revision description",
    value: ({ sheetRevision }) => {
      if (!sheetRevision) {
        return "";
      } else {
        return sheetRevision.description || "";
      }
    },
  },
  {
    id: "sheetRevisionReviewerInitials",
    fieldTypes: ["textfield"],
    label: "Current sheet revision reviewer initials",
    value: ({ users, sheetRevision }) => {
      if (!sheetRevision) {
        return "";
      } else {
        const sheetRevisionReviewerDetails =
          sheetRevision.checkedBy && users.find((x) => x.id === sheetRevision.checkedBy);
        if (sheetRevisionReviewerDetails) {
          return `${(sheetRevisionReviewerDetails.firstName || "")[0]}${
            (sheetRevisionReviewerDetails.lastName || "")[0]
          }`;
        } else {
          return "";
        }
      }
    },
  },
  {
    id: "sheetRevisionReviewerPosition",
    fieldTypes: ["textfield"],
    label: "Current sheet revision reviewer position",
    value: ({ users, sheetRevision }) => {
      if (!sheetRevision) {
        return "";
      } else {
        const sheetRevisionReviewerDetails =
          sheetRevision.checkedBy && users.find((x) => x.id === sheetRevision.checkedBy);
        if (sheetRevisionReviewerDetails) {
          return `${sheetRevisionReviewerDetails.position || ""}`;
        } else {
          return "";
        }
      }
    },
  },
  {
    id: "sheetRevisionReviewerFullName",
    fieldTypes: ["textfield"],
    label: "Current sheet revision reviewer full name",
    value: ({ users, sheetRevision }) => {
      if (!sheetRevision) {
        return "";
      } else {
        const sheetRevisionReviewerDetails =
          sheetRevision.checkedBy && users.find((x) => x.id === sheetRevision.checkedBy);
        if (sheetRevisionReviewerDetails) {
          return `${sheetRevisionReviewerDetails.firstName || ""} ${sheetRevisionReviewerDetails.lastName || ""}`;
        } else {
          return "";
        }
      }
    },
  },

  {
    id: "sheetRevisionApprovedAt",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Current sheet revision approved at",
    value: ({ sheetRevision, dateFormat, addDays }) => {
      if (!sheetRevision) {
        return "";
      } else {
        if (!sheetRevision.reviewAcceptDate) {
          return "";
        } else {
          return moment(sheetRevision.reviewAcceptDate).add(addDays, "day").format(dateFormat);
        }
      }
    },
  },
  {
    id: "sheetRevisionApprovedAtOrToday",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Current sheet revision approved at or today",
    value: ({ sheetRevision, dateFormat, addDays }) => {
      if (!sheetRevision) {
        return "";
      } else {
        if (!sheetRevision.reviewAcceptDate) {
          return moment().add(addDays, "day").format(dateFormat);
        } else {
          return moment(sheetRevision.reviewAcceptDate).add(addDays, "day").format(dateFormat);
        }
      }
    },
  },
  {
    id: "sheetRevisionAuthorInitials",
    fieldTypes: ["textfield"],
    label: "Current sheet revision author initials",
    value: ({ sheetRevision, users }) => {
      if (!sheetRevision) {
        return "";
      } else {
        const sheetRevisionAuthorDetails = sheetRevision.author && users.find((x) => x.id === sheetRevision.author);
        if (sheetRevisionAuthorDetails) {
          return `${(sheetRevisionAuthorDetails.firstName || "")[0]}${(sheetRevisionAuthorDetails.lastName || "")[0]}`;
        } else {
          return "";
        }
      }
    },
  },
  {
    id: "sheetRevisionAuthorFullName",
    fieldTypes: ["textfield"],
    label: "Current sheet revision author full name",
    value: ({ sheetRevision, users }) => {
      if (!sheetRevision) {
        return "";
      } else {
        const sheetRevisionAuthorDetails = sheetRevision.author && users.find((x) => x.id === sheetRevision.author);
        if (sheetRevisionAuthorDetails) {
          return `${sheetRevisionAuthorDetails.firstName || ""} ${sheetRevisionAuthorDetails.lastName || ""}`;
        } else {
          return "";
        }
      }
    },
  },
  {
    id: "sheetRevisionAuthorPosition",
    fieldTypes: ["textfield"],
    label: "Current sheet revision author position",
    value: ({ sheetRevision, users }) => {
      if (!sheetRevision) {
        return "";
      } else {
        const sheetRevisionAuthorDetails = sheetRevision.author && users.find((x) => x.id === sheetRevision.author);
        if (sheetRevisionAuthorDetails) {
          return `${sheetRevisionAuthorDetails.position || ""}`;
        } else {
          return "";
        }
      }
    },
  },

  {
    id: "latestSheetRevisionName",
    fieldTypes: ["textfield"],
    label: "Latest sheet revision name",
    value: ({ sheet }) => {
      const latestSheetRevision = sheet?.revisions?.items.slice(-1)[0];
      if (!latestSheetRevision) {
        return "";
      } else {
        return latestSheetRevision.name;
      }
    },
  },
  {
    id: "latestSheetRevisionStatus",
    fieldTypes: ["textfield"],
    label: "Latest sheet revision status",
    value: ({ sheet }) => {
      const latestSheetRevision = sheet?.revisions?.items.slice(-1)[0];
      if (!latestSheetRevision) {
        return "";
      } else {
        return getReadableStatus(latestSheetRevision.status);
      }
    },
  },
  {
    id: "latestSheetRevisionStatusUppercase",
    fieldTypes: ["textfield"],
    label: "Latest sheet revision status - uppercase",
    value: ({ sheet }) => {
      const latestSheetRevision = sheet?.revisions?.items.slice(-1)[0];
      if (!latestSheetRevision) {
        return "";
      } else {
        return latestSheetRevision.status.toUpperCase().split(" ").join("_");
      }
    },
  },

  {
    id: "latestSheetRevisionDescription",
    fieldTypes: ["textfield"],
    label: "Latest sheet revision description",
    value: ({ sheet }) => {
      const latestSheetRevision = sheet?.revisions?.items.slice(-1)[0];
      if (!latestSheetRevision) {
        return "";
      } else {
        return latestSheetRevision.description || "";
      }
    },
  },

  {
    id: "latestSheetRevisionReviewerInitials",
    fieldTypes: ["textfield"],
    label: "Latest sheet revision reviewer initials",
    value: ({ users, sheet }) => {
      const latestSheetRevision = sheet?.revisions?.items.slice(-1)[0];
      if (!latestSheetRevision) {
        return "";
      } else {
        const sheetRevisionReviewerDetails =
          latestSheetRevision.checkedBy && users.find((x) => x.id === latestSheetRevision.checkedBy);
        if (sheetRevisionReviewerDetails) {
          return `${(sheetRevisionReviewerDetails.firstName || "")[0]}${
            (sheetRevisionReviewerDetails.lastName || "")[0]
          }`;
        } else {
          return "";
        }
      }
    },
  },
  {
    id: "latestSheetRevisionReviewerFullName",
    fieldTypes: ["textfield"],
    label: "Latest sheet revision reviewer full name",
    value: ({ users, sheet }) => {
      const latestSheetRevision = sheet?.revisions?.items.slice(-1)[0];
      if (!latestSheetRevision) {
        return "";
      } else {
        const sheetRevisionReviewerDetails =
          latestSheetRevision.checkedBy && users.find((x) => x.id === latestSheetRevision.checkedBy);
        if (sheetRevisionReviewerDetails) {
          return `${sheetRevisionReviewerDetails.firstName || ""} ${sheetRevisionReviewerDetails.lastName || ""}`;
        } else {
          return "";
        }
      }
    },
  },
  {
    id: "latestSheetRevisionReviewerPosition",
    fieldTypes: ["textfield"],
    label: "Latest sheet revision reviewer position",
    value: ({ users, sheet }) => {
      const latestSheetRevision = sheet?.revisions?.items.slice(-1)[0];
      if (!latestSheetRevision) {
        return "";
      } else {
        const sheetRevisionReviewerDetails =
          latestSheetRevision.checkedBy && users.find((x) => x.id === latestSheetRevision.checkedBy);
        if (sheetRevisionReviewerDetails) {
          return `${sheetRevisionReviewerDetails.position || ""}`;
        } else {
          return "";
        }
      }
    },
  },

  {
    id: "latestSheetRevisionApprovedAt",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Latest sheet revision approved at",
    value: ({ sheet, dateFormat, addDays }) => {
      const latestSheetRevision = sheet?.revisions?.items.slice(-1)[0];
      if (!latestSheetRevision) {
        return "";
      } else {
        if (!latestSheetRevision.reviewAcceptDate) {
          return "";
        } else {
          return moment(latestSheetRevision.reviewAcceptDate).add(addDays, "day").format(dateFormat);
        }
      }
    },
  },
  {
    id: "latestSheetRevisionApprovedAtOrToday",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Latest sheet revision approved at or today",
    value: ({ sheet, dateFormat, addDays }) => {
      const latestSheetRevision = sheet?.revisions?.items.slice(-1)[0];
      if (!latestSheetRevision) {
        return "";
      } else {
        if (!latestSheetRevision.reviewAcceptDate) {
          return moment().add(addDays, "day").format(dateFormat);
        } else {
          return moment(latestSheetRevision.reviewAcceptDate).add(addDays, "day").format(dateFormat);
        }
      }
    },
  },

  {
    id: "latestSheetRevisionAuthorInitials",
    fieldTypes: ["textfield"],
    label: "Latest sheet revision author initials",
    value: ({ sheet, users }) => {
      const latestSheetRevision = sheet?.revisions?.items.slice(-1)[0];
      if (!latestSheetRevision) {
        return "";
      } else {
        const sheetRevisionAuthorDetails =
          latestSheetRevision.author && users.find((x) => x.id === latestSheetRevision.author);
        if (sheetRevisionAuthorDetails) {
          return `${(sheetRevisionAuthorDetails.firstName || "")[0]}${(sheetRevisionAuthorDetails.lastName || "")[0]}`;
        } else {
          return "";
        }
      }
    },
  },

  {
    id: "latestSheetRevisionAuthorFullName",
    fieldTypes: ["textfield"],
    label: "Latest sheet revision author full name",
    value: ({ sheet, users }) => {
      const latestSheetRevision = sheet?.revisions?.items.slice(-1)[0];
      if (!latestSheetRevision) {
        return "";
      } else {
        const sheetRevisionAuthorDetails =
          latestSheetRevision.author && users.find((x) => x.id === latestSheetRevision.author);
        if (sheetRevisionAuthorDetails) {
          return `${sheetRevisionAuthorDetails.firstName || ""} ${sheetRevisionAuthorDetails.lastName || ""}`;
        } else {
          return "";
        }
      }
    },
  },

  {
    id: "latestSheetRevisionAuthorPosition",
    fieldTypes: ["textfield"],
    label: "Latest sheet revision author position",
    value: ({ sheet, users }) => {
      const latestSheetRevision = sheet?.revisions?.items.slice(-1)[0];
      if (!latestSheetRevision) {
        return "";
      } else {
        const sheetRevisionAuthorDetails =
          latestSheetRevision.author && users.find((x) => x.id === latestSheetRevision.author);
        if (sheetRevisionAuthorDetails) {
          return `${sheetRevisionAuthorDetails.position || ""}`;
        } else {
          return "";
        }
      }
    },
  },
];

export function getReadableStatus(status) {
  if (status.length === 0) {
    return status;
  }
  if (status !== status.toUpperCase()) {
    return status;
  }

  if (!status.includes("_")) {
    return status[0].toUpperCase() + status.substring(1).toLowerCase();
  }

  return status
    .split("_")
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(" ");
}

export function getFields() {
  return fields;
}

export const label = "Sheet";
