import React from "react";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";

import {
  SearchOutlined,
  FolderOutlined,
  MailOutlined,
  FileOutlined,
  FileImageOutlined,
  VideoCameraOutlined,
  FilePdfOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Spin, Input, Typography, Menu, Tag, Checkbox, Modal, message } from "antd";
// import Highlighter from "react-highlight-words";
import cx from "classnames";
import _ from "lodash";

import withSubscriptions from "common/withSubscriptions";
import { getReadableStatus } from "common/shared";
// import { stringToColor } from "common/helpers";
import { openAttachment, calculateReadableSize, processIdForDisplay } from "common/helpers";
import { callRest } from "common/apiHelpers";

import Avatar from "Avatar/Avatar";
import ClientLogo from "ClientLogo/ClientLogo";
import DashboardItemTags from "DashboardItemTags/DashboardItemTags";
import TaskIdTag from "TaskIdTag/TaskIdTag";
import FilePreview from "FilePreview/FilePreview";
import DocumentDetailsModal from "Modals/DocumentDetailsModal/DocumentDetailsModal";
import EmailDetailsModal from "Modals/EmailDetailsModal/EmailDetailsModal";

import "./GlobalSearch.scss";
import { getSimpleLabel } from "common/labels";

const MIN_CHARACTERS_FOR_SEARCH = 3;
const MAX_RESULTS_TO_DISPLAY_PER_CATEGORY = 50;

export class GlobalSearch extends React.Component {
  state = {
    value: "",
    searchValue: "",
    includeArchived: false,
    includeFinished: false,
    includeAttachments: false,
    selectedTask: null,
    isSearching: false,
    isSearchingAttachments: false,
    isModalVisible: false,
    // documents: [],
    attachments: null,
    isLoadingFile: false, // for attachments
    rootPrefix: `public/${this.props.organisationDetails.id}`, // for attachments
    isFilePreviewVisible: false, // for attachments
    documentViewModalAttachment: undefined, // for attachments
    pdfPreviewData: undefined, // for attachments
    emailPreviewData: undefined, // for attachments
    activeIndex: 0,
  };

  lastAttachmentSearchId = 0;

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.searchInputRef = React.createRef();
    this.debouncedSetSearchValue = _.debounce(this.setSearchValue, 300);
    this.debouncedSearchAttachments = _.debounce(this.searchAttachments, 500);
  }

  componentDidMount() {
    window.addEventListener("keydown", this.onKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyUp);
  }

  async componentDidUpdate(_, prevState) {
    if (!prevState.isModalVisible && this.state.isModalVisible) {
      setTimeout(() => {
        if (this.searchInputRef.current) {
          this.searchInputRef.current.focus();
        }
      }, 100);
    }

    if (prevState.searchValue !== this.state.searchValue) {
      this.lastAttachmentSearchId = Date.now();
      this.setState({
        isSearching: false,
        isSearchingAttachments: false,
        attachments: null,
      });

      if (!this.state.searchValue) {
        this.setState({ attachments: null });
      } else {
        if (this.state.includeAttachments) {
          this.setState({
            isSearchingAttachments: true,
          });
          this.debouncedSearchAttachments(this.state.searchValue);
        } else {
          this.setState({
            isSearching: false,
            isSearchingAttachments: false,
            attachments: null,
          });
        }
      }
    }
  }

  searchAttachments = async (searchValue) => {
    this.setState({ isSearchingAttachments: true });

    let searchId = Date.now();
    this.lastAttachmentSearchId = searchId;

    let searchResults;

    try {
      let response = await callRest({
        message: "Failed to search attachments",
        method: "GET",
        route: `/search?q=${encodeURIComponent((searchValue || "").toLowerCase())}`,
      });

      if (searchId !== this.lastAttachmentSearchId) {
        return;
      }

      searchResults = response || [];
    } catch (e) {
      console.error("Error searching attachments: ", e);
      this.setState({
        isSearchingAttachments: false,
        attachmentSearchResults: [],
      });
    }

    let searchItems = searchResults?.map((item) => {
      return {
        key: item._source.id,
        name: item._source.name,
        type: item._source.type,
        size: item._source.size,
        lastModified: item._source.s3UpdatedAt,
      };
    });

    this.setState({
      attachments: searchItems || [],
      isSearchingAttachments: false,
    });
  };

  // fetchDocuments = async (searchValue) => {
  //   if (!searchValue) {
  //     this.setState({ documents: [], isSearchingAttachments: false });
  //     return;
  //   }

  //   try {
  //     const searchResults = await callRest({
  //       method: "POST",
  //       route: "/opensearch",
  //       body: {
  //         organisationId: window.apiUser.organisation,
  //         query: searchValue,
  //       },
  //     });

  //     const hits = searchResults.hits.hits;

  //     let documents = [];

  //     if (hits && hits.length > 0) {
  //       for (let hit of hits) {
  //         const documentData = hit._source;
  //         const parsedJsonData = JSON.parse(documentData.data);
  //         const processedPages = parsedJsonData?.processedPages;
  //         const fileId = documentData.fileId;
  //         const fileKey = parsedJsonData.fileKey;
  //         const filterValue = searchValue.toLowerCase();

  //         if (processedPages) {
  //           for (let i = 0; i < processedPages.length; i++) {
  //             const pageData = processedPages[i];

  //             if (pageData?.content?.toLowerCase().includes(filterValue)) {
  //               documents.push({
  //                 ...pageData,
  //                 fileName: `${fileId} (page ${i + 1})`,
  //                 fileKey,
  //               });
  //             }
  //           }
  //         }
  //       }
  //     }
  //     this.setState({ documents });
  //   } catch (err) {
  //     this.setState({ documents: [] });
  //     console.log("err = ", err);
  //   }
  //   this.setState({ isSearchingAttachments: false });
  // };

  setSearchValue = () => {
    this.setState({ searchValue: this.state.value });
  };

  onKeyUp = (e) => {
    const { activeIndex, isModalVisible } = this.state;

    if (window.location.pathname.includes("/templates/annotation-editor")) {
      return;
    }
    if ((e.ctrlKey || e.metaKey) && e.key === "f") {
      e.preventDefault();
      this.setState({
        isModalVisible: true,
      });
    } else if (e.key === "Escape" && this.state.isModalVisible) {
      const inputElement = document.querySelector(".global-search .filter-input input");
      if (inputElement) {
        inputElement.blur();
        this.setState({ isModalVisible: false });
      }
    } else {
      if (!isModalVisible) {
        return;
      }
      switch (e.keyCode) {
        case 38: //up
          this.setState(
            {
              activeIndex: Math.max(activeIndex - 1, 0),
            },
            this.scrollToCurrentElement
          );
          break;
        case 40: //down
          this.setState(
            {
              activeIndex: activeIndex + 1,
            },
            this.scrollToCurrentElement
          );
          break;
        case 13: //enter
          this.openSelectedItem();
          break;
      }
    }
  };

  scrollToCurrentElement = () => {
    setTimeout(() => {
      const activeElement = document.querySelector(`.result.selected`);
      if (activeElement) {
        activeElement.scrollIntoView({ block: "nearest" });
      }
    }, 200);
  };

  openSelectedItem = () => {
    const selectedItem = document.querySelector(`.result.selected`);
    if (selectedItem) {
      selectedItem.click();
    }
  };

  displayResults = () => {
    const { searchValue, includeArchived, includeFinished, isSearching, isSearchingAttachments, activeIndex } =
      this.state;
    const { clients, projects, windowWidth } = this.props;

    if (!searchValue || searchValue.length === 0) {
      return <Menu />;
    }

    if (isSearching || isSearchingAttachments) {
      return (
        <Menu className="instant-search-results">
          <div className="spinner-container">
            <Spin />
          </div>
        </Menu>
      );
    }

    if (searchValue.length < MIN_CHARACTERS_FOR_SEARCH) {
      return (
        <Menu className="instant-search-results">
          <Typography.Text className="invalid-search-value-message">
            Please type at least {MIN_CHARACTERS_FOR_SEARCH} characters to see search results.
          </Typography.Text>
        </Menu>
      );
    }

    let filterValue = searchValue.toLowerCase().trim();

    const categories = [
      this.state.includeAttachments && {
        fullWidthLabel: true,
        label: (
          <div className="attachments-category-header">
            <Typography.Text className="attachments-category-title">Attachments</Typography.Text>
            {this.state.attachments?.length > 50 && (
              <Typography.Text className="attachments-category-subtitle">
                (Only displaying the first {MAX_RESULTS_TO_DISPLAY_PER_CATEGORY} results)
              </Typography.Text>
            )}
            <div className="list-headers attachment-list-header">
              <div className="attachment-content">
                <Typography.Text className="name">Name ({this.state.attachments?.length} items )</Typography.Text>
                <Typography.Text className="file-size">Size</Typography.Text>
                <Typography.Text className="updated-at">Last updated</Typography.Text>
                <Typography.Text className="created-at">Created</Typography.Text>
              </div>
            </div>
          </div>
        ),
        collectionName: "attachments",
        render: (attachment, index) => {
          let sizeReadable = calculateReadableSize(attachment);
          let name = attachment.key.substring(`public/${this.props.organisationDetails.id}/`.length);

          return (
            <>
              <div
                className="attachment-item"
                onClick={() => {
                  openAttachment.call(this, attachment);
                }}
              >
                <div className="attachment-content">
                  <span className={cx("name", `type-${(attachment.type || "").toLowerCase()}`)}>
                    {attachment.type === "FOLDER" ? <FolderOutlined className="file-type-icon" /> : null}
                    {attachment.type === "IMAGE" ? <FileImageOutlined className="file-type-icon" /> : null}
                    {attachment.type === "VIDEO" ? <VideoCameraOutlined className="file-type-icon" /> : null}
                    {attachment.type === "PDF" ? <FilePdfOutlined className="file-type-icon" /> : null}
                    {attachment.type === "EMAIL" ? <MailOutlined className="file-type-icon" /> : null}
                    {attachment.type === "OTHER" ? <FileOutlined className="file-type-icon" /> : null}
                    <span className="file-name" data-cy="file-name" data-index={index}>
                      {name}
                    </span>
                  </span>
                  <span className="file-size" data-cy="file-size">
                    {attachment.type === "FOLDER" ? "-" : sizeReadable}
                  </span>
                  <span className="updated-at">
                    {attachment.type === "FOLDER" ? "-" : moment(attachment.s3UpdatedAt).format("MMM DD YYYY, HH:mm")}
                  </span>
                  <span className="created-at">
                    {attachment.type === "FOLDER" ? "-" : moment(attachment.s3CreatedAt).format("MMM DD YYYY, HH:mm")}
                  </span>
                </div>
              </div>
            </>
          );
        },
      },
      {
        label: getSimpleLabel("Tasks"),
        collectionName: "tasks",
        link: (task) => `/tasks/${task.id}`,
        filter: (task) => {
          if (task.isHidden || task.isExternalReference) {
            return false;
          }
          if (!includeArchived && task.isArchived) {
            return false;
          }
          if (!includeFinished && task.isFinished) {
            return false;
          }
          return task.title.toLowerCase().includes(filterValue) || task.id.toLowerCase().includes(filterValue);
        },
        render: (task) => {
          let client = clients.find((x) => x.id === task.clientId);
          let project = projects.find((x) => x.id === task.projectId);
          return (
            <>
              <TaskIdTag
                task={{
                  ...task,
                  client,
                }}
                includeTitle={false}
                style={{ minWidth: "100px" }}
              />
              <div className="task-title-and-tags">
                {windowWidth <= 600 && client && (
                  <Typography.Text className="task-client-name">{client.name}</Typography.Text>
                )}
                {project && <Typography.Text className="task-project-title">{project.title}</Typography.Text>}
                <Typography.Text className="task-title">{task.title}</Typography.Text>
                <DashboardItemTags item={task} />
              </div>

              {windowWidth > 600 && client && <ClientLogo client={client} />}

              <Typography.Text className="task-status">{getReadableStatus(task.status)}</Typography.Text>
            </>
          );
        },
      },
      {
        label: getSimpleLabel("Projects"),
        collectionName: "projects",
        link: (project) => `/projects/${project.id}`,
        filter: (project) => {
          if (!includeArchived && project.isArchived) {
            return false;
          }
          return project.title.toLowerCase().includes(filterValue) || project.id.toLowerCase().includes(filterValue);
        },
        render: (project) => {
          const client = clients.find((x) => x.id === project.clientId);
          return (
            <>
              <Tag className="project-id-tag dark-tag" style={{ minWidth: "100px" }}>
                {processIdForDisplay(project.id)}
              </Tag>{" "}
              {windowWidth <= 600 && client && (
                <Typography.Text className="project-client-name">{client.name}</Typography.Text>
              )}
              <Typography.Text className="project-title">{project.title}</Typography.Text>
              {windowWidth > 600 && <ClientLogo client={client} />}
            </>
          );
        },
      },
      {
        label: getSimpleLabel("Requests"),
        collectionName: "requests",
        link: (request) => `/requests/${request.id}`,
        filter: (request) => {
          return (
            request.title !== null &&
            request.latestFormName !== null &&
            (request.title.toLowerCase().includes(filterValue) ||
              request.latestFormName.toLowerCase().includes(filterValue))
          );
        },
        render: (request) => (
          <>
            <Tag className="request-id-tag dark-tag" style={{ minWidth: "100px" }}>
              {processIdForDisplay(request.id)}
            </Tag>{" "}
            <Typography.Text className="request-title">{request.title}</Typography.Text>
          </>
        ),
      },
      {
        label: getSimpleLabel("Clients"),
        link: (client) => `/clients/${client.id}`,
        collectionName: "clients",
        filter: (client) => client.name.toLowerCase().includes(filterValue),
        render: (client) => {
          return (
            <>
              {client.key && <ClientLogo client={client} size="small" />}
              <Typography.Text className="client-name">{client.name}</Typography.Text>
            </>
          );
        },
      },
      {
        label: getSimpleLabel("Quotes"),
        collectionName: "quotes",
        link: (quote) => `/quotes/${quote.id}`,
        filter: (quote) => {
          if (!includeArchived && quote.isArchived) {
            return false;
          }
          return quote.title?.toLowerCase().includes(filterValue) || quote.id.toLowerCase().includes(filterValue);
        },
        render: (quote) => {
          const client = clients.find((x) => x.id === quote.clientId);
          return (
            <>
              <Tag className="quote-id-tag dark-tag" style={{ minWidth: "100px" }}>
                {processIdForDisplay(quote.id)}
              </Tag>{" "}
              {windowWidth <= 600 && client && (
                <Typography.Text className="project-client-name">{client.name}</Typography.Text>
              )}
              <Typography.Text className="quote-title">
                {quote.title} <DashboardItemTags item={quote} skipInstructed skipCatLevel skipCustomFields />
              </Typography.Text>
              {windowWidth > 600 && client && <ClientLogo client={client} />}
            </>
          );
        },
      },
      {
        label: getSimpleLabel("Invoices"),
        collectionName: "invoices",
        link: (invoice) => `/invoices/${invoice.id}`,
        filter: (invoice) => {
          return invoice.id.toLowerCase().includes(filterValue);
        },
        render: (invoice) => {
          const client = clients.find((x) => x.id === invoice.clientId);
          return (
            <>
              <Tag className="invoice-id-tag dark-tag" style={{ minWidth: "100px" }}>
                {processIdForDisplay(invoice.id)}
              </Tag>{" "}
              {windowWidth <= 600 && client && (
                <Typography.Text className="project-client-name">{client.name}</Typography.Text>
              )}
              <div className="invoice-tags">
                <DashboardItemTags item={invoice} isInvoice={true} skipInstructed skipCatLevel skipCustomFields />
              </div>
              {windowWidth > 600 && client && <ClientLogo client={client} />}
            </>
          );
        },
      },
      {
        label: getSimpleLabel("Purchase orders"),
        collectionName: "purchaseOrders",
        link: (purchaseOrder) => `/purchase-orders/${purchaseOrder.id}`,
        filter: (purchaseOrder) => {
          return (
            purchaseOrder.title?.toLowerCase().includes(filterValue) ||
            purchaseOrder.id.toLowerCase().includes(filterValue)
          );
        },
        render: (purchaseOrder) => {
          const client = clients.find((x) => x.id === purchaseOrder.clientId);
          return (
            <>
              <Tag className="purchase-order-id-tag dark-tag" style={{ minWidth: "100px" }}>
                {processIdForDisplay(purchaseOrder.id)}
              </Tag>{" "}
              {windowWidth <= 600 && client && (
                <Typography.Text className="project-client-name">{client.name}</Typography.Text>
              )}
              <Typography.Text className="purchase-order-title">{purchaseOrder.title}</Typography.Text>
              <DashboardItemTags item={purchaseOrder} skipInstructed skipCatLevel skipCustomFields />
              {windowWidth > 600 && client && <ClientLogo client={client} />}
            </>
          );
        },
      },
      {
        label: getSimpleLabel("Users"),
        collectionName: "users",
        link: (user) => `/users/${user.id}`,
        filter: (user) =>
          !user.isHidden &&
          (`${user.firstName} ${user.lastName}`.toLowerCase().includes(filterValue) || user.id.includes(filterValue)),
        render: (user) => (
          <>
            <Avatar user={user} showLabel />
          </>
        ),
      },

      // {
      //   label: "Documents",
      //   collectionName: "documents",
      //   link: (document) => `/document-viewer/${encodeURIComponent(document.fileKey)}/${document.pageNumber + 1}`,
      //   filter: (document) => {
      //     return true;
      //   },
      //   render: (document) => {
      //     let lowercaseContent = document.content?.toLowerCase();
      //     let firstOccurenceIndex = lowercaseContent?.indexOf(filterValue);
      //     let contentPreview = document.content?.substring(firstOccurenceIndex - 100, firstOccurenceIndex + 100);

      //     return (
      //       <div className="document-item" data-cy="searchable-document">
      //         <Typography.Text className="document-name">{document.fileName}</Typography.Text>
      //         <Highlighter
      //           highlightClassName="search-highlight"
      //           searchWords={[filterValue]}
      //           autoEscape={true}
      //           textToHighlight={contentPreview}
      //         />
      //       </div>
      //     );
      //   },
      // },
    ].filter((x) => x);

    let categoriesWithResults = [];
    categories.forEach((category) => {
      let collection = this.props[category.collectionName] || this.state[category.collectionName] || [];

      let results;
      if (category.filter) {
        results = collection.filter(category.filter);
      } else {
        results = collection;
      }
      if (results.length > 0) {
        if (results.length > MAX_RESULTS_TO_DISPLAY_PER_CATEGORY) {
          const truncatedResults = results.slice(0, MAX_RESULTS_TO_DISPLAY_PER_CATEGORY);

          categoriesWithResults.push({
            ...category,
            hasTooManyResults: true,
            results: truncatedResults,
          });
        } else {
          categoriesWithResults.push({
            ...category,
            results,
          });
        }
      }
    });

    return (
      <Menu className="instant-search-results">
        <div className="inner-container" ref={this.containerRef} onScroll={this.onScroll}>
          {categoriesWithResults.length === 0 ? (
            <Typography.Text className="no-results">No items match your search</Typography.Text>
          ) : (
            categoriesWithResults.map((category, categoryIndex) => {
              let hasOnClick = !!category.onClick;
              if (!category.onClick) {
                category.onClick = () => {};
              }

              return (
                <div className={cx("category", { "full-width": category.fullWidthLabel })} key={category.label}>
                  <Typography.Text className="category-label">
                    {category.label}{" "}
                    {typeof category.label === "string" &&
                      category.hasTooManyResults &&
                      `(only displaying the first ${MAX_RESULTS_TO_DISPLAY_PER_CATEGORY} results)`}
                  </Typography.Text>
                  <ul className="results-in-category">
                    {category.results.map((resultItem, resultIndex) => {
                      let previousCategories = categoriesWithResults.slice(0, categoryIndex);
                      let totalLengthOfPreviousCategories = previousCategories.reduce(
                        (acc, crtCategory) => acc + crtCategory.results.length,
                        0
                      );
                      let globalIndex = totalLengthOfPreviousCategories + resultIndex;
                      let isSelected = globalIndex === activeIndex;

                      return category.link ? (
                        <Link
                          data-cy={`search-result-${category.collectionName.substring(
                            0,
                            category.collectionName.length - 1
                          )}`}
                          data-item-id={resultItem.id}
                          to={category.link(resultItem)}
                          className={cx("result", "clickable", { selected: isSelected })}
                          key={resultItem.id}
                          onMouseEnter={() => {
                            this.setState({ activeIndex: globalIndex });
                          }}
                          onClick={() => {
                            this.setState({ value: "", isModalVisible: false }, this.debouncedSetSearchValue);
                          }}
                        >
                          {category.render(resultItem)}
                        </Link>
                      ) : (
                        <li
                          className={cx("result", { clickable: hasOnClick })}
                          key={resultItem.id}
                          onClick={() => category.onClick(resultItem)}
                          data-cy={`search-result-${category.collectionName.substring(
                            0,
                            category.collectionName.length - 1
                          )}`}
                          data-item-id={resultItem.id}
                        >
                          {category.render(resultItem)}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })
          )}
        </div>
      </Menu>
    );
  };

  displayModal = () => {
    const {
      isModalVisible,
      isFilePreviewVisible,
      pdfPreviewData,
      value,
      includeArchived,
      includeFinished,
      includeAttachments,
      isLoadingFile,
    } = this.state;

    return (
      <>
        <Modal
          className="modal-global-search"
          open={isModalVisible && !isFilePreviewVisible && !pdfPreviewData && !isLoadingFile}
          footer={null}
          onCancel={() => {
            this.setState({ isModalVisible: false });
          }}
          closable={false}
          maskClosable
        >
          <div className="search-filters">
            <Checkbox
              checked={includeAttachments}
              onChange={(e) => {
                this.setState({ includeAttachments: e.target.checked }, () => {
                  this.lastAttachmentSearchId = Date.now();
                  this.setState({
                    isSearching: false,
                    isSearchingAttachments: false,
                    attachments: null,
                  });
                  if (!e.target.checked) {
                    this.setState({ attachments: null });
                  } else {
                    if (this.state.searchValue) {
                      this.searchAttachments(this.state.searchValue);
                    }
                  }
                });
              }}
            >
              <span data-cy="search-include-attachments">Include attachments</span>
            </Checkbox>
            <Checkbox
              checked={includeArchived}
              onChange={(e) => {
                this.setState({ includeArchived: e.target.checked });
              }}
            >
              <span data-cy="search-include-archived">Include archived</span>
            </Checkbox>
            <Checkbox
              checked={includeFinished}
              onChange={(e) => {
                this.setState({ includeFinished: e.target.checked });
              }}
            >
              <span data-cy="search-include-finished">Include finished</span>
            </Checkbox>
          </div>

          <Input
            data-cy="search-input"
            className="filter-input"
            placeholder={`Search for ${getSimpleLabel("tasks")}, ${getSimpleLabel("projects")}, ${getSimpleLabel(
              "clients"
            )}, etc.`}
            prefix={<SearchOutlined />}
            allowClear
            value={value}
            onChange={(e) => this.setState({ value: e.target.value }, this.debouncedSetSearchValue)}
            ref={this.searchInputRef}
          />

          {this.displayResults()}
        </Modal>
      </>
    );
  };

  displayModalLoadingFile = () => {
    return (
      <Modal
        maskClosable={false}
        title="Loading file..."
        open={true}
        footer={null}
        className="loading-file-modal"
        onCancel={() => this.setState({ isLoadingFile: false })}
      >
        <LoadingOutlined />
      </Modal>
    );
  };

  render() {
    const { documentViewModalAttachment, pdfPreviewData, emailPreviewData, isLoadingFile } = this.state;
    const { windowWidth, windowHeight } = this.props;
    return (
      <div className="global-search">
        <SearchOutlined
          className="search-icon"
          onClick={() => this.setState({ isModalVisible: true })}
          style={{ cursor: "pointer" }}
          data-cy="navigation-search-icon"
        />
        {this.displayModal()}
        {this.state.isFilePreviewVisible && (
          <FilePreview
            attachments={this.state.attachments}
            initiallySelectedAttachment={this.state.documentViewModalAttachment}
            onClose={() => this.setState({ isFilePreviewVisible: false, documentViewModalAttachment: undefined })}
            organisationId={this.props.organisationDetails.id}
          />
        )}
        {this.state.pdfPreviewData && (
          <DocumentDetailsModal
            attachment={documentViewModalAttachment}
            document={pdfPreviewData}
            onClose={() => this.setState({ pdfPreviewData: null })}
            allowDownload={true}
            windowWidth={windowWidth}
            windowHeight={windowHeight}
          />
        )}
        {this.state.emailPreviewData && (
          <EmailDetailsModal
            attachment={documentViewModalAttachment}
            emailData={emailPreviewData}
            onClose={() => this.setState({ emailPreviewData: null })}
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            openAttachment={(attachment) => openAttachment.call(this, attachment)}
          />
        )}
        {isLoadingFile ? this.displayModalLoadingFile() : null}
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: GlobalSearch,
    subscriptions: [
      "organisationDetails",
      "sprints",
      "tasks",
      "users",
      "clients",
      "projects",
      "quotes",
      "invoices",
      "purchaseOrders",
      "requests",
    ],
  })
);
