import * as subscriptions from "graphql/subscriptions";
import * as subscriptionsCustom from "graphql/queries_custom";
import * as helpers from "common/helpers";
import { Modal } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { handleCreationOfNewItem } from "common/onCreateHelpers";

export function subscribeToOnUpdateOrganisation(organisations) {
  const organisationId = organisations[0].id;
  this.subscriptionOnUpdateOrganisation = API.graphql(
    graphqlOperation(subscriptions.onUpdateOrganisation, { id: organisationId })
  ).subscribe({
    next: () => {
      helpers.fetchAndSetOrganisations.call(this);
    },
  });
}

function showSubscriptionErrorModal() {
  if (!window.isSubscriptionTimeout) {
    window.isSubscriptionTimeout = true;
    Modal.confirm({
      title: "Your session has timed out",
      content: (
        <>
          If you do not refresh the page, you can still save any ongoing work, but you will not be able to see any
          updates (including ones you make).
        </>
      ),
      icon: null,
      okText: "Reload",
      cancelText: "Remind me in one minute",
      onOk: () => {
        window.location.reload();
      },
      onCancel: () => {
        window.isSubscriptionTimeout = false;
        setTimeout(showSubscriptionErrorModal, 60000);
      },
    });
  }
}

export async function subscribeToEntity(params) {
  // console.log("subscribeToEntity() params = ", params);
  let { entityNamePlural, entityNameSingular, ...subscriptionParams } = params;

  let entityName;
  if (entityNamePlural) {
    if (entityNamePlural === "analytics") {
      entityName = "analytics";
    } else {
      entityName = entityNamePlural.substring(0, entityNamePlural.length - 1);
    }
  } else {
    entityName = entityNameSingular;
  }
  const entityNameUpperCase = entityName[0].toUpperCase() + entityName.substring(1);

  let updateSubscriptionQueryName = `onUpdate${entityNameUpperCase}`;
  let updateSubscriptionQueryDefinition =
    subscriptionsCustom[updateSubscriptionQueryName] || subscriptions[updateSubscriptionQueryName];

  let createSubscriptionQueryName = `onCreate${entityNameUpperCase}`;
  let createSubscriptionQueryDefinition =
    subscriptionsCustom[createSubscriptionQueryName] || subscriptions[createSubscriptionQueryName];

  let deleteSubscriptionQueryName = `onDelete${entityNameUpperCase}`;
  let deleteSubscriptionQueryDefinition =
    subscriptionsCustom[deleteSubscriptionQueryName] || subscriptions[deleteSubscriptionQueryName];

  const updateSubscriptionName = `subscriptionOnUpdate${entityNameUpperCase}`;
  const createSubscriptionName = `subscriptionOnCreate${entityNameUpperCase}`;
  const deleteSubscriptionName = `subscriptionOnDelete${entityNameUpperCase}`;

  // we set the name of the subscription on "this" so that we can unsubscribe when the component unmounts

  this[updateSubscriptionName] = API.graphql(
    graphqlOperation(updateSubscriptionQueryDefinition, subscriptionParams)
  ).subscribe({
    error: (data) => {
      console.log("updateSubscriptionName error:", data);
      showSubscriptionErrorModal();
    },
    next: async (eventData) => {
      // console.log(`${updateSubscriptionName} triggered:`);
      const updatedItem = eventData.value.data[`onUpdate${entityNameUpperCase}`];
      // console.log("Updated item:", updatedItem);

      let newContext = this.state.context;

      if (entityNameSingular) {
        if (updatedItem.lastUpdateAuthorId && window.authorId && window.authorId === updatedItem.lastUpdateAuthorId) {
          // console.log("Just got an update sent by ourselves: ", entityNameSingular);
          return;
        }
        const filterValue = this.props.match.params[`${entityNameSingular}Id`];
        if (updatedItem.id === filterValue) {
          newContext = {
            ...this.state.context,
            [entityNameSingular]: helpers.processEntityBeforeSetState({
              entityName,
              entityData: updatedItem,
            }),
          };
        }
      } else {
        let updatedItemProcessed = helpers.processEntityBeforeSetState({
          entityName,
          entityData: updatedItem,
        });

        newContext = {
          ...this.state.context,
          [entityNamePlural]: this.state.context[entityNamePlural]?.map((x) => {
            if (x.id === updatedItem.id) {
              return updatedItemProcessed;
            } else {
              return x;
            }
          }),
        };

        let entityNamePluralById = `${entityNamePlural}ById`;

        if (newContext[entityNamePluralById]) {
          newContext[entityNamePluralById] = {
            ...newContext[entityNamePluralById],
            [updatedItemProcessed.id]: updatedItemProcessed,
          };
        }
      }

      this.setState({
        context: newContext,
      });
    },
  });

  if (entityNameSingular) {
    // for individual records, we don't need subscriptions to 'create' or 'delete' actions
    return;
  }

  this[createSubscriptionName] = API.graphql(
    graphqlOperation(createSubscriptionQueryDefinition, subscriptionParams)
  ).subscribe({
    next: (eventData) => {
      // console.log(`${entityName} created::eventData = `, eventData);
      const newItem = eventData.value.data[`onCreate${entityNameUpperCase}`];
      const newItemProcessed = helpers.processEntityBeforeSetState({
        entityName,
        entityData: newItem,
      });
      const existingItems = this.state.context[entityNamePlural];
      if (existingItems?.find((x) => x.id === newItemProcessed.id)) {
        // if the item already exists in the list, we don't add it again.
        // this will be the case if we add the item directly to the state, rather than waiting for the subscription event,
        // e.g. on the timeline view
        return;
      }

      handleCreationOfNewItem({ entityNameUpperCase, newItem: newItemProcessed });

      let newContext = {
        ...this.state.context,
        [entityNamePlural]: [...(this.state.context[entityNamePlural] || []), newItemProcessed],
      };

      let entityNamePluralById = `${entityNamePlural}ById`;

      if (newContext[entityNamePluralById]) {
        newContext[entityNamePluralById] = {
          ...newContext[entityNamePluralById],
          [newItemProcessed.id]: newItemProcessed,
        };
      }

      this.setState({
        context: newContext,
      });
    },
    error: () => {
      // nothing, we're handling timeouts and re-subscribing in the 'update' subscription
    },
  });

  this[deleteSubscriptionName] = API.graphql(
    graphqlOperation(deleteSubscriptionQueryDefinition, subscriptionParams)
  ).subscribe({
    next: (eventData) => {
      // console.log(`${entityName} ::eventData = `, eventData);
      const deletedItem = eventData.value.data[`onDelete${entityNameUpperCase}`];

      const collection = this.state.context[entityNamePlural];

      if (collection) {
        let newContext = {
          ...this.state.context,
          [entityNamePlural]: collection.filter((x) => x.id !== deletedItem.id),
        };

        let entityNamePluralById = `${entityNamePlural}ById`;

        if (newContext[entityNamePluralById]) {
          let newById = { ...newContext[entityNamePluralById] };
          delete newById[deletedItem.id];
          newContext[entityNamePluralById] = newById;
        }

        this.setState({
          context: newContext,
        });
      }
    },
    error: () => {
      // nothing, we're handling timeouts and re-subscribing in the 'update' subscription
    },
  });

  // this is necessary in order to remove all the subscriptions on the window whenever we reload the application data
  window[`${updateSubscriptionName}_${window.randomUUID()}`] = this[updateSubscriptionName];
  window[`${createSubscriptionName}_${window.randomUUID()}`] = this[createSubscriptionName];
  window[`${deleteSubscriptionName}_${window.randomUUID()}`] = this[deleteSubscriptionName];
}

export function clearSubscriptionsForComponent() {
  for (let propertyName in this) {
    if (propertyName.indexOf("subscription") === 0) {
      try {
        this[propertyName].unsubscribe();
      } catch (e) {
        console.error("ERROR failed to unsubscribe from ", propertyName, e);
        // nothing to do
      }
    }
  }
}
