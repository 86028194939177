import { useGetSetState } from "react-use";
import { Button, Table, message } from "antd";
import { SearchOutlined, DownOutlined, RightOutlined, DownloadOutlined } from "@ant-design/icons";
import axios from "axios";

import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";
import { downloadLatestPDF } from "common/taskHelpers";

import AppPageCard from "CustomApplicationPage/AppPageCard/AppPageCard";
import InfoItem from "InfoItem/InfoItem";
import Input from "Input/Input";

import "./ProjectListWithTasks.scss";

export function ProjectListWithTasks({ projectsWithTasks, style, organisationDetails, users }) {
  const [getState, setState] = useGetSetState({
    filterValue: "",
    expandedRowKeys: [],
  });

  const { filterValue, expandedRowKeys } = getState();

  async function onTaskClick(task) {
    const taskDetails = (
      await callGraphQLSimple({
        queryName: "getTaskSimple",
        variables: {
          id: task.id,
        },
      })
    ).data.getTask;

    const latestTaskRevision = taskDetails.revisions.items.slice(-1)[0];
    console.log("latestTaskRevision", latestTaskRevision);
    let file = latestTaskRevision.files.items.find((file) => ["REVIT", "AUTOCAD"].includes(file.type));
    if (!file) {
      message.error("No files to download");
      return;
    }

    const messageKey = "downloading-pdf";
    message.loading({ content: "Downloading PDF...", key: messageKey, duration: 0 });
    try {
      await downloadLatestPDF({
        file,
        task,
        users,
        organisationDetails,
      });
      message.success({ content: "PDF downloaded", key: messageKey });
    } catch (e) {
      message.destroy();
    }
    // const filesInLatestTaskRevision
  }

  function displayTasks(project) {
    return (
      <div className="tasks-in-project">
        {project.tasks.items
          .filter((task) => {
            return !task.isArchived && !task.isHidden;
          })
          .map((task) => {
            return (
              <div className="task-item" key={task.id} onClick={() => onTaskClick(task)}>
                <Button icon={<DownloadOutlined />} />
                <span className="task-title">{task.title}</span>
              </div>
            );
          })}
      </div>
    );
  }

  function onRowClick(e, project) {
    const { expandedRowKeys } = getState();

    if (expandedRowKeys.includes(project.id)) {
      setState({ expandedRowKeys: expandedRowKeys.filter((key) => key !== project.id) });
    } else {
      setState({ expandedRowKeys: [...expandedRowKeys, project.id] });
    }
  }

  let styleToUse = { ...(style || {}) };
  if (styleToUse.width) {
    styleToUse.width += 32;
  }

  let projectColumns = [
    {
      title: "",
      align: "left",
      render: (_, project) => {
        return <b>{project.title}</b>;
      },
    },
  ];

  let filteredProjects = projectsWithTasks;

  if (filterValue) {
    filteredProjects = filteredProjects
      .map((project) => {
        let tasksThatMatch = project.tasks.items.filter((task) => {
          return task.title.toLowerCase().includes(filterValue.toLowerCase());
        });

        return {
          ...project,
          tasks: {
            items: tasksThatMatch,
          },
        };
      })
      .filter((project) => {
        return project.tasks.items.length > 0 || project.title.toLowerCase().includes(filterValue.toLowerCase());
      });
  }

  return (
    <div className="project-list-with-tasks" style={styleToUse}>
      <AppPageCard>
        <Input
          className="filter-input"
          fullWidth
          showBorder
          prefix={<SearchOutlined />}
          placeholder="Type to search"
          defaultValue={filterValue}
          onChange={(value) => setState({ filterValue: value })}
          fireOnChangeWithoutBlur
        />

        <Table
          columns={projectColumns}
          dataSource={filteredProjects || []}
          pagination={{ pageSize: 50, hideOnSinglePage: true }}
          sticky={{
            offsetHeader: 5,
          }}
          rowKey="id"
          expandable={{
            expandedRowRender: displayTasks,
            expandedRowKeys,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <Button className="expand-button" icon={<DownOutlined />} onClick={(e) => onExpand(record, e)} />
              ) : (
                <Button className="collapse-button" icon={<RightOutlined />} onClick={(e) => onExpand(record, e)} />
              ),
            rowExpandable: (record) => record.name !== "Not Expandable",
          }}
          onRow={(project) => {
            return {
              onClick: (e) => {
                onRowClick(e, project);
              },
            };
          }}
        />
      </AppPageCard>
    </div>
  );
}

export default withSubscriptions({
  Component: ProjectListWithTasks,
  subscriptions: ["organisationDetails", "users"],
});
