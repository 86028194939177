import { Switch } from "antd";

import Card from "Card/Card";
import InfoItem from "InfoItem/InfoItem";

import "./ExperimentalFeatures.scss";

export default function ExperimentalFeatures({ userDetails }) {
  let features = [
    {
      label: "Auto back-up for AutoCAD and BricsCAD",
      name: "autoBackup",
    },
    {
      label: "Server-powered publish for reports",
      name: "serverPoweredReportPublish",
    },
  ];

  return (
    <Card title="Experimental features" className="experimental-features">
      {features.map((feature, i) => {
        return (
          <InfoItem
            key={i}
            inline
            label={feature.label}
            value={
              <Switch
                checked={userDetails.featureFlags && userDetails.featureFlags[feature.name]}
                onChange={(checked) => {
                  window.callGraphQLSimple({
                    message: "Failed to update preferences",
                    mutation: "updateUser",
                    variables: {
                      input: {
                        id: userDetails.id,
                        featureFlags: { ...(userDetails.featureFlags || {}), [feature.name]: checked },
                      },
                    },
                  });
                }}
              />
            }
          />
        );
      })}
    </Card>
  );
}
