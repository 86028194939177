import React from "react";
import moment from "moment";
import uniqid from "uniqid";
import { Typography, Tag, notification, Tooltip, Button, Menu } from "antd";
import { withRouter, Link } from "react-router-dom";
import { gsap } from "gsap";
import { CheckOutlined, CheckCircleFilled, EyeOutlined, EditOutlined } from "@ant-design/icons";
import { ReplyIcon } from "common/icons";
import cx from "classnames";

import { callGraphQLSimple } from "common/apiHelpers";
import { HAS_SHEETS } from "common/shared";
import { findCommentRecursive, markCommentAsResolved } from "../reviewHelpers";
import { getSimpleLabel } from "common/labels";

import ReviewCommentBox from "../ReviewCommentBox/ReviewCommentBox";
import Avatar from "Avatar/Avatar";
import Card from "Card/Card";
import Input from "Input/Input";
import Textarea from "DocumentForm/Textarea/Textarea";

import "./ReviewThreadItem.scss";

export class ReviewThreadItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCommentBoxVisible: false,
      isHighlightedThroughHover: false,
      isEditing: false,
    };

    this.ownElementRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("click", this.onOutsideClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onOutsideClick);
  }

  onOutsideClick = () => {
    this.setState({ isCommentBoxVisible: false });
  };

  componentDidUpdate(prevProps) {
    let isHighlightedNow =
      this.props.highlightedAnnotationId &&
      (this.props.type === "ANNOTATION_TEXT" || this.props.type === "ANNOTATION_LEADER_LINE") &&
      this.props.content?.includes(this.props.highlightedAnnotationId);

    let wasHighlightedBefore =
      prevProps.highlightedAnnotationId &&
      (prevProps.type === "ANNOTATION_TEXT" || prevProps.type === "ANNOTATION_LEADER_LINE") &&
      prevProps.content?.includes(prevProps.highlightedAnnotationId);

    if (isHighlightedNow !== wasHighlightedBefore) {
      this.setState({ isHighlightedThroughHover: isHighlightedNow });
    }

    if (isHighlightedNow && !wasHighlightedBefore) {
      const element = this.props.elementRef?.current || this.ownElementRef?.current;

      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }

  getUpToDateReview = async () => {
    const upToDateReview = (
      await callGraphQLSimple({
        message: "Failed to retrieve review details",
        queryName: "getReview",
        variables: {
          id: this.props.review.id,
        },
      })
    ).data.getReview;

    return upToDateReview;
  };

  resolveComment = async () => {
    const { id, taskRevision, apiUser } = this.props;
    const reviewInput = await this.getUpToDateReview();

    markCommentAsResolved({
      currentNode: reviewInput,
      activityId: id,
      apiUser,
    });

    await callGraphQLSimple({
      message: "Failed to submit comment",
      queryName: "updateReview",
      variables: {
        input: {
          id: reviewInput.id,
          reviewThread: reviewInput.reviewThread,
          randomNumber: Math.floor(Math.random() * 100000), // we need to set this so that the change is reflected on everyone's draw area
        },
      },
    });
    await callGraphQLSimple({
      message: "Failed to submit comment",
      queryName: "updateTaskRevision",
      variables: {
        input: {
          id: taskRevision.id,
          randomNumber: Math.floor(Math.random() * 100000),
        },
      },
    });
    if (this.props.refreshDrawing) {
      setTimeout(this.props.refreshDrawing, 2000);
    }
  };

  submitReply = async (commentValue) => {
    const { id, taskRevision, apiUser } = this.props;
    this.setState({ isCommentBoxVisible: false });

    const reviewInput = await this.getUpToDateReview();

    const targetParent = findCommentRecursive({
      currentNode: reviewInput,
      activityId: id,
    });

    if (targetParent) {
      if (!targetParent.reviewThread) {
        targetParent.reviewThread = [];
      }

      targetParent.reviewThread.push({
        id: uniqid(),
        type: "COMMENT",
        createdAt: new Date().toISOString(),
        content: commentValue,
        author: apiUser.id,
      });

      await callGraphQLSimple({
        message: "Failed to submit comment",
        queryName: "updateReview",
        variables: {
          input: {
            id: reviewInput.id,
            reviewThread: reviewInput.reviewThread,
          },
        },
      });

      if (this.props.request) {
        await callGraphQLSimple({
          message: "Failed to submit comment",
          queryName: "updateRequest",
          variables: {
            input: {
              id: this.props.request.id,
              itemSubscription: Math.floor(Math.random() * 100000),
            },
          },
        });
      } else {
        await callGraphQLSimple({
          message: "Failed to submit comment",
          queryName: "updateTaskRevision",
          variables: {
            input: {
              id: taskRevision.id,
              randomNumber: Math.floor(Math.random() * 100000),
            },
          },
        });
      }
    } else {
      notification.error({
        message: "Reply not added",
        description: "Could not find parent comment in order to reply",
      });
    }
  };

  onEditComment = async (newContent) => {
    const { id, taskRevision, content } = this.props;

    if (content === newContent) {
      return;
    }

    const reviewInput = await this.getUpToDateReview();

    const targetComment = findCommentRecursive({
      currentNode: reviewInput,
      activityId: id,
    });

    if (!targetComment) {
      notification.error({
        message: "Comment not edited",
        description: "Could not find comment in order to edit",
      });
    }

    targetComment.content = newContent;
    targetComment.edited = true;

    await callGraphQLSimple({
      message: "Failed to edit comment",
      queryName: "updateReview",
      variables: {
        input: {
          id: reviewInput.id,
          reviewThread: reviewInput.reviewThread,
        },
      },
    });

    let upToDateTaskRevision = (
      await callGraphQLSimple({
        message: `Failed to fetch ${getSimpleLabel("task revision")}`,
        queryName: "getTaskRevision",
        variables: {
          id: taskRevision.id,
        },
      })
    ).data.getTaskRevision;

    if (upToDateTaskRevision) {
      await callGraphQLSimple({
        message: "Failed to edit comment",
        queryName: "updateTaskRevision",
        variables: {
          input: {
            id: taskRevision.id,
            randomNumber: Math.floor(Math.random() * 100000),
          },
        },
      });
    }
  };

  onEditAnnotation = async (newContent) => {
    const { id, taskRevision, content } = this.props;

    let parsedContent = JSON.parse(content);

    if (parsedContent.content === newContent) {
      return;
    }

    const reviewInput = await this.getUpToDateReview();

    const targetAnnotation = reviewInput.reviewThread.find((x) => x.id === id);

    parsedContent.content = newContent;

    if (!targetAnnotation) {
      notification.error({
        message: "Annotation not edited",
        description: "Could not find annotation in order to edit",
      });
    }

    targetAnnotation.edited = true;
    targetAnnotation.content = JSON.stringify(parsedContent);

    await callGraphQLSimple({
      message: "Failed to edit comment",
      queryName: "updateReview",
      variables: {
        input: {
          id: reviewInput.id,
          reviewThread: reviewInput.reviewThread,
        },
      },
    });

    let upToDateTaskRevision = (
      await callGraphQLSimple({
        message: `Failed to fetch ${getSimpleLabel("task revision")}`,
        queryName: "getTaskRevision",
        variables: {
          id: taskRevision.id,
        },
      })
    ).data.getTaskRevision;

    if (upToDateTaskRevision) {
      await callGraphQLSimple({
        message: "Failed to edit comment",
        queryName: "updateTaskRevision",
        variables: {
          input: {
            id: taskRevision.id,
            randomNumber: Math.floor(Math.random() * 100000),
          },
        },
      });
    }

    if (this.props.refreshDrawing) {
      setTimeout(this.props.refreshDrawing, 1000);
    }
  };

  onItemClick = (e) => {
    e.stopPropagation();

    if (this.canBeViewed()) {
      this.reveal();
    }
  };

  onMouseEnter = (e) => {
    e.stopPropagation();
    if (
      this.props.onHoverStart &&
      (this.props.type === "ANNOTATION_TEXT" || this.props.type === "ANNOTATION_LEADER_LINE")
    ) {
      let parsedContent = JSON.parse(this.props.content);
      this.props.onHoverStart(parsedContent.id);
    }
  };

  onMouseLeave = (e) => {
    e.stopPropagation();
    if (this.props.onHoverEnd) {
      this.props.onHoverEnd();
    }
  };

  reveal = () => {
    const { content, file, zoom } = this.props;
    try {
      if (!HAS_SHEETS[file.type]) {
        const parsedContent = JSON.parse(content);

        let selector;
        if (parsedContent.type === "TEXT") {
          selector = `[data-annotation-id='${parsedContent.id}'].annotation-textbox`;
        } else {
          selector = `[data-annotation-id='${parsedContent.id}'] .annotation-textbox`;
        }

        const annotationElement = document.querySelector(selector);
        const annotationElementTop = annotationElement.offsetTop;
        const offsetFromScreenTop = 200;
        const targetScroll = annotationElementTop * zoom - offsetFromScreenTop;
        const container = document.querySelector(".drawing-container");
        const containerTop = container.scrollTop;
        const distance = targetScroll - containerTop;
        const duration = Math.max(Math.min(2, distance / 7000), 0.5);
        gsap.to(".drawing-container", {
          scrollTop: targetScroll,
          duration,
          ease: "power4.inOut",
        });
      }
    } catch (e) {
      // not much to do, reveal failed for some reason
      console.error("Reveal failed:", e);
    }
  };

  displayReviewThread = () => {
    const {
      reviewThread,
      apiUser,
      review,
      users,
      taskRevision,
      file,
      zoom,
      includeResolved,
      viewDocumentForComment,
      historicalVersionTimestamp,
      reviewIsActive,
      task,
      highlightedAnnotationId,
      request,
    } = this.props;

    return (
      <div className="review-reply-list">
        {reviewThread
          .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
          .map((item, i) => {
            return (
              <ReviewThreadItem
                {...item}
                task={task}
                viewDocumentForComment={viewDocumentForComment}
                includeResolved={includeResolved}
                key={`${item.id}-${i}`}
                minimalistic
                users={users}
                apiUser={apiUser}
                review={review}
                taskRevision={taskRevision}
                file={file}
                revisionAuthor={taskRevision.author}
                revisionReviewer={taskRevision.checkedBy}
                zoom={zoom}
                request={request}
                historicalVersionTimestamp={historicalVersionTimestamp}
                reviewIsActive={reviewIsActive}
                highlightedAnnotationId={highlightedAnnotationId}
              />
            );
          })}
      </div>
    );
  };

  canBeViewed = () => {
    const { type, file } = this.props;
    return file && !HAS_SHEETS[file.type] && ["ANNOTATION_TEXT", "ANNOTATION_LEADER_LINE"].includes(type);
  };

  displayMoreMenu = () => {
    const { viewDocumentForComment } = this.props;

    return (
      <Menu>
        <>
          <Menu.Item
            key="view-at-timestamp"
            onClick={() => viewDocumentForComment(this.props)}
            disabled={!viewDocumentForComment}
          >
            View document on this date
          </Menu.Item>
        </>
      </Menu>
    );
  };

  displayActionButtons = ({ canEdit, canReply, canResolve }) => {
    const { type, reviewIsActive } = this.props;
    const { isEditing } = this.state;

    if (!["COMMENT", "ANNOTATION_TEXT", "ANNOTATION_LEADER_LINE"].includes(type)) {
      return null;
    }

    let actionButtons = [];

    if (isEditing) {
      actionButtons.push(
        <Button
          key="save"
          type="primary"
          className="finish-editing-button"
          data-cy="comment-finish-editing-button"
          onClick={() => {
            this.setState({ isEditing: false });
          }}
        >
          Finish editing
        </Button>
      );
    } else {
      if (canEdit && reviewIsActive) {
        actionButtons.push(
          <Tooltip title="Edit" placement="topRight" mouseEnterDelay={0.5}>
            <Button
              icon={<EditOutlined />}
              className="edit-button"
              data-cy="comment-edit-button"
              onClick={() => {
                this.setState({ isEditing: true });
              }}
            />
          </Tooltip>
        );
      }
      if (canReply && reviewIsActive) {
        actionButtons.push(
          <Tooltip title="Reply" placement="topRight" mouseEnterDelay={0.5}>
            <Button
              icon={<ReplyIcon />}
              className="reply-button"
              data-cy="comment-reply-button"
              onClick={() => this.setState({ isCommentBoxVisible: true })}
            />
          </Tooltip>
        );
      }

      if (canResolve && reviewIsActive) {
        actionButtons.push(
          <Tooltip title="Mark comment as resolved" placement="topRight" mouseEnterDelay={0.5}>
            <Button
              icon={<CheckOutlined />}
              className="mark-as-resolved"
              data-cy="comment-resolve-button"
              onClick={this.resolveComment}
            />
          </Tooltip>
        );
      }

      actionButtons.push(
        <Tooltip title="View document on this date" placement="topRight" arrowPointAtCenter>
          <Button
            icon={<EyeOutlined />}
            className="more"
            data-cy="comment-more-button"
            onClick={() => this.props.viewDocumentForComment(this.props)}
            disabled={!this.props.viewDocumentForComment}
          />
        </Tooltip>
      );
    }

    return <div className="actions">{actionButtons}</div>;
  };

  render() {
    const {
      type,
      createdAt,
      content,
      author,
      users,
      resolved,
      resolvedBy,
      resolvedAt,
      edited,
      sheetLabel,
      revisionAuthor,
      revisionReviewer,
      reviewThread,
      taskRevision,
      compact,
      minimalistic,
      elementRef,
      apiUser,
      includeResolved,
      historicalVersionTimestamp,
      reviewIsActive,
      task,
      isFromExternalReview,
    } = this.props;

    if (includeResolved === false && resolved) {
      return null;
    }

    if (!users) {
      return null;
    }

    const { isEditing, isCommentBoxVisible } = this.state;

    let title = null;
    let contentToDisplay = content;
    let contentForCypress = content;
    let tag = null;
    let canReply = false;
    let canEdit = false;
    let canResolve = !resolved && (revisionReviewer === apiUser.id || author === apiUser.id);

    let resolvedMarker = null;
    if (resolved) {
      let resolvedByDetails = users.find((x) => x.id === resolvedBy);
      const resolvedAtDetails = resolvedAt && moment(resolvedAt).fromNow(false);

      resolvedMarker = (
        <span className="resolved-marker" data-cy="resolved-marker">
          <CheckCircleFilled /> resolved {resolvedBy && <>by {resolvedByDetails?.firstName}</>}{" "}
          {resolvedAtDetails && <>{resolvedAtDetails}</>}
        </span>
      );
    }

    const extraProps = {};
    if (elementRef) {
      extraProps.ref = elementRef;
    } else {
      extraProps.ref = this.ownElementRef;
    }

    if (!minimalistic) {
      if (revisionAuthor === author) {
        tag = <Tag className="author-tag">author</Tag>;
      } else if (revisionReviewer === author) {
        tag = <Tag className="author-tag">reviewer</Tag>;
      }
    }

    let authorDetails = null;
    let authorDisplayName = null;
    if (author) {
      authorDetails = users.find((x) => x.id === author);
      authorDisplayName = `${authorDetails?.firstName} ${authorDetails?.lastName}`;
    }

    const { match, sheetId } = this.props;
    let linkPath = "";

    if (match && sheetId) {
      linkPath = `${match.url}?tab=${sheetId}`;
    }

    let wasCreatedBeforeRichTextIntroduction = false;

    if (createdAt < "2024-10-21") {
      wasCreatedBeforeRichTextIntroduction = true;
    }

    let input;

    switch (type) {
      case "START":
        title = (
          <span className="title">
            <b>Review started</b>
          </span>
        );
        break;

      case "REVIEWER_SET":
      case "REVIEWER_CHANGE":
        title = (
          <span className="title">
            <b>Reviewer {type === "REVIEWER_SET" ? "set" : "changed"}</b>
          </span>
        );
        let newReviewerDetails = users.find((x) => x.id === content);

        contentToDisplay = (
          <span className="static-content">
            {newReviewerDetails?.firstName} {newReviewerDetails?.lastName} is
            {type === "REVIEWER_SET" ? " " : " now "}
            the reviewer
          </span>
        );
        break;

      case "COMMENT":
        canReply = true;
        canEdit = author === apiUser.id;

        title = (
          <span className="title">
            <b>{authorDisplayName}</b>{" "}
            <span className="activity-item-type">{minimalistic ? "replied" : "commented"}</span>{" "}
            <Link to={linkPath}>{!compact && !minimalistic && sheetLabel ? ` on ${sheetLabel}` : ""}</Link>
            {!compact && !minimalistic && tag}
          </span>
        );

        if (wasCreatedBeforeRichTextIntroduction) {
          input = (
            <Input
              defaultValue={content}
              className={cx({ resolved })}
              fullWidth
              disabled={!isEditing}
              multiLine
              onChange={this.onEditComment}
            />
          );
        } else {
          try {
            let parsedContent = undefined;
            if (content) {
              parsedContent = JSON.parse(content);
            }
            input = (
              <Textarea
                defaultValue={parsedContent}
                hideToolbar={!isEditing}
                includeImageFormatting={false}
                basicFormattingOnly
                includeAlignmentButtons={false}
                includeWarningAboutLargeAttachments={false}
                extraToolbarButtons={["attachment"]}
                noBorder
                noPadding
                minHeight={16}
                disabled={!isEditing}
                debouncedOnChange={this.onEditComment}
              />
            );
          } catch (e) {
            input = (
              <Input
                defaultValue={content}
                className={cx({ resolved })}
                fullWidth
                disabled={author !== apiUser.id}
                multiLine
                onChange={this.onEditComment}
              />
            );
          }
        }

        contentToDisplay = (
          <>
            {resolvedMarker}
            {input}
          </>
        );
        break;
      case "ANNOTATION_LEADER_LINE":
      case "ANNOTATION_TEXT":
        canReply = true;
        canEdit = author === apiUser.id;

        title = (
          <span className="title">
            <b>{authorDisplayName}</b> annotated
            <Link to={linkPath}>{!compact && !minimalistic && sheetLabel ? ` ${sheetLabel}` : ""}</Link>
            {!compact && !minimalistic && tag}
          </span>
        );
        let parsedContent = JSON.parse(content);
        contentForCypress = parsedContent.content;
        contentToDisplay = (
          <>
            {resolvedMarker}
            <Input
              defaultValue={parsedContent.content}
              className={cx({ resolved })}
              fullWidth
              multiLine
              onChange={this.onEditAnnotation}
              disabled={!isEditing}
            />
          </>
        );

        break;

      case "STATUS_CHANGE":
        contentToDisplay = null;
        switch (content) {
          case "WITH_COMMENTS":
            title = (
              <span className="title">
                <b>{authorDisplayName}</b>
              </span>
            );
            contentToDisplay = (
              <span className="static-content">
                Review Submitted: <b>with comments</b>
              </span>
            );

            break;
          case "CHANGES_REQUESTED":
            title = (
              <span className="title">
                <b>{authorDisplayName}</b>
              </span>
            );
            contentToDisplay = (
              <span className="static-content">
                Review Submitted: <b>changes requested</b>
              </span>
            );
            break;
          case "SUCCESS":
            title = (
              <span className="title">
                <b>{authorDisplayName}</b>
              </span>
            );
            contentToDisplay = (
              <span className="static-content">
                Review Submitted: <b>approved</b>
              </span>
            );
            break;
          case "CLOSED":
            title = (
              <span className="title">
                <b>{authorDisplayName}</b>
              </span>
            );
            contentToDisplay = <span className="static-content">Cancelled the review</span>;
            break;
          case "IN_PROGRESS":
            title = (
              <span className="title">
                <b>{authorDisplayName}</b>
              </span>
            );
            contentToDisplay = <span className="static-content">Reopened the review</span>;
            break;
          default:
        }
        break;

      default:
        break;
    }

    let reviewResult = null;
    if (type === "STATUS_CHANGE") {
      reviewResult = content;
    }

    let timestamp = moment(createdAt).format("DD MMMM YYYY HH:mm");

    if (edited) {
      timestamp = `(Edited) ${timestamp}`;
    }

    let externalReviewTag = null;
    if (isFromExternalReview) {
      externalReviewTag = (
        <Tag className="external-review-tag" color="#ffbc0d">
          From external review
        </Tag>
      );
    }

    let isHighlighted = false;

    if (historicalVersionTimestamp === createdAt || this.state.isHighlightedThroughHover) {
      isHighlighted = true;
    }

    let mainContent = (
      <div
        onClick={this.onItemClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        className={cx("review-thread-item", "item", reviewResult, type, {
          compact,
          minimalistic,
          highlighted: isHighlighted,
          viewable: this.canBeViewed(),
        })}
        data-cy="comment-item"
        data-content={contentForCypress}
        {...extraProps}
      >
        <Card>
          <div className="main-content">
            <div className="header">
              <Avatar user={authorDetails || "draughthub"} />

              <div className="author-and-timestamp">
                {externalReviewTag}
                <Typography.Paragraph className="item-title">{title}</Typography.Paragraph>
                <Typography.Text className="timestamp">{timestamp}</Typography.Text>
              </div>
              {this.displayActionButtons({ canEdit, canReply, canResolve })}
            </div>

            <div className="item-content">{contentToDisplay && <pre className="content">{contentToDisplay}</pre>}</div>
          </div>
          {isCommentBoxVisible ? (
            <ReviewCommentBox
              users={users}
              task={task}
              apiUser={apiUser}
              onSubmitComment={this.submitReply}
              userIsReviewer={revisionReviewer}
              userIsAuthor={revisionAuthor}
              taskRevision={taskRevision}
              request={this.props.request}
              isReply
            />
          ) : null}
        </Card>
      </div>
    );

    return (
      <>
        {this.canBeViewed() ? (
          <Tooltip title="Scroll to comment" placement="left" arrowPointAtCenter>
            {mainContent}
          </Tooltip>
        ) : (
          mainContent
        )}

        {reviewThread ? this.displayReviewThread() : null}
      </>
    );
  }
}

export default withRouter(ReviewThreadItem);
