import { useState, useEffect } from "react";
import moment from "moment";
import { withRouter, Link } from "react-router-dom";
import cx from "classnames";
import { Button, Empty, Modal, Breadcrumb, Typography, Alert, Table, message, notification } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { LexoRank } from "lexorank";
import query from "query-string";
import stringSimilarity from "string-similarity";
import { EditOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { computePublishStatus, publish, performSheetOperation, downloadSheetPDF } from "common/helpers";
import { callRest, callGraphQLSimple } from "common/apiHelpers";
import { annotate } from "ReviewPage/reviewHelpers";
import { getLatestFileVersion, HAS_SHEETS, KEY_TYPES, encodeKey } from "common/shared";
import { FILE_TYPES_READABLE, DEFAULT_FILE_NAMES, SHEET_NAMES_TO_IGNORE } from "common/constants";
import { getSimpleLabel } from "common/labels";

import Card from "Card/Card";
import CopyLinkButton from "CopyLinkButton/CopyLinkButton";
import CreateSheetRevisionModal from "CreateSheetRevisionModal/CreateSheetRevisionModal";
import CreateExternalReferenceModal from "Modals/CreateExternalReferenceModal/CreateExternalReferenceModal";
import EditSheetsModal from "Modals/EditSheetsModal/EditSheetsModal";
import SheetDetails from "./SheetDetails/SheetDetails";
import SheetListItem from "./SheetListItem/SheetListItemNew";
import UploadCustomFileModal from "Modals/UploadCustomFileModal/UploadCustomFileModal";
import TaskIdTag from "TaskIdTag/TaskIdTag";
import FileSidebar from "./FileSidebar/FileSidebar";
// import DismissableBanner from "DismissableBanner/DismissableBanner";

import "./FileDetailsPage.scss";

export function FileDetailsPage({
  task,
  file,
  apiUser,
  users,
  user,
  history,
  location,
  tasks,
  organisationDetails,
  setProps,
  context,
  setBoxedLayout,
  windowWidth,
  projects,
  fetchAndSetAsyncJobs,
  asyncJobs,
}) {
  const [isCreateSheetRevisionModalOpen, setIsCreateSheetRevisionModalOpen] = useState(false);
  const [isEditSheetsModalOpen, setIsEditSheetsModalOpen] = useState(false);

  const [isCreateExternalReferenceModalOpen, setIsCreateExternalReferenceModalOpen] = useState(false);
  const [isUploadCustomFileModalOpen, setIsUploadCustomFileModalOpen] = useState(false);
  const [selectedSheet, setSelectedSheet] = useState();
  const [selectedSheetIndex, setSelectedSheetIndex] = useState();
  const [mainFileVersions, setMainFileVersions] = useState([]);
  const [numberForPreviewRefresh, setNumberForPreviewRefresh] = useState(0);

  const latestFileVersion = getLatestFileVersion(file);
  const latestFileVersionPublishStatus = computePublishStatus(latestFileVersion);
  const taskRevision = task.revisions.items.find((x) => x.id === file.taskRevisionId);

  const taskPath = `/tasks/${task.id}`;
  const basePath = `${taskPath}/${file.type}/${file.id}`;
  const sheets = file.sheets.items || [];

  const queryString = query.parse(location.search);
  const activeTab = queryString.tab;
  const project = projects.find((project) => project.id === task.projectId);

  const asyncJobsForFile = asyncJobs?.filter((asyncJob) => asyncJob.fileVersionId === latestFileVersion?.id);

  const inProgressOrPendingAsyncJobsForFile = asyncJobsForFile?.filter((asyncJob) => {
    return ["IN_PROGRESS", "PENDING"].includes(asyncJob.status);
  });

  useEffect(() => {
    fetchAndSetAsyncJobs({
      organisation: file.organisation,
      fileVersionId: latestFileVersion?.id,
      limit: 20,
    });
  }, [file?.id]); // eslint-disable-line

  useEffect(() => {
    const messageKey = "generating-pdf";
    if (inProgressOrPendingAsyncJobsForFile?.length > 0) {
      message.loading({
        content: "We're generating a new PDF",
        duration: 0,
        key: messageKey,
      });
    } else {
      message.destroy(messageKey);
    }

    return () => {
      message.destroy(messageKey);
    };
  }, [inProgressOrPendingAsyncJobsForFile]);

  useEffect(() => {
    setBoxedLayout(false);

    if (!activeTab && file.sheets?.items?.length > 0) {
      history.replace(`${basePath}?tab=${file.sheets.items[0].id}`);
    }

    fetchMainFileVersions();

    callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: task.id,
          projectId: task.projectId,
          fileId: file.id,
          clientId: task.clientId,
          page: "FILE",
          type: "PAGE_VIEW",
          fileType: file.type,
          fileName: file.name,
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });

    runSanityChecks();

    return () => {
      setBoxedLayout(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function runSanityChecks() {
    await checkLatestFileVersionNumberMatchesS3();
  }

  async function checkLatestFileVersionNumberMatchesS3() {
    const paramsForEncodeKey = {
      task,
      file,
      organisation: apiUser.organisation,
      projectId: task.projectId,
      taskId: task.id,
      clientInitials: task.client?.initials || "",
      projectInitials: task.project?.initials || "",
      taskInitials: task.initials,
      fileId: file.id,
      taskRevisionName: taskRevision.name,
      fileType: file.type,
    };

    let folderKey =
      (await encodeKey({
        type: KEY_TYPES.FILE_FOLDER,
        data: paramsForEncodeKey,
      })) + "/";

    let versionFoldersResponse = await callRest({
      method: "GET",
      route: `/s3-list?prefix=${btoa(folderKey)}&withDelimiter=true`,
      includeCredentials: false,
    });

    let versionFolderNumbers = versionFoldersResponse.CommonPrefixes.map((x) =>
      parseInt(
        x.Prefix.substring(0, x.Prefix.length - 1)
          .split("/")
          .pop()
      )
    ).filter((x) => !isNaN(x));

    let highestVersionNumberInS3 = Math.max(...versionFolderNumbers);

    let highestVersionNumberInDatabase = file.versions.items.length - 2;

    if (highestVersionNumberInS3 < highestVersionNumberInDatabase) {
      notification.error({
        message: (
          <>
            There is a mismatch between the latest file version number in the database ({highestVersionNumberInDatabase}
            ) and the latest version number in the cloud storage ({highestVersionNumberInS3}). <br />
            <b>Please contact DraughtHub support.</b>
          </>
        ),
        duration: 0,
      });
    }

    // copy main file from the highest version number in S3 to a new folder that has a number equal to the file version count in the database

    // console.log("versionFolderNumbers", versionFolderNumbers);
    // console.log("versionFoldersResponse", versionFoldersResponse);
  }

  async function fetchMainFileVersions() {
    const listVersionsResponse = await callRest({
      method: "GET",
      route: `/s3-list-versions?prefix=${btoa(latestFileVersion.key)}&withDelimiter=false`,
      includeCredentials: false,
    });
    setMainFileVersions(listVersionsResponse.Versions);
  }

  const { publishError } = file.versions.items.slice(-1)[0];

  function refreshPreview() {
    setNumberForPreviewRefresh(numberForPreviewRefresh + 1);
  }

  // async function renameSheetInDraughtHubOnly({ file, oldSheetName, newSheetName }) {
  //   const sheet = file.sheets.items.find((x) => x.name === oldSheetName);
  //   await callGraphQLSimple({
  //     message: "Failed to rename sheet in DraughtHub",
  //     mutation: "updateSheet",
  //     variables: {
  //       input: {
  //         id: sheet.id,
  //         name: newSheetName,
  //       },
  //     },
  //   });
  //   const updatedFile = (
  //     await callGraphQLSimple({
  //       message: "Failed to update file",
  //       queryCustom: "updateFile",
  //       variables: {
  //         input: {
  //           id: file.id,
  //           itemSubscription: Math.floor(Math.random() * 100000),
  //         },
  //       },
  //     })
  //   ).data.updateFile;
  //   triggerPublish(updatedFile);
  // }

  async function triggerAnnotate() {
    await annotate({
      task,
      fileId: file.id,
      taskRevisionId: taskRevision.id,
      fileVersion: latestFileVersion,
      fileType: file.type,
      apiUser,
      customId: latestFileVersion.customId,
      sheetNames: file.sheets.items.filter((sheet) => sheet.includeInPublish !== false).map((sheet) => sheet.name),
    });

    refreshPreview();
  }

  async function deleteSheetInDraughtHubOnly({ file, sheetName }) {
    try {
      await new Promise((resolve, reject) => {
        let modal = Modal.confirm({
          title: "Confirm delete sheet",
          content: (
            <Typography.Text>Are you sure you want to delete sheet {sheetName} from DraughtHub?</Typography.Text>
          ),

          onOk: () => {
            modal.destroy();
            resolve();
          },
          onCancel: () => {
            modal.destroy();
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, the user clicked "cancel"
      return;
    }

    const sheet = file.sheets.items.find((x) => x.name === sheetName);
    await callGraphQLSimple({
      message: "Failed to delete sheet from DraughtHub",
      mutation: "deleteSheet",
      variables: {
        input: {
          id: sheet.id,
        },
      },
    });
    const updatedFile = (
      await callGraphQLSimple({
        message: "Failed to update file",
        queryCustom: "updateFile",
        variables: {
          input: {
            id: file.id,
            itemSubscription: Math.floor(Math.random() * 100000),
          },
        },
      })
    ).data.updateFile;
    triggerPublish(updatedFile);
  }

  async function moveSheetInApi({ sheetId, order }) {
    setProps({
      context: {
        ...context,
        file: {
          ...file,
          sheets: {
            items: [...file.sheets.items]
              .map((sheet) => {
                if (sheet.id !== sheetId) {
                  return sheet;
                }
                return {
                  ...sheet,
                  order,
                };
              })
              .sort((a, b) => (a.order < b.order ? -1 : 1)),
          },
        },
      },
    });
    await callGraphQLSimple({
      message: "Failed to update sheet",
      queryName: "updateSheet",
      variables: {
        input: {
          id: sheetId,
          order,
        },
      },
    });
    triggerPublish();
    await updateFileRandomNumber();
  }

  async function triggerPublish(updatedFile, options = {}) {
    let hasChangedSincePublish = latestFileVersion
      ? latestFileVersion.savedAt && latestFileVersion.savedAt > latestFileVersion.publishStartAt
      : false;

    if (!options.force && !hasChangedSincePublish && !window.Cypress) {
      try {
        await new Promise((resolve, reject) => {
          Modal.confirm({
            icon: null,
            title: "The file has not changed since the last publish",
            maskClosable: true,
            content: <>Do you still want to publish it again?</>,
            onOk: async () => {
              resolve();
            },
            onCancel: () => {
              reject();
            },
          });
        });
      } catch (e) {
        // nothing, it just means the user selected "cancel"
        return;
      }
    }

    await publish({
      file: updatedFile || file,
      fileVersionId: getLatestFileVersion(file).id,
      taskRevisionId: taskRevision.id,
      task,
    });
  }

  async function removeExternalReference(reference) {
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm delete Xref",
          className: "delete-external-reference-modal",
          maskClosable: true,
          content: (
            <>
              Are you sure you want to delete the Xref pointing to <b>{reference.taskId}</b>?
            </>
          ),
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    await performSheetOperation({
      operation: "REMOVE_EXTERNAL_REFERENCES",
      organisationDetails,
      extraParams: {
        externalReferences: [reference],
        latestFileVersion,
      },
      apiUser,
      task,
      file,
      taskRevision,
      history,
    });
  }

  async function updateFileRandomNumber() {
    await callGraphQLSimple({
      message: "Could not update file",
      queryName: "updateFile",
      variables: {
        input: {
          id: file.id,
          randomNumber: Math.floor(Math.random() * 1000000),
        },
      },
    });
  }

  async function changeCustomId(customId) {
    if (customId === latestFileVersion.customId) {
      return;
    }

    await callGraphQLSimple({
      message: "Failed to update custom ID",
      queryName: "updateFileVersion",
      variables: {
        input: {
          id: latestFileVersion?.id,
          customId,
        },
      },
    });

    updateFileRandomNumber();

    const updatedFile = (
      await callGraphQLSimple({
        message: "Failed to retrieve file",
        queryName: "getFile",
        variables: {
          id: file.id,
        },
      })
    ).data.getFile;

    triggerAnnotate(updatedFile);
  }

  function displaySheetNames() {
    return sheets.map((sheet, index) => {
      let sheetListItem = (
        <SheetListItem
          key={sheet.id}
          sheets={sheets}
          sheet={sheet}
          file={file}
          task={task}
          apiUser={apiUser}
          taskRevision={taskRevision}
          selected={activeTab === sheet.id}
          history={history}
          basePath={basePath}
          fileIsInQueue={latestFileVersion?.processingStatus === "IN_QUEUE"}
          organisationDetails={organisationDetails}
        />
      );

      if (taskRevision.isReadOnly) {
        return sheetListItem;
      }

      return (
        <Draggable
          key={sheet.id}
          draggableId={sheet.id}
          index={index}
          className={`draggable-sheet ${sheet.name.split(" ").join("")}`}
        >
          {(provided) => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              {sheetListItem}
            </div>
          )}
        </Draggable>
      );
    });
  }

  function displaySheetRoutes() {
    let activeSheet;
    let activeSheetIndex;
    for (let i = 0; i < sheets.length; i++) {
      const sheet = sheets[i];
      if (activeTab === sheet.id) {
        activeSheet = sheet;
        activeSheetIndex = i;
      }
    }

    if (!activeSheet) {
      activeSheet = sheets[0];
      activeSheetIndex = 0;
    }

    return (
      <SheetDetails
        key={activeSheet.id}
        apiUser={apiUser}
        sheet={activeSheet}
        users={users}
        sheetIndex={activeSheetIndex}
        task={task}
        file={file}
        taskRevision={taskRevision}
        setSelectedSheet={setSelectedSheet}
        setSelectedSheetIndex={setSelectedSheetIndex}
        openCreateSheetRevisionModal={() => setIsCreateSheetRevisionModalOpen(true)}
        organisationDetails={organisationDetails}
        triggerPublish={triggerPublish}
        downloadSheetPDF={downloadSheetPDF}
        windowWidth={windowWidth}
        inProgressOrPendingAsyncJobsForFile={inProgressOrPendingAsyncJobsForFile}
        numberForPreviewRefresh={numberForPreviewRefresh}
        triggerAnnotate={triggerAnnotate}
      />
    );
  }

  async function onDragEnd(result) {
    // item dropped outside the list
    if (!result.destination) {
      return;
    }

    const newIndex = result.destination.index;

    let sortedSheets = file.sheets.items;
    let destinationOrder = LexoRank.parse(sortedSheets[newIndex].order);

    let newOrder;
    if (result.destination.index === result.source.index) {
      // no movement needed
      return;
    } else if (newIndex === 0) {
      newOrder = destinationOrder.genPrev();
    } else if (result.destination.index < result.source.index) {
      sortedSheets = file.sheets.items.filter((x) => x.id !== result.draggableId);
      destinationOrder = LexoRank.parse(sortedSheets[newIndex].order);
      let beforeSheetOrder = LexoRank.parse(sortedSheets[newIndex - 1].order);
      newOrder = destinationOrder.between(beforeSheetOrder);
    } else if (newIndex === sortedSheets.length - 1) {
      newOrder = destinationOrder.genNext();
    } else {
      let afterSheetOrder = LexoRank.parse(sortedSheets[newIndex + 1].order);
      newOrder = afterSheetOrder.between(destinationOrder);
    }

    await moveSheetInApi({
      sheetId: result.draggableId,
      order: newOrder.value,
    });
  }

  function displaySheets() {
    if (!file.sheets.items || file.sheets.items.length === 0) {
      return <Empty />;
    }

    if (HAS_SHEETS[file.type]) {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="tabs">
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  className={cx("sheet-names", "sheet-names-desktop")}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <Button
                    className="edit-sheets"
                    icon={<EditOutlined />}
                    type="primary"
                    onClick={() => setIsEditSheetsModalOpen(true)}
                    disabled={taskRevision.isReadOnly || latestFileVersion?.processingStatus === "IN_QUEUE"}
                  >
                    Edit sheets
                  </Button>

                  {displaySheetNames()}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {displaySheetRoutes()}
          </div>
        </DragDropContext>
      );
    } else {
      return (
        <SheetDetails
          apiUser={apiUser}
          sheet={file.sheets.items[0]}
          key={file.sheets.items[0].id}
          sheetIndex={0}
          task={task}
          file={file}
          users={users}
          taskRevision={taskRevision}
          setSelectedSheet={setSelectedSheet}
          setSelectedSheetIndex={setSelectedSheetIndex}
          openCreateSheetRevisionModal={() => setIsCreateSheetRevisionModalOpen(true)}
          organisationDetails={organisationDetails}
          downloadSheetPDF={downloadSheetPDF}
          windowWidth={windowWidth}
          triggerPublish={triggerPublish}
          triggerAnnotate={triggerAnnotate}
        />
      );
    }
  }

  async function restorePreviousVersion() {
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm restore",
          maskClosable: true,
          content: <>Are you sure you want to restore the previous version of this file?</>,
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    await callRest({
      method: "POST",
      route: `/restore-previous-version-corrupted`,
      includeCredentials: false,
      body: {
        key: latestFileVersion.key,
        versionId: mainFileVersions[0].VersionId,
      },
    });

    await triggerPublish();
  }

  function displayDifferentSheetsAlert() {
    if (
      !HAS_SHEETS[file.type] ||
      !file.sheetsInApplication?.length ||
      file.versions.items.slice(-1)[0].processingStatus === "IN_QUEUE"
    ) {
      return null;
    }
    const sheetsInApplicationNames =
      file.sheetsInApplication
        ?.map((sheet) => sheet.name)
        .filter((sheetName) => !SHEET_NAMES_TO_IGNORE.includes(sheetName))
        .sort() || [];
    const sheetsInDraughtHubNames = file.sheets?.items.map((sheet) => sheet.name).sort();
    // const sheetsInDraughtHubNames = ["Sheet 1", "Sheet2"];

    let missingSheets = [];

    for (let sheetName of sheetsInDraughtHubNames) {
      if (!sheetsInApplicationNames.includes(sheetName)) {
        missingSheets.push(sheetName);
      }
    }

    if (missingSheets.length === 0) {
      return null;
    }

    let longestListLength = Math.max(sheetsInApplicationNames.length, sheetsInDraughtHubNames.length);
    const diffTableRows = [];
    for (let i = 0; i < longestListLength; i++) {
      let draughtHubSheetName = sheetsInDraughtHubNames[i];
      let closestMatch;
      let isDraughtHubSheetWithoutMatch = false;
      if (draughtHubSheetName) {
        let sheetsInApplicationWithoutMatches = sheetsInApplicationNames.filter(
          (sheetName) => !sheetsInDraughtHubNames.includes(sheetName)
        );
        let matches;
        if (sheetsInApplicationWithoutMatches.length > 0) {
          matches = stringSimilarity.findBestMatch(draughtHubSheetName, sheetsInApplicationNames);
        }
        if (matches) {
          closestMatch = matches.bestMatch.target;
        }
        if (missingSheets.includes(draughtHubSheetName)) {
          isDraughtHubSheetWithoutMatch = true;
        }
      }

      diffTableRows.push({
        draughtHubSheet: draughtHubSheetName,
        applicationSheet: sheetsInApplicationNames[i],
        closestMatch,
        isDraughtHubSheetWithoutMatch,
      });
    }

    return (
      <Card
        className="sheet-diff-alert"
        withSpace
        title={`
            Some sheet names in the ${
              FILE_TYPES_READABLE[file.type]
            } file are different from the ones stored in DraughtHub. This leads to publish issues.`}
      >
        <div className="stacked-content">
          <Table
            className="not-interactive"
            dataSource={diffTableRows}
            columns={[
              {
                title: "Sheets in DraughtHub",
                dataIndex: "draughtHubSheet",
                render: (sheetName) => {
                  return (
                    <Typography.Text
                      key={sheetName}
                      className={cx("sheet-item", {
                        missing: missingSheets.includes(sheetName),
                      })}
                    >
                      {sheetName}
                    </Typography.Text>
                  );
                },
              },
              {
                title: `Sheets in ${FILE_TYPES_READABLE[file.type]}`,
                render: (_, row) => <Typography.Text>{row.applicationSheet}</Typography.Text>,
              },
            ]}
            pagination={{ hideOnSinglePage: true, pageSize: 500 }}
          />
          {/* <Table
            className="not-interactive"
            dataSource={diffTableRows.filter((x) => x.isDraughtHubSheetWithoutMatch)}
            title={() =>
              `The following sheets from DraughtHub do not have a match in ${FILE_TYPES_READABLE[file.type]}:`
            }
            columns={[
              {
                title: "DraughtHub sheet",
                render: (_, row) => {
                  return (
                    <>
                      <Typography.Text key={row.draughtHubSheet} className="sheet-item missing">
                        {row.draughtHubSheet}
                      </Typography.Text>
                    </>
                  );
                },
              },
              {
                title: `Closest match in ${FILE_TYPES_READABLE[file.type]}`,
                render: (_, row) => {
                  if (!row.closestMatch) {
                    return "-";
                  }

                  return <Typography.Text className="sheet-item closest-match">{row.closestMatch}</Typography.Text>;
                },
              },
              {
                title: "Actions",
                render: (_, row) => {
                  return (
                    <div className="actions">
                      <Dropdown
                        trigger="click"
                        placement="bottomRight"
                        overlayClassName="sheet-diff-resolution-options"
                        overlay={
                          <Menu>
                            {row.closestMatch && (
                              <Menu.Item
                                key="rename"
                                onClick={() =>
                                  renameSheetInDraughtHubOnly({
                                    file,
                                    oldSheetName: row.draughtHubSheet,
                                    newSheetName: row.closestMatch,
                                  })
                                }
                              >
                                <Typography.Text>
                                  Rename{" "}
                                  <Typography.Text key={row.draughtHubSheet} className="sheet-item missing">
                                    {row.draughtHubSheet}
                                  </Typography.Text>{" "}
                                  to{" "}
                                  <Typography.Text className="sheet-item closest-match">
                                    {row.closestMatch}
                                  </Typography.Text>{" "}
                                  in DraughtHub
                                </Typography.Text>
                              </Menu.Item>
                            )}
                            <Menu.Divider />

                            <Menu.Item
                              key="Add"
                              onClick={() =>
                                openModalForAddingSheetToApplicationOnly({
                                  sheetName: row.draughtHubSheet,
                                  missingSheets,
                                })
                              }
                            >
                              Add{" "}
                              <Typography.Text key={row.draughtHubSheet} className="sheet-item missing">
                                {row.draughtHubSheet}
                              </Typography.Text>{" "}
                              to {FILE_TYPES_READABLE[file.type]}
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item
                              key="delete"
                              disabled={file.sheets.items.length === 1}
                              onClick={() =>
                                deleteSheetInDraughtHubOnly({
                                  file,
                                  sheetName: row.draughtHubSheet,
                                })
                              }
                            >
                              Delete{" "}
                              <Typography.Text key={row.draughtHubSheet} className="sheet-item missing">
                                {row.draughtHubSheet}
                              </Typography.Text>{" "}
                              from DraughtHub
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <Button type="primary">
                          Resolve <DownOutlined />
                        </Button>
                      </Dropdown>
                    </div>
                  );
                },
              },
            ]}
            pagination={{ hideOnSinglePage: true, pageSize: 500 }}
          /> */}
        </div>
      </Card>
    );
  }

  function displaySidebar({ layout }) {
    return (
      <FileSidebar
        file={file}
        organisationDetails={organisationDetails}
        taskRevision={taskRevision}
        setIsUploadCustomFileModalOpen={setIsUploadCustomFileModalOpen}
        latestFileVersion={latestFileVersion}
        removeExternalReference={removeExternalReference}
        setIsCreateExternalReferenceModalOpen={setIsCreateExternalReferenceModalOpen}
        changeCustomId={changeCustomId}
        tasks={tasks}
        task={task}
        triggerPublish={triggerPublish}
        history={history}
        taskPath={taskPath}
        setSelectedSheet={setSelectedSheet}
        setIsCreateSheetRevisionModalOpen={setIsCreateSheetRevisionModalOpen}
        apiUser={apiUser}
        publishStatus={publishStatus}
        downloadSheetPDF={downloadSheetPDF}
        splitLayout={layout.sidebarWidth === 0 && windowWidth > 700}
        project={project}
        asyncJobsForFile={asyncJobsForFile}
        inProgressOrPendingAsyncJobsForFile={inProgressOrPendingAsyncJobsForFile}
        refreshPreview={refreshPreview}
        triggerAnnotate={triggerAnnotate}
      />
    );
  }

  function displaySheetNamesMobile() {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              className={cx("sheet-names", "sheet-names-mobile")}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <Button
                className="edit-sheets"
                icon={<EditOutlined />}
                type="primary"
                onClick={() => setIsEditSheetsModalOpen(true)}
                disabled={taskRevision.isReadOnly || latestFileVersion?.processingStatus === "IN_QUEUE"}
              >
                Edit sheets
              </Button>

              {displaySheetNames()}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  const publishStatus = latestFileVersion ? latestFileVersionPublishStatus : null;

  let isOutOfDate = latestFileVersion.publishEndAt < latestFileVersion.savedAt;
  let sheetSetsWithSameDescription = {};
  for (let sheet of file.sheets.items) {
    let sheetDescription = sheet.description;
    if (!sheetSetsWithSameDescription[sheetDescription]) {
      sheetSetsWithSameDescription[sheetDescription] = [];
    }
    sheetSetsWithSameDescription[sheetDescription].push(sheet);
  }

  let hasDuplicatedSheetDescriptions;
  if (organisationDetails?.settings?.file?.sheetDescriptionsMustBeUnique) {
    hasDuplicatedSheetDescriptions = Object.values(sheetSetsWithSameDescription).some(
      (sheetSet) => sheetSet.length > 1
    );
  }

  let layout = {};

  if (windowWidth > 1600) {
    layout.sidebarWidth = "300px";
  } else if (windowWidth > 1300) {
    layout.sidebarWidth = "230px";
  } else {
    layout.sidebarWidth = 0;
  }

  return (
    <div
      className={cx("file-details-page", {
        "no-preview": !HAS_SHEETS[file.type],
      })}
    >
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/projects/${task.projectId}`}>{task.project.title}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="task-id-tag">
          <TaskIdTag task={task} />
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          {taskRevision.name} - {FILE_TYPES_READABLE[file.type]} - {file.name || DEFAULT_FILE_NAMES[file.type]}{" "}
          <CopyLinkButton />
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="inner-page-content">
        <div className="main-content">
          {taskRevision.isReadOnly ? (
            <Alert
              message={`This file belongs to a read-only ${getSimpleLabel(
                "task revision"
              )}. You cannot make changes to it.`}
              type="info"
            />
          ) : null}

          {isOutOfDate && (
            <Alert message="PDF is out of date. Please publish it again to get the latest version." type="info" />
          )}
          {hasDuplicatedSheetDescriptions && (
            <Alert
              message={
                <>
                  <span>
                    Some sheets in this file have the same title. This can lead to publish errors. Here are the sheets
                    with the same title:
                    {Object.keys(sheetSetsWithSameDescription)
                      .filter((sheetDescription) => {
                        let sheetSet = sheetSetsWithSameDescription[sheetDescription];
                        return sheetSet.length > 1;
                      })
                      .map((sheetDescription) => {
                        let sheetSet = sheetSetsWithSameDescription[sheetDescription];
                        return (
                          <>
                            <br />
                            {sheetSet.map((sheetInSet) => sheetInSet.name).join(", ")} (title is "{sheetDescription}")
                          </>
                        );
                      })}
                  </span>
                </>
              }
              type="error"
            />
          )}
          {publishError && <Alert message={`Latest publish failed to complete: ${publishError}`} type="error" />}
          {file.isCorrupted && mainFileVersions.length > 0 && (
            <Alert
              message={
                <div className="content">
                  <Typography.Text>This file has been corrupted. </Typography.Text>
                  {
                    <Button onClick={restorePreviousVersion} disabled={mainFileVersions.length <= 1}>
                      Restore previous version{" "}
                      {mainFileVersions.length > 1 && (
                        <>
                          (saved on {moment(mainFileVersions[1].LastModified).format("DD MMMM YYYY")} at{" "}
                          {moment(mainFileVersions[1].LastModified).format("HH:mm:ss")})
                        </>
                      )}
                    </Button>
                  }
                </div>
              }
              type="error"
            />
          )}
          {displayDifferentSheetsAlert()}
          {file.autoPublishDisabled && (
            <Alert
              message="We have disabled auto-publish for this file because publishing it takes a long time. Please publish it manually whenever you want a new PDF output."
              type="info"
            />
          )}

          {layout.sidebarWidth === 0 && displaySidebar({ layout })}
          <Card
            className={cx("sheets", { "with-sheets": HAS_SHEETS[file.type] })}
            title={!task.isExternalReference && displaySheetNamesMobile()}
          >
            {!task.isExternalReference && displaySheets()}
            {isCreateSheetRevisionModalOpen ? (
              <CreateSheetRevisionModal
                onClose={() => setIsCreateSheetRevisionModalOpen(false)}
                apiUser={apiUser}
                task={task}
                sheet={selectedSheet}
                sheetIndex={selectedSheetIndex}
                file={file}
              />
            ) : null}
            {isEditSheetsModalOpen ? (
              <EditSheetsModal
                onClose={() => setIsEditSheetsModalOpen(false)}
                file={file}
                user={user}
                apiUser={apiUser}
                taskRevision={taskRevision}
                task={task}
                organisationDetails={organisationDetails}
                history={history}
              />
            ) : null}

            {isCreateExternalReferenceModalOpen ? (
              <CreateExternalReferenceModal
                visible={true}
                onClose={() => setIsCreateExternalReferenceModalOpen(false)}
                task={task}
                apiUser={apiUser}
                file={file}
                organisationDetails={organisationDetails}
              />
            ) : null}
            {isUploadCustomFileModalOpen ? (
              <UploadCustomFileModal
                onClose={() => setIsUploadCustomFileModalOpen(false)}
                apiUser={apiUser}
                file={file}
                visible={true}
                task={task}
                taskRevision={taskRevision}
                updateFileRandomNumber={updateFileRandomNumber}
                basePath={basePath}
              />
            ) : null}
          </Card>
        </div>
        {layout.sidebarWidth !== 0 && (
          <div style={{ width: layout.sidebarWidth, flexShrink: 0 }}>{displaySidebar({ layout })}</div>
        )}
      </div>
      {/* keeping this here as an example of how to use DismissableBanner */}
      {/* <DismissableBanner cookie="banner-new-file-details-page-1" type="modal" title="New page layout">
        <Typography.Text>
          We've added a sidebar to this page and moved the action buttons and file settings there.
        </Typography.Text>
      </DismissableBanner> */}
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: FileDetailsPage,
    subscriptions: ["task", "file", "tasks", "users", "projects", "organisationDetails", "asyncJobs"],
  })
);
