import { useState } from "react";
import { Storage } from "aws-amplify";
import { Form, Modal, Button, Typography, Space, notification, message } from "antd";
import { readAndCompressImage } from "browser-image-resizer";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";
import { getExtensionFromKey } from "common/shared";
import { getSimpleLabel } from "common/labels";

import Upload from "Upload/Upload";

import "./EditClientLogoModal.scss";

export function EditClientLogoModal({ onClose, organisationDetails, client, tasks }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function deleteClientLogo() {
    try {
      await callGraphQLSimple({
        message: `Could not remove the ${getSimpleLabel("client")} logo`,
        queryName: "updateClient",
        variables: {
          input: {
            id: client.id,
            key: null,
          },
        },
      });

      message.success({
        content: <Typography.Text>The client logo has been deleted</Typography.Text>,
        className: "update-user-success",
      });

      onClose();
    } catch (err) {
      console.log("error deleting client logo:", err);
    }
  }

  function confirmDeleteClientLogo() {
    Modal.confirm({
      title: "Confirm remove client logo",
      icon: <ExclamationCircleOutlined />,
      className: "remove-client-logo-modal",
      content: <>Are you sure you want to remove the logo for this client?</>,
      onOk: deleteClientLogo,
    });
  }

  async function onSubmit({ name, logo, isPriority }) {
    try {
      setIsLoading(true);

      let newLogo = client.key;

      // if we have a logo, then we need to upload it to S3 before
      // proceeding to update the client record in the database
      if (logo) {
        const extension = getExtensionFromKey(logo.name);

        if (!["png", "jpg", "jpeg"].includes(extension.toLowerCase())) {
          alert("Only .png, .jpg and .jpeg formats are allowed");
          setIsLoading(false);
          return;
        }

        const resizedLogo = await readAndCompressImage(logo, {
          quality: 1,
          maxWidth: 500,
          maxHeight: 500,
          mimeType: logo.type,
        });
        const fileName = `${organisationDetails.id}/clients/${Date.now()}${Math.floor(Math.random() * 10000)}`;
        const { key } = await Storage.put(fileName, resizedLogo, {
          contentType: "image/png",
        });
        newLogo = key;
      }

      await callGraphQLSimple({
        message: `Could not update ${getSimpleLabel("client")}`,
        queryName: "updateClient",
        variables: {
          input: {
            id: client.id,
            name,
            key: newLogo,
            isPriority,
          },
        },
      });

      let delay = 0;
      for (let i = 0; i < tasks.length; i++) {
        if (tasks[i].clientId === client.id) {
          setTimeout(() => {
            callGraphQLSimple({
              message: `Could not update ${getSimpleLabel("task")}`,
              queryName: "updateTask",
              variables: {
                input: {
                  id: tasks[i].id,
                  randomNumber: Math.floor(Math.random() * 1000000),
                },
              },
            });
          }, delay);
          delay += 50;
        }
      }

      setIsLoading(false);
      form.resetFields();
      onClose();
    } catch (e) {
      setIsLoading(false);
      notification.error({
        message: <Typography.Text>Could not update {getSimpleLabel("client")}</Typography.Text>,
        duration: 0,
      });
    }
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title={client.key ? "Edit logo" : "Add logo"}
      visible={true}
      onOk={onSubmit}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      footer={null}
      className="edit-client-modal"
    >
      <Form
        {...layout}
        form={form}
        initialValues={{ name: client.name, isPriority: client.isPriority }}
        onFinish={onSubmit}
      >
        <Space direction="vertical">
          <Form.Item name="logo" label="Logo" getValueFromEvent={(e, file) => file}>
            <Upload size="normal" />
          </Form.Item>

          <div className="submit-container">
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              className="submit-create-client-modal"
              data-cy="submit-modal-button"
            >
              {isLoading ? "Creating" : "Submit"}
            </Button>

            <Button onClick={confirmDeleteClientLogo} className="remove-client-logo">
              Remove
            </Button>
          </div>
        </Space>
      </Form>
    </Modal>
  );
}

export default withSubscriptions({
  Component: EditClientLogoModal,
  subscriptions: ["organisationDetails", "tasks"],
});
