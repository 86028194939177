import { KEY_TYPES } from "../shared";
import moment from "moment";
import { getFullDetailsForNaming } from "./namingHelpers";

export async function getFileName({ data, type }) {
  // log({
  //   level: "DEBUG",
  //   message: "START",
  //   method: "AEC:getFileName",
  //   details: {
  //     data,
  //     type,
  //   },
  // });

  if (data.fileType.toUpperCase() === "QUOTE") {
    return data.quoteId;
  }

  if (data.fileType.toUpperCase() === "INVOICE") {
    return data.invoiceId;
  }

  if (data.fileType.toUpperCase() === "PURCHASE_ORDER") {
    return data.purchaseOrderId;
  }

  const { fileTypeCount, taskIdWithoutOrg, task, file } = await getFullDetailsForNaming(data, type);

  const fileTypeCountSuffix = `${fileTypeCount}`.padStart(2, "0");

  let suffix = "";
  switch (data.fileType.toUpperCase()) {
    case "BRICSCAD":
    case "REVIT":
    case "AUTOCAD":
      suffix += `DRW-${fileTypeCountSuffix}`;
      if (type === KEY_TYPES.SHEET_REFERENCE) {
        suffix += `-${(1000 + (data.hasOwnProperty("sheetIndex") ? data.sheetIndex : file.sheets.items.length) + 1)
          .toString()
          .substring(1)}`;
      } else if (!type?.toUpperCase().includes("EXPORT")) {
        suffix += ` ${data.versionNumber}`;
      }
      break;
    case "BLUEBEAM":
      suffix = `PDF-${fileTypeCountSuffix}`;
      if (type !== KEY_TYPES.SHEET_REFERENCE) {
        suffix += ` ${data.taskRevisionName}`;
      }
      break;
    case "WORD":
    case "REPORT":
    case "APP_PAGE":
      suffix = `REPORT-${fileTypeCountSuffix}`;
      if (type !== KEY_TYPES.SHEET_REFERENCE) {
        suffix += ` ${data.taskRevisionName}`;
      }
      break;
    case "DESIGNERS RISK ASSESSMENT":
      suffix = "DRA";
      if (type !== KEY_TYPES.SHEET_REFERENCE) {
        suffix += ` ${data.taskRevisionName}`;
      }
      break;
    case "MATHCAD":
    case "EXCEL":
      suffix = `CAL-${fileTypeCountSuffix}`;
      if (type !== KEY_TYPES.SHEET_REFERENCE) {
        suffix += ` ${data.taskRevisionName}`;
      }
      break;
    case "POWERPOINT":
      suffix = `SK-${fileTypeCountSuffix}`;
      if (type !== KEY_TYPES.SHEET_REFERENCE) {
        suffix += ` ${data.taskRevisionName}`;
      }
      break;

    default:
      throw new Error(`Unknown file type:`, data.fileType.toUpperCase());
  }

  let projectInitials = task.project && task.project.initials?.trim();
  let clientInitials = task.client && task.client.initials?.trim();
  let taskInitials = task.initials?.trim();
  let elements = [taskIdWithoutOrg, clientInitials, projectInitials, taskInitials, suffix].filter((x) => x);

  let result = elements.join("-");

  if (type?.includes("SHEET") && data.sheetName && type.includes("EXPORT")) {
    result += ` ${data.sheetName}`;
  }

  // log({
  //   level: "DEBUG",
  //   message: "END",
  //   method: "AEC:getFileName",
  //   details: {
  //     result,
  //   },
  // });
  return result;
}

function changeFileNameAtDownloadTime(data) {
  let { fileName, sheetRevisionName, sheetRevision, file, type, invoice, projects, clients } = data;

  if (file?.type === "BRICSCAD") {
    if (type?.includes("SHEET") && sheetRevision) {
      let sheet = file.sheets.items.find((x) => x.id === sheetRevision.sheetId);
      if (sheet.customReferenceNumber) {
        return sheet.customReferenceNumber;
      }
    }
  } else if (type === KEY_TYPES.INVOICE) {
    let date = moment(invoice.createdAt);

    const projectDetails = projects.find((x) => x.id === invoice.projectId);
    const clientDetails = clients.find((x) => x.id === invoice.clientId);

    return `${clientDetails?.initials} ${projectDetails?.title} ${date.format("MMM YYYY")} ${invoice.id}.pdf`;
  }
  switch (type) {
    case KEY_TYPES.FILE_MAIN_EXPORT:
    case KEY_TYPES.FILE_MAIN_EXPORT_RAW:
      if (file.type === "REPORT") {
        return fileName;
      }
      let sheetRevisions = [];
      file.sheets.items.forEach((sheet) => {
        sheet.revisions.items.forEach((sheetRevision) => sheetRevisions.push(sheetRevision));
      });
      let highestSheetRevisionName = sheetRevisions
        .sort((a, b) => {
          let aRevisionNumber = parseInt(a.name.substring(1));
          let bRevisionNumber = parseInt(b.name.substring(1));
          return aRevisionNumber < bRevisionNumber ? -1 : 1;
        })
        .slice(-1)[0].name;
      return `${fileName} ${highestSheetRevisionName}`;

    case KEY_TYPES.FILE_SHEET_EXPORT:
    case KEY_TYPES.FILE_SHEET_EXPORT_RAW:
      if (file.type === "REPORT") {
        return fileName;
      }

      return `${fileName} ${sheetRevisionName}`;

    default:
      return fileName;
  }
}

function getProjectId({ organisation, extraOffset }) {
  const projectNumber =
    parseInt(organisation.projectIdOffset || 0) + parseInt(organisation.projectCount || 0) + 1 + parseInt(extraOffset);
  return `${organisation.id}-${projectNumber}`;
}

function getTaskId({ organisation, projectDetails, extraOffset }) {
  const taskNumber = parseInt(projectDetails.taskCount || 0) + 1 + parseInt(extraOffset);
  return `${projectDetails.id}-${taskNumber}`;
}

function getQuoteId({ organisation, projectDetails, extraOffset }) {
  const quoteNumber = parseInt(projectDetails.quoteCount || 0) + 1 + parseInt(extraOffset);
  return `${projectDetails.id}-Q${quoteNumber}`;
}

function getRequestId({ organisationDetails, extraOffset }) {
  const requestNumber = parseInt(organisationDetails.requestCount || 0) + 1 + parseInt(extraOffset);
  return `${organisationDetails.id}-REQ${requestNumber}`;
}

function getInvoiceId({ organisation, projectDetails, extraOffset }) {
  const invoiceNumber = parseInt(projectDetails.invoiceCount || 0) + 1 + parseInt(extraOffset);
  return `${projectDetails.id}-INV${invoiceNumber}`;
}

function getPurchaseOrderId({ organisation, projectDetails, extraOffset }) {
  const poNumber = parseInt(projectDetails.purchaseOrderCount || 0) + 1 + parseInt(extraOffset);
  return `${projectDetails.id}-PO${poNumber}`;
}

function getSheetDescription({ organisation, task, taskRevision, file, sheetCount }) {
  return "";
}

function getTaskRevisionName({ organisation, task }) {
  const possibleNames = [
    "R0",
    "R1",
    "R2",
    "R3",
    "R4",
    "R5",
    "R6",
    "R7",
    "R8",
    "R9",
    "R10",
    "R11",
    "R12",
    "R13",
    "R14",
    "R15",
    "R16",
    "R17",
    "R18",
    "R19",
  ];
  if (!task.revisions || !task.revisions.items || task.revisions.items.length === 0) {
    return possibleNames[0];
  }
  const nonArchivedRevisions = task.revisions.items.filter((x) => !x.isArchived);
  const latestTaskRevision = nonArchivedRevisions[nonArchivedRevisions.length - 1];

  let nameIndexOfLatestTaskRevision = possibleNames.findIndex((x) => x === latestTaskRevision.name);
  if (nameIndexOfLatestTaskRevision === -1) {
    return null;
  }

  let nextName = null;
  while (!nextName && nameIndexOfLatestTaskRevision < possibleNames.length - 1) {
    nextName = possibleNames[nameIndexOfLatestTaskRevision + 1];
    // eslint-disable-next-line
    if (nonArchivedRevisions.find((x) => x.name === nextName)) {
      nextName = null;
      nameIndexOfLatestTaskRevision++;
    }
  }

  return nextName;
}

function getSheetRevisionName({ sheet, task, newStatus }) {
  if (task.createdAt < "2024-05-27") {
    return getSheetRevisionNameUsingOldLogic({ sheet });
  } else {
    return getSheetRevisionNameUsingNewLogic({ sheet, newStatus });
  }
}

function getSheetRevisionNameUsingNewLogic({ sheet, newStatus }) {
  let newStatusIsConstruction = newStatus.toUpperCase().split(" ").join("_").includes("CONSTRUCTION");
  let newStatusIsApproval = newStatus.toUpperCase().split(" ").join("_").includes("APPROVAL");

  if (!sheet || !sheet.revisions || !sheet.revisions.items || sheet.revisions.items.length === 0) {
    if (newStatusIsConstruction) {
      return "C01";
    } else if (newStatusIsApproval) {
      return "B01";
    } else {
      return "P01";
    }
  }

  const nonArchivedRevisions = sheet.revisions.items.filter((x) => !x.isArchived);
  const latestSheetRevision = nonArchivedRevisions[nonArchivedRevisions.length - 1];

  let previousStatusIsConstruction = latestSheetRevision.status
    ?.toUpperCase()
    .split(" ")
    .join("_")
    .includes("CONSTRUCTION");
  let previousStatusIsApproval = latestSheetRevision.status?.toUpperCase().split(" ").join("_").includes("APPROVAL");

  let newName = "";
  let numberPart = parseInt(latestSheetRevision.name.substring(1)) || 0;
  if (
    (newStatusIsConstruction && previousStatusIsConstruction) ||
    (newStatusIsApproval && previousStatusIsApproval) ||
    (!newStatusIsConstruction && !newStatusIsApproval)
  ) {
    numberPart++;
  }

  if (numberPart < 10) {
    numberPart = `0${numberPart}`;
  }
  if (newStatusIsConstruction) {
    if (!previousStatusIsConstruction) {
      numberPart = "01";
    }

    while (true) {
      newName = `C${numberPart}`;
      let nameIsAlreadyUsed = nonArchivedRevisions.find((x) => x.name === newName);
      if (!nameIsAlreadyUsed || parseInt(numberPart) >= 1000) {
        break;
      }

      let numberPartIncremented = parseInt(numberPart) + 1;
      if (numberPartIncremented < 10) {
        numberPart = `0${numberPartIncremented}`;
      } else {
        numberPart = `${numberPartIncremented}`;
      }
    }
  } else if (newStatusIsApproval) {
    if (!previousStatusIsApproval) {
      numberPart = "01";
    }

    while (true) {
      newName = `B${numberPart}`;
      let nameIsAlreadyUsed = nonArchivedRevisions.find((x) => x.name === newName);

      if (!nameIsAlreadyUsed || parseInt(numberPart) >= 1000) {
        break;
      }
      let numberPartIncremented = parseInt(numberPart) + 1;
      if (numberPartIncremented < 10) {
        numberPart = `0${numberPartIncremented}`;
      } else {
        numberPart = `${numberPartIncremented}`;
      }
    }
  } else {
    while (true) {
      newName = `P${numberPart}`;
      let nameIsAlreadyUsed = nonArchivedRevisions.find((x) => x.name === newName);
      if (!nameIsAlreadyUsed || parseInt(numberPart) >= 1000) {
        break;
      }
      let numberPartIncremented = parseInt(numberPart) + 1;
      if (numberPartIncremented < 10) {
        numberPart = `0${numberPartIncremented}`;
      } else {
        numberPart = `${numberPartIncremented}`;
      }
    }
  }

  return newName;
}

function getSheetRevisionNameUsingOldLogic({ sheet }) {
  const possibleNames = [
    "C0",
    "C1",
    "C2",
    "C3",
    "C4",
    "C5",
    "C6",
    "C7",
    "C8",
    "C9",
    "C10",
    "C11",
    "C12",
    "C13",
    "C14",
    "C15",
    "C16",
    "C17",
    "C18",
    "C19",
  ];
  if (!sheet || !sheet.revisions || !sheet.revisions.items || sheet.revisions.items.length === 0) {
    return possibleNames[0];
  }
  const nonArchivedRevisions = sheet.revisions.items.filter((x) => !x.isArchived);
  const latestSheetRevision = nonArchivedRevisions[nonArchivedRevisions.length - 1];

  const nameIndexOfLatestSheetRevision = possibleNames.findIndex((x) => x === latestSheetRevision.name);
  if (nameIndexOfLatestSheetRevision === -1) {
    return null;
  }
  return possibleNames[nameIndexOfLatestSheetRevision + 1];
}

function changeSheetReference({ sheet, file, referenceNumber }) {
  return referenceNumber.split(" ")[0];
}

async function getFrontendFileName({ organisationDetails, task, taskRevision, templateId, fileType }) {
  const filesOfType = taskRevision.files.items.filter((x) => x.type === fileType);
  let fileTypeCount = filesOfType.length;
  const deletedFilesByType = JSON.parse(taskRevision.deletedFilesByType || "{}");
  if (deletedFilesByType[fileType]) {
    fileTypeCount += deletedFilesByType[fileType];
  }
  let result = String(1000 + fileTypeCount + 1).substring(1);
  return result;
}

function getTimelineBlockColor({ task, timelineBlock }) {
  if (timelineBlock.taskId === "HOLIDAY") {
    return "#a15902"; // orange
  }

  if (timelineBlock.taskId === "SICK DAY") {
    return "#ff4d4f"; // red
  }

  if (task?.titleLowerCase.includes("site visit") || task?.titleLowerCase.includes("visit")) {
    return "#fc659d"; // hot pink
  }
}

function getOrderedTasks({ tasks }) {
  return [...tasks].sort((a, b) => {
    if (a.client?.isPriority && !b.client?.isPriority) {
      return -1;
    }

    if (!a.client?.isPriority && b.client?.isPriority) {
      return 1;
    }

    if (a.client?.name.toLowerCase() < b.client?.name.toLowerCase()) {
      return -1;
    }

    if (a.client?.name.toLowerCase() > b.client?.name.toLowerCase()) {
      return 1;
    }

    if (a.project?.title?.toLowerCase() < b.project?.title?.toLowerCase()) {
      return -1;
    }

    if (a.project?.title?.toLowerCase() > b.project?.title?.toLowerCase()) {
      return 1;
    }

    if (a.title?.toLowerCase() < b.title?.toLowerCase()) {
      return -1;
    } else {
      return 1;
    }
  });
}

function isTimelineBlockFixed({ timelineBlock, task }) {
  if (task?.titleLowerCase?.includes("site visit") || timelineBlock.taskId === "SITE VISIT") {
    return true;
  }

  return false;
}

const functions = {
  getFileName,
  changeFileNameAtDownloadTime,
  changeSheetReference,
  getProjectId,
  getTaskId,
  getQuoteId,
  getInvoiceId,
  getPurchaseOrderId,
  getSheetDescription,
  getTaskRevisionName,
  getSheetRevisionName,
  getFrontendFileName,
  getRequestId,
  getTimelineBlockColor,
  getOrderedTasks,
  isTimelineBlockFixed,
};

export default functions;
